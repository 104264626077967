import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import './HomePage.css';
import loginIconSVG from "../../images/login-icon.svg"

export default function HomePage() {
  const navigate = useNavigate();
  const { loading , statusCode , HEADERS } = useSelector( state => state.login_reducer);
  
  useEffect(() => {
    if(!loading && statusCode === 200) {
      navigate("/chat");
      window.HEADERS = HEADERS;
    }
  },[loading,statusCode])
  const toSignIn = () => {
    navigate("/login");
  }
  return (
    <div className="dave-homepage-container col col-12">
        <div className="dave-logo-container col col-6">
            <img src={require('../../images/marutiLogo.png')} alt="" className="dave-main-logo-img" />
        </div>
        <div className="dave-login-container col col-6 col-sm-6">
            <button onClick={toSignIn} className="dave-login btn">
              <span className="btn-text">Login</span>
              <div className="icon-container">
                <img src={loginIconSVG}/>
              </div>
            </button>
        </div>
      <div className="dave-footer-container col col-12">
      <span className="dave-login-message">Get Login Details From Your Manager</span>
        <img src={require('../../images/artwork.png')} alt="" className="dave-footer-logo-img" />
      </div>
    </div>
  )
}
