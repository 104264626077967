import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as API_Calls from './API_Call';
import * as  API from './API_Path';

// export const userInfo = createAsyncThunk("userInfo", async (para,{rejectWithValue}) => {
//     const { HEADERS } = para;
//     try {
//         const response = await axios.get(API.USER_ROLE_API+HEADERS['X-I2CE-USER-ID'],{headers : HEADERS});
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const userInfo = createAsyncThunk("userInfo", async (para,{rejectWithValue}) => {
    const { HEADERS } = para;
    try {
        const response = await API_Calls.getMethod(API.USER_ROLE_API+HEADERS['X-I2CE-USER-ID']);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const userLogged = createSlice({
    name : "userLogged",
    initialState : {
        userInfoData : [],
        userInfoLoading : null,
        error : "",
        userInfoServiceCenterId : "",
        userInfoRole : ""
    },
    reducers:{
        clearUserData : (state,action) =>{
            state.userInfoLoading = null;
            state.userInfoData = [];
            state.userInfoServiceCenterId = "";
            state.userInfoRole = "";
            state.error = ""
        }
    },
    extraReducers : {
        [userInfo.pending] : (state) => {
            state.userInfoLoading = true;
        },
        [userInfo.fulfilled] : (state, action) => {
            state.userInfoLoading = false;
            state.userInfoData = action.payload;
            state.userInfoServiceCenterId = action.payload.service_center_id;
            state.userInfoRole = action.payload.agent_role;
        },
        [userInfo.rejected] : (state, action) => {
            state.userInfoLoading = false;
            state.error = action.payload;
        }
    }
});
export const { clearUserData } = userLogged.actions
export default userLogged.reducer;