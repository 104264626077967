import React from 'react';
import { useSelector } from 'react-redux';
import './ActiveTicketInfo.css';

export default function ActiveTicketInfo() {
  const { customer_name , vehicle_model } = useSelector( state => state.ticketStatus_reducer.activeTicketData);
  return (
    <div className="dave-tck-vechile-info">
        <span className="dave-tck-vechile-name">{customer_name.toLowerCase()}</span>
        <span className="dave-tck-vechile-model">{vehicle_model.toLowerCase()}</span>
    </div>
  )
}
