import React, { useEffect } from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleLogout } from '../../feature/LogoutSlice';
import Loader from '../loader/Loader';
import './Logout.css';
import { clearLoginCredentials } from '../../feature/LoginSlice';
import { clearUserData } from '../../feature/AgentSlice';

export default function Logout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user_id } = useSelector( state => state.login_reducer);
    const { userInfoData } = useSelector( state => state.user_reducer);
    const { cCMData , cCMLoading , wMData , wMLoading } = useSelector( state => state.reassignTicket_reducer);
    // const { activeTicketData } = useSelector( state => state.ticketStatus_reducer);
    const logoutUser = () => {
        // setTimeout(() => {
            dispatch(clearLoginCredentials());
            dispatch(clearUserData());
            navigate("/");
            localStorage.clear();
            window.location.reload();
        // }, 2000);
    }
    const closeLogOutPopUp = () => {
        dispatch(toggleLogout(false));
    }
    useEffect(() => {
        // console.log(cCMData[0][0]);
        // console.log(wMData[0][0])
    }, [cCMLoading,wMLoading])
    
    return (
        <div className="dave-logout-popup-container">
            <div className="dave-shadow-box" onClick={closeLogOutPopUp} ></div>
            <div className="dave-logout-popup-messge-container">
                <img src={require('../../images/Close.png')} alt="" className="dave-close-logout-popup" onClick={closeLogOutPopUp} />
                <div className="dave-logout-details-container">
                    <div>
                        <label htmlFor="dave-user-id">Center : </label>
                        <span id="dave-user-id">{userInfoData.service_center_name}</span>
                    </div>
                    <div>
                        <label htmlFor="dave-user-id">User : </label>
                        <span id="dave-user-id">{userInfoData.name}</span>
                    </div>
                    {
                        cCMLoading === false && user_id != cCMData[0][0] ? <div>
                            <label htmlFor="dave-ccm-id">CCM : </label>
                            <span id="dave-ccm-id">{cCMData[0][1]}</span>
                        </div> : ""
                    }
                    {
                        wMLoading === false && user_id != wMData[0][0] ? <div>
                            <label htmlFor="dave-wm-id">WM : </label>
                            <span id="dave-wm-id">{wMData[0][1]}</span>
                        </div> : ""
                    }
                </div>
                <button className="dave-logout-action-btn btn btn-lg" onClick={logoutUser}>Logout</button>
                {cCMLoading || wMLoading?<Loader style={{position:"absolute",height:"100%",width:"100%",borderRadius:"10px"}}/>:""}
            </div>
        </div>
    )
}
