import React from 'react';
import './Notification.css';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationAPI , toggleNotificationState } from '../../feature/NotificationSlice';
import bellIconSVG from "../../images/bell-icon.svg"
import h_bellIconSVG from "../../images/bell-icon-h.svg"


export default function Notification() {
  const dispatch = useDispatch();
  const { api_key , enterprise_id , user_id , HEADERS } = useSelector( state => state.login_reducer);
  const { notificationActive } = useSelector( state => state.notification_reducer);
  const toggleNotification = (e) => {
    !notificationActive ? dispatch(getNotificationAPI({HEADERS : HEADERS})) : console.log(notificationActive); 
    dispatch(toggleNotificationState(!notificationActive));
    console.log(notificationActive);
  }
  return (
    <>
    <button className="dave-notification-btn btn btn-lg" onClick={toggleNotification} style={notificationActive ? {"background":"#fff"} : {"background":"#008069"}}>
      <img src={notificationActive ? h_bellIconSVG :bellIconSVG} alt="" className="dave-notification-img" />
    </button>
    <button className="dave-notification-btn i-pad btn btn-lg" onClick={toggleNotification} style={notificationActive ?  {"background":"#008069"}:{"background":"#fff"}}>
      <img src={notificationActive ?bellIconSVG:h_bellIconSVG} alt="" className="dave-notification-img" />
    </button>
    </>
  )
}
