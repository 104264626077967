import React from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { activeTicketInfo } from '../../feature/ChatStatusSlice';
import { toggleNotificationState } from '../../feature/NotificationSlice';
// import { updateActiveTicketData } from '../../feature/MessageSlice';
import { hideNudgePopUp } from '../../feature/NudgeSlice';
import './Notification.css'

export default function Notification(props) {
    const dispatch = useDispatch();
    const { notificationActive } = useSelector( state => state.notification_reducer);
    const notificationData = props.data;
    const openChat = (e) => {
    dispatch(hideNudgePopUp(false));
    console.log(notificationData);
        // dispatch(updateActiveTicketData(notificationData));
        dispatch(activeTicketInfo(notificationData));
        dispatch(toggleNotificationState(!notificationActive));
    }
    return (
        <div className="dave-notification-container col" onClick={openChat}>
            <div className="dave-notification-header-col">
                <span className="dave-vechile-registration">{notificationData.vehicle_reg_number}</span>
            </div>
            <div className="dave-notification-detail-col">
                <span className="dave-notification-customer-name">{notificationData.customer_name}</span>
                {
                    // notificationData.booking_date === new Date().toJSON().slice(0, 10) ? <span className="dave-notification-last-message">Hi! The service appointment for {notificationData.customer_name} is scheduled for today, from {notificationData.booking_time}. Please ensure everything is prepared for a smooth and timely service.</span> : <span className="dave-notification-last-message">Hi! The car service appointment for {notificationData.customer_name} is scheduled for tomorrow, {notificationData.booking_date.split("-").reverse().join("-")} from {notificationData.booking_time}. Please follow-up with the customer and ensure all necessary arrangements are in place.</span>
                    notificationData.booking_date === new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split('T')[0] ? <span className="dave-notification-last-message">Hi! The service appointment for {notificationData.customer_name} is scheduled for today, from {notificationData.booking_time}. Please ensure everything is prepared for a smooth and timely service.</span> : <span className="dave-notification-last-message">Hi! The car service appointment for {notificationData.customer_name} is scheduled for tomorrow, {notificationData.booking_date.split("-").reverse().join("-")} from {notificationData.booking_time}. Please follow-up with the customer and ensure all necessary arrangements are in place.</span>
                }
            </div>
        </div>
    )
}
