import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API_Calls from './API_Call';
import * as API from "./API_Path";

// export const loginApi = createAsyncThunk("loginAPI", async (para,{rejectWithValue}) => {
//     try {
//         const response = await axios.post(API.LOGIN_API,para,{});
//         const result = response.data;
//         console.log(response);
//         console.log(result);
//         return(response)
//     } catch (error) {
//         console.log(error);
//         return rejectWithValue(error.response);
//     }
// });

export const loginApi = createAsyncThunk("loginApi", async (para,{rejectWithValue}) => {
    try {
        const res = await API_Calls.postMethod(API.LOGIN_API,para);
        console.log(res);
        return res;
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.response)
    }
});

export const login = createSlice({
    name : "login",
    initialState : {
        userData : [],
        HEADERS : {
            'Content-Type': 'application/json',
            'X-I2CE-ENTERPRISE-ID': "",
            'X-I2CE-USER-ID' : "",
            'X-I2CE-API-KEY' : ""
        },
        loading : null,
        error : "",
        api_key : "",
        enterprise_id : "",
        role : "",
        user_id : "",
        statusCode : "",
        attemptCount : 0,
    },
    reducers : {
        apiKey: (state,action) => {
            state.api_key = action.payload;
        },
        enterpriseId: (state,action) => {
            state.enterprise_id = action.payload;
        },
        userRole: (state,action) => {
            state.role = action.payload;
        },userId: (state,action) => {
            state.user_id = action.payload;
        },
        resetAttemptCount: (state,action) => {
            state.attemptCount = 0;
        },
        setCustomError: (state,action) => {
            state.error = action.payload;
        },
        clearLoginCredentials:(state,action) => {
            state.userData = [];
            state.api_key = "";
            state.enterprise_id = "";
            state.user_id = "";
            state.role = "";
            state.statusCode = "";
            state.HEADERS['X-I2CE-ENTERPRISE-ID'] = "";
            state.HEADERS['X-I2CE-USER-ID'] = "";
            state.HEADERS['X-I2CE-API-KEY'] = "";
            state.loading = null;
            state.error = "";
        }
    },
    extraReducers : {
        [loginApi.pending] : (state) => {
            state.loading = true;
            state.error = "";
        },
        [loginApi.fulfilled] : (state, action) => {
            state.loading = false;
            state.userData = action.payload.data;
            state.api_key = action.payload.data.api_key;
            state.enterprise_id = action.payload.data.enterprise_id;
            state.user_id = action.payload.data.user_id;
            state.role = action.payload.data.role;
            state.statusCode = action.payload.status;
            state.HEADERS['X-I2CE-ENTERPRISE-ID'] = action.payload.data.enterprise_id;
            state.HEADERS['X-I2CE-USER-ID'] = action.payload.data.user_id;
            state.HEADERS['X-I2CE-API-KEY'] = action.payload.data.api_key;
        },
        [loginApi.rejected] : (state, action) => {
            state.loading = false;
            state.error = action.payload.data.error;
            state.attemptCount+=1;
            state.statusCode = action.payload.status;
        }
    }
});

export const { apiKey , enterpriseId , userRole , userId , resetAttemptCount , setCustomError, clearLoginCredentials } = login.actions;
export default login.reducer;