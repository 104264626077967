import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeChatStatusApi , closedChatStatusApi, activeTicketInfo } from '../../feature/ChatStatusSlice';
import { toggleNotificationState } from '../../feature/NotificationSlice';
import { toggleUploadFilePopUp , toggleTemplateMessagePopUp } from '../../feature/ChatActionSlice';
import { updateReassignPopUp , updateReassignAction } from '../../feature/ReassignSlice';
import { hideNudgePopUp } from '../../feature/NudgeSlice';
// import { clearMessageCount , updateActiveTicketData } from '../../feature/MessageSlice';
import { updateChatMessageLoading , updateMessageAction , updateCustomerActive , getPreviousMessageByRoomId } from '../../feature/MessageSlice';
import './Ticket.css';
import messageIconSVG from "../../images/message-icon.svg"

export default function Ticket(props) {
  const dispatch = useDispatch();
  const { api_key , enterprise_id , user_id , HEADERS } = useSelector( state => state.login_reducer);
  const { sortByUpdated } = useSelector( state => state.sorting_reducer);
  const { kWS } = useSelector( state => state.searchJob_reducer);
  const { activeState , activeTicketData } = useSelector( state => state.ticketStatus_reducer);
  const { userInfoRole , userInfoServiceCenterId } = useSelector( state => state.user_reducer);
  const { newStatusData , messageCount , chatMessage } = useSelector( state => state.message_reducer);
  const activeTicketdata = {...props.activeTicketData};
  const openChat = (e) => {
    console.log(activeTicketdata);
    dispatch(hideNudgePopUp(false));
    activeTicketData.booking_id !== activeTicketdata.booking_id && dispatch(updateMessageAction("")) && dispatch(updateCustomerActive("")) && dispatch(activeTicketInfo(activeTicketdata));
    // dispatch(updateMessageAction(""));
    // dispatch(updateCustomerActive(""));
    // dispatch(updateActiveTicketData(activeTicketdata));
    // dispatch(clearMessageCount(activeTicketdata));
    // dispatch(activeTicketInfo(activeTicketdata));
    dispatch(toggleNotificationState(false));
    dispatch(toggleUploadFilePopUp(false));
    dispatch(toggleTemplateMessagePopUp(false));
    dispatch(updateReassignPopUp(false));
    dispatch(updateReassignAction(false));
    const userInfoParam = {
      HEADERS : HEADERS,
      sortByUpdated : sortByUpdated,
      kws : kWS,
      serviceCenterId : userInfoServiceCenterId
    }
    if(activeState === 'active') {
      dispatch(activeChatStatusApi(userInfoParam));
    } else if(activeState === 'closed') {
      dispatch(closedChatStatusApi(userInfoParam));
    }
  }
  useEffect(() => {
    activeTicketdata.room_id && !chatMessage[activeTicketdata.room_id] && dispatch(updateChatMessageLoading({room_id:activeTicketdata.room_id})) && 
    dispatch(getPreviousMessageByRoomId({room_id:activeTicketdata.room_id})) && console.log("Room_Id",activeTicketdata.room_id)
  }, [chatMessage[activeTicketdata.room_id]]);
  // useEffect(() => {
  // }, [messageCount])
  
  
  return (
    <>
      {
        ( userInfoRole === "wm") || ( userInfoRole === "ccm") || ( userInfoRole === "smre" && activeTicketdata.smre_id === user_id ) || ( userInfoRole === "service advisor" && activeTicketdata.service_advisor_id === user_id) ? <div className={`dave-ticket-container col ${activeState}`} onClick={openChat}>
          <div className="dave-ticket-detail-col">
            <span className="dave-vechile-registration">{activeTicketdata.vehicle_reg_number}</span>
            <span className="dave-ticket-assigned">{activeTicketdata.service_advisor_name || "Service Advisor"}</span>
          </div>
          <div className="dave-ticket-detail-notification">
            <div className="dave-ticket-vechile-detail-col">
              <span className="dave-customer-name">{activeTicketdata.customer_name.toLowerCase()}</span>
              <span className="dave-customer-mobile">{activeTicketdata.customer_contact_number}</span>
              <span className="dave-vechile-model">{activeTicketdata.vehicle_model.toLowerCase()}</span>
              <span className="dave-service-type">{activeTicketdata.service_type.toLowerCase()}</span>
            </div>
            <div className="dave-ticket-notification-container">
              {
                activeTicketdata && (( userInfoRole === "wm" ) || ( userInfoRole === "ccm" ) || ( userInfoRole === "smre" && activeTicketdata.smre_id === user_id ) || ( userInfoRole === "service advisor" && activeTicketdata.service_advisor_id === user_id)) && !(activeTicketdata.room_id in newStatusData) ? <div className="dave-new-message-notification-container">
                  <button className="dave-new-message-btn btn">NEW</button>
                </div> : ""
              }
              <div className="dave-ticket-notification">
                <img src={messageIconSVG} alt="" className="dave-ticket-notification-img" />
                {
                  activeTicketdata.room_id && (activeTicketdata.room_id in messageCount) && messageCount[activeTicketdata.room_id] > 0 ? <span className="dave-new-message-count">{messageCount[activeTicketdata.room_id]}</span> : ""
                }
              </div>
            </div>
          </div>
        </div> : ""
      }
    </>
  )
}
