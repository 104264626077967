import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as API_Calls from './API_Call';
import * as  API from './API_Path';

// export const getNotificationAPI = createAsyncThunk("getNotificationAPI", async (para,{rejectWithValue}) => {
//     const { HEADERS } = para;
//     const currentDate = new Date().toJSON().slice(0, 10);
//     let tommorrowDate  = new Date();
//     tommorrowDate.setDate(new Date().getDate() + 1);
//     tommorrowDate = tommorrowDate.toJSON().slice(0, 10);
//     try {
//         const response = await axios.get(API.GET_SERVICE_JOBS,{
//             params : {
//                 smre_id : HEADERS['X-I2CE-USER-ID'],
//                 booking_date : currentDate+","+tommorrowDate,
//                 _sort_by : "booking_date",
//                 _sort_reverse : true
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const getNotificationAPI = createAsyncThunk("getNotificationAPI", async (para,{rejectWithValue}) => {
    const { HEADERS } = para;
    // const currentDate = new Date().toJSON().slice(0, 10);
    const currentDate = new Date(new Date('2024-02-22').getTime() - (new Date('2024-02-22').getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    let tommorrowDate  = new Date(+new Date().setHours(0, 0, 0,0)+ 86400000).toLocaleDateString('fr-CA');
    // let tommorrowDate  = new Date();
    // tommorrowDate.setDate(new Date().getDate() + 1);
    // tommorrowDate = tommorrowDate.toJSON().slice(0, 10);
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_JOBS,{
            smre_id : HEADERS['X-I2CE-USER-ID'],
            booking_date : currentDate+","+tommorrowDate,
            _sort_by : "booking_date",
            _sort_reverse : true
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const notification = createSlice({
    name : "notification",
    initialState : {
        notificationLoading : null,
        notificationData : [],
        notificationError : "",
        notificationActive : false,
        notificationAllData : []
    },
    reducers : {
        toggleNotificationState : (state, action) => {
            state.notificationActive = action.payload;
        }
    },
    extraReducers : {
        [getNotificationAPI.pending] : (state) => {
            state.notificationLoading = true;
        },
        [getNotificationAPI.fulfilled] : (state, action) => {
            state.notificationLoading = false;
            state.notificationData = action.payload.data;
            state.notificationAllData = action.payload;
        },
        [getNotificationAPI.rejected] : (state, action) => {
            state.notificationLoading = false;
            state.notificationError = action.payload;
        }
    }
});

export const { toggleNotificationState } = notification.actions;
export default notification.reducer;