import './App.css';
import Dave from "./components/Dave";

function App() {
  return (
    <Dave/>
  );
}

export default App;
