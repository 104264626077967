import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as  API from './API_Path';
import * as API_Calls from './API_Call';

// export const initAPI = createAsyncThunk("initAPI", async (para,{rejectWithValue}) => {
//     const { HEADERS } = para;
//     try {
//         const response = await axios.post(API.MAIN_API+HEADERS['X-I2CE-USER-ID'],{},{headers : HEADERS});
//         const result = response.data;
//         console.log(result)
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const initAPI = createAsyncThunk("initAPI", async (para,{rejectWithValue}) => {
    const { HEADERS } = para;
    try {
        const response = await API_Calls.postMethod(API.MAIN_API+HEADERS['X-I2CE-USER-ID']);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const getServiceType = createAsyncThunk("getServiceType", async (para,{rejectWithValue}) => {
//     const { HEADERS , engagement_id } = para;
//     const body = {
//         "engagement_id": engagement_id,
//         "customer_state": "cs_service_type",
//         "system_response": "sr_ask_options"
//     }
//     try {
//         const response = await axios.post(API.MAIN_API+HEADERS['X-I2CE-USER-ID'],body,{headers : HEADERS});
//         const result = response.data;
//         console.log(result)
//         return result;
//     } catch (error) {
//         return rejectWithValue(error.response);
//     }
// });

export const getServiceType = createAsyncThunk("getServiceType", async (para,{rejectWithValue}) => {
    const { HEADERS , engagement_id } = para;
    const body = {
        "engagement_id": engagement_id,
        "customer_state": "cs_service_type",
        "system_response": "sr_ask_options"
    }
    try {
        const response = await API_Calls.postMethod(API.MAIN_API+HEADERS['X-I2CE-USER-ID'],body);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const addCustomerAPI = createAsyncThunk("addCustomerAPI", async (para,{rejectWithValue}) => {
//     const { HEADERS , customer_response } = para;
//     !customer_response.booking_date ? customer_response.booking_date = new Date().toJSON().slice(0, 10) : console.log(customer_response.booking_date);
//     !customer_response.booking_time ? customer_response.booking_time = new Date().toLocaleTimeString().slice(0,5) : console.log(customer_response.booking_time);
//     try {
//         const response = await axios.post(API.BASE_URL+"object/service_job",customer_response,{headers : HEADERS});
//         const result = response.data;
//         console.log(result)
//         return result;
//     } catch (error) {
//         return rejectWithValue(error.response);
//     }
// });

export const addCustomerAPI = createAsyncThunk("addCustomerAPI", async (para,{rejectWithValue}) => {
    const { HEADERS , customer_response } = para;
    customer_response.source = "web";
    !customer_response.booking_date ? customer_response.booking_date = new Date().toJSON().slice(0, 10) : console.log(customer_response.booking_date);
    !customer_response.booking_time ? customer_response.booking_time = new Date().toLocaleTimeString().slice(0,5) : console.log(customer_response.booking_time);
    try {
        const response = await API_Calls.postMethod(API.BASE_URL+"object/service_job",customer_response,{headers : HEADERS});
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const addCustomerOption = createSlice({
    name : "addCustomerOption",
    initialState : {
        showAddCustomer : false,
        showAddWalkInCustomer : false,
        showAddAppointmentCustomer : false,
        userEngagementId : "",
        userEngagementLoading : null,
        userEngagementError : "",
        addCustomerData : [],
        addCustomerLoading : null,
        addCustomerError : "",
        serviceTypeData : [],
        serviceTypeLoading : null,
        serviceTypeError : "",
        addCustomerAPIFire : false,
        ticketDataLoading:false
    },
    reducers : {
        addCustomer: (state,action) => {
            state.showAddCustomer = action.payload;
        },
        addWalkInCustomer: (state,action) => {
            state.showAddWalkInCustomer = action.payload;
        },
        addAppointmentCustomer: (state,action) => {
            state.showAddAppointmentCustomer = action.payload;
        },
        toggleAddCustomerAPIFire : (state,action) => {
            state.addCustomerAPIFire = action.payload;
        },
        toggleTicketLoading:(state,action)=>{
            state.ticketDataLoading = action.payload;
        }
    },
    extraReducers : {
        [initAPI.pending] : (state) => {
            state.userEngagementLoading = true;
        },
        [initAPI.fulfilled] : (state, action) => {
            state.userEngagementLoading = false;
            state.userEngagementId = action.payload.engagement_id;
        },
        [initAPI.rejected] : (state, action) => {
            state.userEngagementLoading = false;
            state.userEngagementError = action.payload;
        },
        [addCustomerAPI.pending] : (state) => {
            state.addCustomerLoading = true;
        },
        [addCustomerAPI.fulfilled] : (state, action) => {
            state.addCustomerLoading = false;
            state.addCustomerData = action.payload;
        },
        [addCustomerAPI.rejected] : (state, action) => {
            state.addCustomerLoading = false;
            state.addCustomerError = action.payload;
        },
        [getServiceType.pending] : (state) => {
            state.serviceTypeLoading = true;
        },
        [getServiceType.fulfilled] : (state, action) => {
            state.serviceTypeLoading = false;
            state.serviceTypeData = action.payload.data.service_types;
        },
        [getServiceType.rejected] : (state, action) => {
            state.serviceTypeLoading = false;
            state.serviceTypeError = action.payload;
        }
    }
});

export const { addCustomer , addWalkInCustomer , addAppointmentCustomer , toggleAddCustomerAPIFire, toggleTicketLoading } = addCustomerOption.actions;
export default addCustomerOption.reducer;