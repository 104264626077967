import React from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { activeChatStatusApi , updateActiveTicketData , closedChatStatusApi , clearActiveTicketData } from '../../feature/ChatStatusSlice';
import { updateReasinee , updateReassignAction , updateReassignPopUp } from '../../feature/ReassignSlice';
import { newStatus } from '../../feature/MessageSlice';
import './ReassignPopup.css';
import DropdownIconSVG from "../../images/dropdown-icon.svg"
import Loader from '../loader/Loader';
import { toggleTicketLoading } from '../../feature/AddCustomerOptionSlice';

export default function ReassignPopup() {
  const dispatch = useDispatch();
  const { HEADERS } = useSelector( state => state.login_reducer);
  const { userInfoRole , userInfoServiceCenterId } = useSelector( state => state.user_reducer);
  const { sortByUpdated } = useSelector( state => state.sorting_reducer);
  const { activeTicketData , activeState } = useSelector( state => state.ticketStatus_reducer);
  const { kWS } = useSelector( state => state.searchJob_reducer);
  const { smreData , smreLoading , serviceAdvisorData , serviceAdvisorLoading } = useSelector( state => state.reassignTicket_reducer);
  const reassignAdvisors = async (e) => {
    let selectedSMRE , selectedServiceAdvisor;
    if(userInfoRole === 'wm' || userInfoRole === 'ccm') {
      selectedSMRE = document.getElementById('dave-smre-reassign') ? document.getElementById('dave-smre-reassign').value : "";
    } 
    if (userInfoRole === 'wm' || userInfoRole === 'ccm' || userInfoRole === 'smre' || userInfoRole === 'service advisor') {
      selectedServiceAdvisor = document.getElementById('dave-service-advisor-reassign').value;
    }
    const reassignPara = {
      HEADERS : HEADERS,
      booking_id : activeTicketData.booking_id,
      advisors : {
        reassign_pending : true,
      }
      // smre_id : selectedSMRE || activeTicketData.smre_id,
      // service_advisor_id : selectedServiceAdvisor || activeTicketData.service_advisor_id
    }
    if (selectedSMRE) {
      reassignPara.advisors.smre_id = selectedSMRE;
      if((reassignPara.advisors.assigned_agents && !reassignPara.advisors.assigned_agents.includes(selectedSMRE)) || !activeTicketData.assigned_agents.includes(selectedSMRE)) {
        reassignPara.advisors.assigned_agents ? reassignPara.advisors.assigned_agents = [...reassignPara.advisors.assigned_agents,selectedSMRE] : reassignPara.advisors.assigned_agents = [...activeTicketData.assigned_agents,selectedSMRE];
      }
    }
    if (selectedServiceAdvisor) {
      reassignPara.advisors.service_advisor_id = selectedServiceAdvisor;
      if((reassignPara.advisors.assigned_agents && !reassignPara.advisors.assigned_agents.includes(selectedServiceAdvisor)) || !activeTicketData.assigned_agents.includes(selectedServiceAdvisor)) {
        reassignPara.advisors.assigned_agents ? reassignPara.advisors.assigned_agents = [...reassignPara.advisors.assigned_agents,selectedServiceAdvisor] : reassignPara.advisors.assigned_agents = [...activeTicketData.assigned_agents,selectedServiceAdvisor];
      }

    }
    dispatch(toggleTicketLoading(true))
    dispatch(updateReasinee(reassignPara)).then(() => {
      const userInfoParam = {
        HEADERS : HEADERS,
        sortByUpdated : sortByUpdated,
        kws : kWS,
        serviceCenterId : userInfoServiceCenterId
      }
      // if(activeState === 'active') {
      //   dispatch(activeChatStatusApi(userInfoParam)).then(() => {
      //     dispatch(updateActiveTicketData())
      //   });
      // } else if(activeState === 'closed') {
      //   dispatch(closedChatStatusApi(userInfoParam)).then(() => {
      //     dispatch(updateActiveTicketData())
      //   });
      // }
      dispatch(activeChatStatusApi(userInfoParam));
      dispatch(newStatus(userInfoParam));
    });
    dispatch(updateReassignPopUp(false));
    dispatch(updateReassignAction(false));
    if (userInfoRole === 'service advisor') {
      // dispatch(clearActiveTicketData());
    }
  }
  const cancelReassign = (e) => {
    dispatch(updateReassignPopUp(false));
    dispatch(updateReassignAction(false));
  }
  
  return (
    <div className="dave-close-ticket-container col">
      { activeTicketData.booking_category === 'appointment' && ( userInfoRole === 'wm' || userInfoRole === 'ccm' ) ?
        <div className="dave-smre-advisior-reassign-container">
          <span>Select a SMRE</span>
          <div className="dave-dropdown-container">
            <select style={{border:"2px solid #008069"}} className="form-control border-black" id="dave-smre-reassign" >
              <option value="">Select SMRE</option>
              {
                smreLoading !== null && !smreLoading ? smreData.map( smre => smre[0] !== activeTicketData.smre_id ? <option value={smre[0]}>{smre[1]}</option> : "") : ""
              }
            </select>
            <button style={{borderRadius:"0 24px 24px 0"}} className="dave-dropdown">
                <img src={DropdownIconSVG} alt="" className="dave-dropdown-logo-img" />
            </button>
          </div>
        </div> : ""
      }
      { userInfoRole === 'wm' || userInfoRole === 'ccm' || userInfoRole === 'smre'  || userInfoRole === 'service advisor' ?
        <div className="dave-service-advisior-reassign-container">
          <span>Select a Service Advisor</span>
          <div className="dave-dropdown-container">
              <select style={{border:"2px solid #008069"}} className="form-control border-black" id="dave-service-advisor-reassign" >
                <option value="">Select Service Advisor</option>
                {
                  serviceAdvisorLoading !== null && !serviceAdvisorLoading ? serviceAdvisorData.map( serviceAdvisor => serviceAdvisor[0] !== activeTicketData.service_advisor_id ? <option value={serviceAdvisor[0]}>{serviceAdvisor[1]}</option> : "") : ""
                }
              </select>
              <button className="dave-dropdown">
                  <img src={DropdownIconSVG} alt="" className="dave-dropdown-logo-img" />
              </button>
          </div>
        </div> : ""
      }
      <div className="dave-reassign-action-container col">
          <button className="dave-ticket-reassign-cancel-btn btn btn-lg mx-2 my-3" onClick={cancelReassign}>Cancel</button>
          <button className="dave-ticket-reassign-bt btn btn-lg mx-2 my-3" onClick={reassignAdvisors}>Reassign</button>
      </div>
      {/* {(smreLoading === null && smreLoading) || (serviceAdvisorLoading === null && serviceAdvisorLoading) ? "":<Loader style={{position:"absolute",height:"100%",width:"100%",}}/>} */}
    </div>
  )
}
