import { createSlice } from "@reduxjs/toolkit";

export const searchJob = createSlice({
    name : 'searchJob',
    initialState : {
        kWS : ""
    },
    reducers : {
        updateKWS: (state,action) => {
            state.kWS = action.payload;
        }
    }
})

export const { updateKWS } = searchJob.actions;
export default searchJob.reducer;