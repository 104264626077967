import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as API_Calls from './API_Call';
import * as API from "./API_Path";



// getDSData('/objects/person', {}).then((data)=>{

// }).then((data)=>{
    
// })


// export const searchTicket = createAsyncThunk("searchTicket", async (para,{rejectWithValue}) => {
//     const { HEADERS , tRoom_id } = para;
//     try {
//         const response = await axios.get(API.GET_SERVICE_JOBS,{ 
//             params : {
//                 room_id : tRoom_id 
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         console.log(result);
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const searchTicket = createAsyncThunk("searchTicket", async (para,{rejectWithValue}) => {
    const { tRoom_id } = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_JOBS,{ 
            room_id : tRoom_id 
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const reOpenJob = createAsyncThunk("reOpenJob", async (para,{rejectWithValue}) => {
    const { booking_id } = para;
    const updatedBody = {
        "status": "active"
    }
    try {
        const response = await API_Calls.patchMethod(API.UPDATE_TICKET+booking_id,updatedBody);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const searchJobTicket = createSlice({
    name : "searchJobTicket",
    initialState : {
        sTicketData : {},
        sTicketloading : null,
        sTicketerror : "",
        // reOpenJobData : null,
        // reOpenJobLoading : null,
        // reOpenJobError : null, 
    },
    reducers : {
        deleteSearchTicketData: (state,action) => {
            // if(action.payload in state.advisorEmailCounterList) {
            //     console.log(state.advisorEmailCounterList[action.payload]);
            //     delete state.advisorEmailCounterList[action.payload];
            // }
        }
    },
    extraReducers : {
        [searchTicket.pending] : (state) => {
            state.sTicketloading = true;
            state.sTicketerror = "";
        },
        [searchTicket.fulfilled] : (state, action) => {
            state.sTicketloading = false;
            console.log(action.payload.data);
            if(action.payload.data[0].room_id in state.sTicketData) {
                console.log(state.sTicketData[action.payload.data[0].room_id]);
                state.sTicketData[action.payload.data[0].room_id] = action.payload.data[0];
            }
            else {
                Object.assign(state.sTicketData, {
                    [action.payload.data[0].room_id]: action.payload.data[0],
                });
            }
        },
        [searchTicket.rejected] : (state, action) => {
            state.sTicketloading = false;
            state.sTicketerror = action.payload.data.error;
            state.statusCode = action.payload.status;
        },
        // [reOpenJob.pending] : (state) => {
        //     state.sTicketloading = true;
        //     state.sTicketerror = "";
        // },
        // [reOpenJob.fulfilled] : (state, action) => {
        //     state.sTicketloading = false;
        //     console.log(action.payload.data);
        //     if(action.payload.data[0].room_id in state.sTicketData) {
        //         console.log(state.sTicketData[action.payload.data[0].room_id]);
        //         state.sTicketData[action.payload.data[0].room_id] = action.payload.data[0];
        //     }
        //     else {
        //         Object.assign(state.sTicketData, {
        //             [action.payload.data[0].room_id]: action.payload.data[0],
        //         });
        //     }
        // },
        // [reOpenJob.rejected] : (state, action) => {
        //     state.sTicketloading = false;
        //     state.sTicketerror = action.payload.data.error;
        //     state.statusCode = action.payload.status;
        // }
    }
});

export const { deleteSearchTicketData } = searchJobTicket.actions;
export default searchJobTicket.reducer;