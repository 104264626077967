import React from 'react';
import { useSelector } from 'react-redux';
import Status from '../chatStatus/Status'
import './Navbar.css';
import Logo from './Logo';
import AddCustomer from './AddCustomer';
import Notification from './Notification';
import Logout from './Logout';

export default function Navbar() {
  const { ticketCountData , ticketCountLoading } = useSelector(state => state.ticketStatus_reducer);
  const { activeState } = useSelector( state => state.ticketStatus_reducer);
  const { userInfoRole } = useSelector( state => state.user_reducer);
  return (
    <nav className="navbar bg-body-tertiary">
      <div className="dave-nav-contents-wrapper container-fluid">
        <Logo/>
        <div className="header-status-container">
        <Status title="Active" style={activeState === "active" ? {"background":"#fff","color":"#000","box-shadow": "0px 10px 20px 0px #00000040"} : {"background":"#008069","color":"#fff","border": "2px solid #FFF"}} ticketNo={ticketCountData.active===null?0:ticketCountData.active}/>
      <Status title="Closed" style={activeState === "closed" ? {"background":"#fff","color":"#000","box-shadow": "0px 10px 20px 0px #00000040"} : {"background":"#008069","color":"#fff","border": "2px solid #FFF"}}  ticketNo={ticketCountData.closed===null?0:ticketCountData.closed}/>
      
        </div>
        <div className="dave-add-cus-notification-container col col-sm-6">
          <AddCustomer/>
          {
            userInfoRole === "smre" ? <Notification/> : ""
          }
          <Logout/>
        </div>
        <div className="dave-popup-message-container">
          <div className="popup-message-text">
            Something went wrong!
          </div>
          <div className="popup-message-action-container">
            <button className="popup-message-retry-btn">Retry</button>
          </div>
        </div>
      </div>
    </nav>
  )
}