//TEST ENVIRONMENT
export const BASE_URL = window.location.origin !== 'https://mutiparty-uat.iamdave.ai' ? "https://test.iamdave.ai/" : "https://msil-multipartychat-dashboardv2-uat.gryd.in/";
// export const BASE_URL = "https://msil-multipartychat-dashboardv2-uat.gryd.in/";
// export const BASE_URL = "https://test.iamdave.ai/"

// DMS API
// export const dMSAPI = "http://dmssales.co.in:8071/arenaservice.svc/VehicleDetails";
export const dMSAPI = "http://dmssales.co.in:8071/arenaservice.svc/VehicleDetails";

// PASS THROUGH API
export const PASS_THROUGH_API = BASE_URL+"pass_through_api/dave";

// NUDGE API
export const NUDGE_API = BASE_URL + "webhook/dave/maruti_core/inactive_user_nudge"

// const LOGIN_ENDPOINT = window.location.origin !== 'https://mutiparty-uat.iamdave.ai' ? "dave/login" : "login";
const LOGIN_ENDPOINT = "dave/login";

//LOGIN
export const LOGIN_API = BASE_URL+LOGIN_ENDPOINT;

//USER ROLE
export const USER_ROLE_API = BASE_URL+"object/agent/";

//SERVICE ADVISOR
export const GET_SERVICE_ADVISORS = BASE_URL+"options/agent/";

// GET SERVICE CENTER
export const GET_SERVICE_CENTER = GET_SERVICE_ADVISORS+'agent_full_name';

//SERVICE JOBS
export const GET_SERVICE_JOBS = BASE_URL+"objects/service_job";

// UPDATE ADVISORS
export const UPDATE_TICKET = BASE_URL+"object/service_job/"

//CUSTOMER
export const MAIN_API = BASE_URL+"conversation/deployment_services_multiparty/";

// SOCKET 
export const SOCKET_URL = window.location.origin !== 'https://mutiparty-uat.iamdave.ai' ? "https://multiparty-orchestrator.iamdave.ai/" : "https://msil-multiparty-orchestrator-uat.gryd.in/";
// export const SOCKET_URL = "https://msil-multiparty-orchestrator-uat.gryd.in/";

// PREVIOUS MESSAGE
export const PREVIOUS_MESSAGE = BASE_URL+"objects/dave_message";

// NEW STATUS
export const NEW_STATUS = BASE_URL+"pivot/dave_message/room_id";

// EMAIL PATH
export const EMAIL_PATH = BASE_URL+"transaction/email";

// FILE UPLOAD
export const FILE_UPLOAD = BASE_URL+"upload_file"
