import React from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { activeChatStatusApi , closedChatStatusApi ,updateState } from '../../feature/ChatStatusSlice';
import './Status.css';

export default function Status(props) {
    const dispatch = useDispatch();
    const { HEADERS } = useSelector( state => state.login_reducer);
    const { userInfoServiceCenterId } = useSelector( state => state.user_reducer);
    const { sortByUpdated } = useSelector( state => state.sorting_reducer);
    const { kWS } = useSelector( state => state.searchJob_reducer);
    const changeTicketStatus = (e) => {
        const userInfoParam = {
            HEADERS : HEADERS,
            sortByUpdated : sortByUpdated,
            kws : kWS,
            serviceCenterId : userInfoServiceCenterId
        }
        if(props.title === "Active") {
            dispatch(activeChatStatusApi(userInfoParam));
            dispatch(updateState("active"));
        } else if(props.title === "Closed") {
            dispatch(closedChatStatusApi(userInfoParam));
            dispatch(updateState("closed"));
        }
    }
    return (
        <button className="dave-status-btn btn btn-lg" onClick={changeTicketStatus} style={props.style}>
            <div className="dave-status-name">{props.title}</div>
            <div className="dave-status-number">{props.ticketNo}</div>
        </button>
    )
}
