import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notification from './Notification';
import { toggleNotificationState } from '../../feature/NotificationSlice';
import './Notifications.css';
import closeIconSVG from "../../images/close-icon.svg"
import Loader from '../loader/Loader';
export default function Notifications() {
  const dispatch = useDispatch();
  const { notificationData , notificationActive , notificationLoading } = useSelector(state => state.notification_reducer);

  function sortByDateTimeSlot(itemA, itemB) {
    const dateA = new Date(itemA.booking_date);
    const timeSlotA = new Date(`1970-01-01T${itemA.booking_time.split("-")[1]}`);
    const combinedDateTimeA = new Date(dateA.getFullYear(), dateA.getMonth(), dateA.getDate(), timeSlotA.getHours(), timeSlotA.getMinutes());

    const dateB = new Date(itemB.booking_date);
    const timeSlotB = new Date(`1970-01-01T${itemB.booking_time.split("-")[1]}`);
    const combinedDateTimeB = new Date(dateB.getFullYear(), dateB.getMonth(), dateB.getDate(), timeSlotB.getHours(), timeSlotB.getMinutes());

    return combinedDateTimeA - combinedDateTimeB;
  }
  function filterNotification(val){
    const currentDateAndTime = new Date();
    const itemsDateAndTime = new Date(`${val.booking_date}T${val.booking_time.split("-")[1]}`)
    return itemsDateAndTime < currentDateAndTime ? false : true;
  }


  console.log([...notificationData]?.sort(sortByDateTimeSlot))
  return (
    <div className="dave-notification-data-container col col-5">
      <button onClick={()=>{dispatch(toggleNotificationState(!notificationActive));}} className="close-notification-btn">
        <img height="20px" src={closeIconSVG}/>
      </button>
      <div className="container-title">Notifications</div>
      <div className="dave-notification-wrapper">
        {
          notificationActive && notificationData.length > 0 ? [...notificationData]?.sort(sortByDateTimeSlot)?.filter(filterNotification)?.map( notification => <Notification data={notification}/>) : <div className="dave-no-notification-container">
          <span className="dave-no-notification-message">No New Notification</span>
        </div>
        }
      </div>
                {notificationLoading?<Loader style={{position:"absolute",height:"100%",width:"100%"}}/>:""}
    </div>
  )
}
