import React , { useEffect , useState } from 'react';
import * as API from '../../feature/API_Path';
import { DAVE_SPACE } from '../../feature/daveSocket';
import { useSelector , useDispatch } from "react-redux";
import ChatFileUpload from './ChatFileUpload';
import DefaultMessage from './DefaultMessage';
import { deletefileData, toggleUploadLoading } from '../../feature/FileUploadSlice';
import { toggleUploadFilePopUp , toggleTemplateMessagePopUp } from '../../feature/ChatActionSlice';
import { deleteFromAdvisorEmailCounterList , deleteFromManagerEmailCounterList } from "../../feature/SendEmailSlice";
import { chatMessage , updateMessageText , clearMessageCount, updateAutomatedResponse, updateChatMessage , lastCustomerMessage , updateMessageAction , updateCustomerActive, addToTempMessage } from '../../feature/MessageSlice';
import { fileUpload } from '../../feature/FileUploadSlice';
import moment from 'moment/moment';
import './ChatAction.css';
import sendIconSVG from "../../images/send-icon.svg"
import attachmentIconSVG from "../../images/attachment-icon.svg"
import Loader from '../loader/Loader';
import { nudgeApi , hideNudgePopUp } from '../../feature/NudgeSlice';
import { dateFrom , generateTempMesID } from '../common/Common';
import NudgeNotification from '../notification/NudgeNotification';



let chatRoomConnection;
export default function ChatAction() {
  const dispatch = useDispatch();
  const messages = ["Click here "];
  const { api_key , enterprise_id , user_id } = useSelector( state => state.login_reducer);
  const { nudgePopUp } = useSelector( state => state.nudgeUser_reducer);
  const { customer_id , space_id , room_id } = useSelector( state => state.ticketStatus_reducer.activeTicketData);
  const { uploadFilePopUp , templateMessagePopUp } = useSelector( state => state.chatAction_reducer);
  const { activeTicketData } = useSelector( state => state.ticketStatus_reducer);
  const { messageText , lastCustomerMessageData , customerActive , messageAction , lastCustomerMessageLoading , chatMessage , tempMessage } = useSelector( state => state.message_reducer);
  const { fileType, fileUploadData , fileName , fileUploadloading , selectedFile } = useSelector( state => state.mediaUpload_reducer);
  
  const toggleUploadFile = (e) => {
    dispatch(toggleUploadFilePopUp(!uploadFilePopUp));
    dispatch(toggleTemplateMessagePopUp(false));
    dispatch(deletefileData());
  }
  const toggleDefaultMessage = (e) => {
    dispatch(toggleTemplateMessagePopUp(!templateMessagePopUp));
    dispatch(toggleUploadFilePopUp(false));
  }
  const successCallback = (res) => {
    console.log(res);
    chatRoomConnection.registerCallback(dataHandler, "data-callback", "data");
  }
  const errorCallback = (err) => {
      console.log(err);
  }
  const dataHandler = (data) => {
    // console.log("job_space: P2P - Data Handler - data --> "+JSON.stringify(data));
    console.log("suggestion:",data);

    // if(data.message_id && !data.data?.reply_id) {
    //   // if(data.data.send_via === "whatsapp") {
    //   //     data.data.message_id = data.message_id;
    //   // }
    //   console.log("new message:",data);
    //   dispatch(updateChatMessage(data));
    //   console.log("User Id--->>>"+user_id);
    //   console.log("Sender Id->>>"+data.data.sender_id);
    //   console.log(data.data);
      
    // }
    
    // if(data.data?.suggestion){
    //   dispatch(updateAutomatedResponse(data));
    // }
    console.log(chatRoomConnection);
  }
  const sendMessage = () => {
    console.log("enterprise_id : " + enterprise_id);
    console.log("customer_id : " + customer_id);
    console.log("api_key : " + api_key);
    console.log("user_id : " + user_id);
    console.log("space_id : " + space_id);
    console.log("room_id : " + room_id);
    dispatch(deleteFromAdvisorEmailCounterList(room_id));
    dispatch(deleteFromManagerEmailCounterList(room_id));
    const typedMessage = document.querySelector(".dave-user-message-typing").value.trim();
    console.log(chatRoomConnection);
    if (typedMessage.length > 0 && !fileType) {
      let sentMessage  = chatRoomConnection.sendData({"message":typedMessage,"temMessageID":generateTempMesID()}, "data-callback");
      // let sentMessage  = chatRoomConnection.sendData({"image":url}, "data-callback");
      dispatch(addToTempMessage(sentMessage));
      console.log(sentMessage);
      console.log("Message Box Cleared");
      // document.querySelector('.dave-user-message-typing').value = "";
    const payload = {
      room_id : room_id,
      message : ""
    }
    dispatch(updateMessageText(payload));
    }
    if(fileType){
      dispatch(toggleUploadFilePopUp(false))
      dispatch(fileUpload({api_key, enterprise_id ,user_id, file : selectedFile})).then(() => { 
        dispatch(deletefileData());
      });
    }
  }
  const sendMessageOnEnter = (e) => {
    // console.log(e)
    if(e.key === "Enter" && !e.shiftKey) {
      sendMessage();
      console.log(e.target.value);
      e.target.blur();
    }
  }
  const sendMedia = () => {
    console.log("enterprise_id : " + enterprise_id);
    console.log("customer_id : " + customer_id);
    console.log("api_key : " + api_key);
    console.log("user_id : " + user_id);
    console.log("space_id : " + space_id);
    console.log("room_id : " + room_id);
    dispatch(deleteFromAdvisorEmailCounterList(room_id));
    dispatch(deleteFromManagerEmailCounterList(room_id));
    console.log(chatRoomConnection);
    console.log(fileName);
    console.log("fileUploadData",fileUploadData);
    let sentMessage  = chatRoomConnection.sendData({"media_url":fileUploadData.path,"type":fileType,"filename":fileName,"temMessageID":generateTempMesID()}, "data-callback");
    dispatch(addToTempMessage(sentMessage));
    console.log('sentMessage=====',sentMessage);
    console.log('state.fileUploadData======', fileUploadData);
    console.log('state.fileType======', fileType);
    console.log('state.fileName======', fileName);
    dispatch(deletefileData());
    console.log("Media type " + fileType + "sent");
    const payload = {
      room_id : room_id,
      message : ""
    }
    dispatch(updateMessageText(payload));
  }
  const unsetMessageCount = ()=> {
    // dispatch(clearMessageCount(activeTicketData))
  };
  const nudgeUser = () => {
    console.log("activeTicketData",activeTicketData);
    console.log("user_id",user_id );
    dispatch(nudgeApi({
      booking_id : activeTicketData.booking_id,
      customer_mobile_number : activeTicketData.customer_contact_number,
      service_whatsapp_number : activeTicketData.service_whatsapp_number || "",
      room_id : activeTicketData.room_id,
      sender_id : user_id
    }));
    dispatch(hideNudgePopUp(true));
  }
  useEffect(() => {


    chatRoomConnection?.isConnected() && console.log("chatRoomConnection.isConnected",chatRoomConnection.isConnected())
    chatRoomConnection ? chatRoomConnection.isConnected() && chatRoomConnection.disconnect() : console.log("disconnect not called") ;
    if(enterprise_id && customer_id && api_key && user_id && space_id && room_id) {
      console.log("enterprise_id : " + enterprise_id);
      console.log("customer_id : " + customer_id);
      console.log("api_key : " + api_key);
      console.log("user_id : " + user_id);
      console.log("space_id : " + space_id);
      console.log("room_id : " + room_id);
      chatRoomConnection  = new DAVE_SPACE(enterprise_id, customer_id, api_key, user_id, space_id, room_id, API.SOCKET_URL , successCallback, errorCallback);
      console.log(chatRoomConnection);
    }
  },[room_id]);


  const changeMessageText = (e) => {
    // setMessageText(e.target.value);
    console.log(room_id);
    console.log(e.target.value);
    const payload = {
      room_id : room_id,
      message : e.target.value
    }
    dispatch(updateMessageText(payload));
  }

  useEffect(() => {
    console.log("messageText is changed", templateMessagePopUp);
  }, [messageText]);
  useEffect(() => {
    if(fileType && fileUploadData) {
      sendMedia();
      dispatch(toggleUploadLoading(false));
    }
  }, [fileUploadData])

  useEffect(() => {
    if (activeTicketData.room_id in chatMessage) {
      console.log("chatMessage of activeTicketData.room_id ",chatMessage[activeTicketData.room_id])
    } else {
      // dispatch(lastCustomerMessage({room_id:activeTicketData.room_id, sender_id:activeTicketData.customer_name+"-"+activeTicketData.customer_contact_number}))
    }
    dispatch(lastCustomerMessage({room_id:activeTicketData.room_id, sender_id:activeTicketData.customer_name+"-"+activeTicketData.customer_contact_number}))
  }, [activeTicketData,chatMessage[activeTicketData.room_id]]);

  useEffect(() => {
    dispatch(updateCustomerActive(""));
    dispatch(updateMessageAction(""));
    // dispatch(toggleTemplateMessagePopUp(""));
  }, [activeTicketData])

  useEffect(() => {
    console.log("lastCustomerMessage",lastCustomerMessageData);
    // console.log("lastCustomerMessage Length",lastCustomerMessageData.length);
    if(lastCustomerMessageData && lastCustomerMessageData.length) {
    // if(lastCustomerMessageData && lastCustomerMessageData.length) {
      // console.log("Time Difference",moment().diff(moment(lastCustomerMessageData.sent_timestamp),"seconds"))
      console.log("Time Difference",moment().diff(moment(lastCustomerMessageData[0].sent_timestamp),"seconds"))
      dispatch(updateCustomerActive(true));
      // moment().diff(moment(dateFrom(lastCustomerMessageData.sent_timestamp)),"seconds") > 86400 ? dispatch(updateMessageAction(false)) : dispatch(updateMessageAction(true));
      moment().diff(moment(dateFrom(lastCustomerMessageData[0].sent_timestamp)),"seconds") > 86400 ? dispatch(updateMessageAction(false)) : dispatch(updateMessageAction(true));
    } else {
      dispatch(updateCustomerActive(false));
      // dispatch(toggleTemplateMessagePopUp(true));
    }
  }, [lastCustomerMessageData])
  
  useEffect(() => {
  }, [messageAction,customerActive])

  const hideNudge = () => {
    dispatch(hideNudgePopUp(false));
  }
  
  return (
    // <>
    // <DefaultMessage />
    // </>
    <>
      {
        templateMessagePopUp ? <DefaultMessage /> : ""
      }
      {
        uploadFilePopUp ? <ChatFileUpload /> : ""
      }
      {
        room_id ? messageAction && customerActive ? <div className="dave-chat-action-container input-group">
          {/* <button className="dave-default-chat btn btn-lg" onClick={toggleDefaultMessage}>
            <img src={require('../../images/DefaultTemplate.png')} alt="" style={templateMessagePopUp ? {"transform":"rotate(180deg)"} : {"transform":"rotate(0deg)"}} />
          </button> */}
          <div className="dave-message-and-upload-container">
            <textarea disabled={uploadFilePopUp} rows={1} type="text" className='dave-user-message-typing' onKeyDown={sendMessageOnEnter} onClick={unsetMessageCount} value={room_id in messageText ? messageText[room_id] : ""} onChange={changeMessageText}/>
            <button className="dave-chat-attachment-btn btn btn-md" onClick={toggleUploadFile}>
              <img src={attachmentIconSVG} alt="" />
            </button>
          </div>
          <button className="dave-chat-send btn btn-lg" onClick={sendMessage}>
            <img src={sendIconSVG} alt="" />
          </button>
        </div> : customerActive === false && !lastCustomerMessageLoading ? <div className="dave-chat-room-connection-error-container">
          <span className="dave-chat-room-connection-message">
            Customer has not accepted chat yet. <span className="dave-default-message" onClick={nudgeUser}>{messages[0]}</span> to nudge customer.
          </span>
        </div> : messageAction === false && !lastCustomerMessageLoading && <div className="dave-chat-room-connection-error-container">
          <span className="dave-chat-room-connection-message">
            Customer has not replied for 24 hours. <span className="dave-default-message" onClick={nudgeUser}>{messages[0]}</span> to nudge customer.
          </span>
        </div>  : <div className="dave-chat-room-connection-error-container">
          <span className="dave-chat-room-connection-message">
            Unable to connect to chat room : CHAT ROOM MISSING
          </span>
        </div> && console.log("room_id",room_id)
      }
      {
        nudgePopUp && <NudgeNotification propsData={ activeTicketData } sCallBack= { hideNudge } />
        // <NudgeNotification propsData={ activeTicketData } sCallBack= { hideNudge } />
      }
      {/* {
        room_id ? messageAction && customerActive && !lastCustomerMessageLoading ? <div className="dave-chat-action-container input-group">
          <button className="dave-default-chat btn btn-lg" onClick={toggleDefaultMessage}>
            <img src={require('../../images/DefaultTemplate.png')} alt="" style={templateMessagePopUp ? {"transform":"rotate(180deg)"} : {"transform":"rotate(0deg)"}} />
          </button>
          <div className="dave-message-and-upload-container">
            <textarea disabled={uploadFilePopUp} rows={1} type="text" className='dave-user-message-typing' onKeyDown={sendMessageOnEnter} onClick={unsetMessageCount} value={room_id in messageText ? messageText[room_id] : ""} onChange={changeMessageText}/>
            <button className="dave-chat-attachment-btn btn btn-md" onClick={toggleUploadFile}>
              <img src={attachmentIconSVG} alt="" />
            </button>
          </div>
          <button className="dave-chat-send btn btn-lg" onClick={sendMessage}>
            <img src={sendIconSVG} alt="" />
          </button>
        </div> : !customerActive && !lastCustomerMessageLoading ? <div className="dave-chat-room-connection-error-container">
          <span className="dave-chat-room-connection-message">
            Customer has not accepted chat yet. <span className="dave-default-message">{messages[0]}</span> to nudge customer.
          </span>
        </div> : !messageAction && !lastCustomerMessageLoading && <div className="dave-chat-room-connection-error-container">
          <span className="dave-chat-room-connection-message">
            Customer has not replied for 24 hours. <span className="dave-default-message">{messages[0]}</span> to nudge customer.
          </span>
        </div>  : <div className="dave-chat-room-connection-error-container">
          <span className="dave-chat-room-connection-message">
            Unable to connect to chat room : CHAT ROOM MISSING
          </span>
        </div>
      } */}
    </>
  )
}
