import React from 'react';
import { useSelector , useDispatch } from "react-redux";
import { toggleTemplateMessagePopUp } from '../../feature/ChatActionSlice';
import './DefaultMessage.css';

export default function DefaultMessage() {
    const dispatch = useDispatch();
    const messages = ["Sure, give me a minute." , "Done" , "Yes" , "No" , "Let me get back to you." , "Sure, give me a minute." , "Done" , "Yes" , "No" , "Let me get back to you."];
    const { templateMessagePopUp } = useSelector( state => state.chatAction_reducer);
    const insertDefaultMessage = (e) => {
        console.log(e.target.innerText);
        document.querySelector('.dave-user-message-typing').value = e.target.innerText;
        dispatch(toggleTemplateMessagePopUp(!templateMessagePopUp));
    }

    return (
        <div className="dave-default-message-container col">
            {
                messages.map( message => <span className="dave-default-message" onClick={insertDefaultMessage}>{message}</span>)
            }
        </div>
    )
}
