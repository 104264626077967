import React , { useState , useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import './AddCustomerForm.css';
import dropdownIconSVG from "../../images/dropdown-icon.svg"
import calendarIconSVG from "../../images/calenderIcon.svg"
import sendIconSVG from "../../images/send-icon.svg"
import { mobile , inputSuccess , inputError, customerNameValidation } from '../common/Common'
import { useDispatch, useSelector } from 'react-redux';
import { activeChatStatusApi } from '../../feature/ChatStatusSlice';
import { getNotificationAPI } from '../../feature/NotificationSlice';
import { DMS_API } from '../../feature/DMS_APISlice';
import Loader from '../loader/Loader';
import { addCustomer , addCustomerAPI ,addWalkInCustomer , addAppointmentCustomer , toggleAddCustomerAPIFire, toggleTicketLoading } from '../../feature/AddCustomerOptionSlice';
export default function AddCustomerForm() {
    const dispatch = useDispatch();
    const {DMS_Data} = useSelector(state=>state.DMSAPI_reducer);
    const { user_id , HEADERS } = useSelector( state => state.login_reducer);
    const { sortByUpdated } = useSelector( state => state.sorting_reducer);
    const { kWS } = useSelector( state => state.searchJob_reducer);
    const { userInfoServiceCenterId , userInfoRole } = useSelector( state => state.user_reducer);
    const { smreData , smreLoading , serviceAdvisorData , serviceAdvisorLoading , wMData , cCMData } = useSelector( state => state.reassignTicket_reducer);
    const { showAddAppointmentCustomer , showAddWalkInCustomer , serviceTypeData , serviceTypeLoading , addCustomerAPIFire , addCustomerLoading } = useSelector( state => state.addCustomer_reducer);
    const [contactNumber, setContactNumber] = useState("");
    const [bookingDate, setbookingDate] = useState("");
    const vehicleModel = [ "ALTO" , "ALTO 800" , "ALTO K10" , "ALTO K10 (NEW)" , "A-STAR" , "BALENO" , "CELERIO" , "CIAZ" , "DZIRE" , "EECO" , "ERTIGA" , "ESTEEM" , "FRONX" , "GRAND VITARA" , "GYPSY" , "IGNIS" , "JIMNY" , "KIZASHI" , "M 800" , "NEW ALTO K10" , "NEW BALENO" , "NEW BREZZA" , "NEW CELERIO" , "NEW ERTIGA" , "NEW SWIFT" , "OMNI" , "RITZ" , "SCROSS" , "SMG DZIRE" , "S-PRESSO" , "SUPER CARRY" , "SWIFT" , "SWIFT DZIRE" , "SX4" , "VITARA BREZZA" , "WAGON R" , "WAGONR" , "XL6" , "ZEN" , "ZEN ESTILO" ]
    
    const [vehicleNumberList,setVehicleNumberList] = useState([]);
    const [currentVehicleNumber,setCurrentVehicleNumber] = useState({});
    const [currentVehicleModel,setCurrentVehicleModel] = useState([]);
    const [vehicleNumError,setVehicleNumError] = useState(false);
    const [currentVehicleModelText,setCurrentVehicleModelText] = useState("");
    const [datePickerShown,setDatePickerShown] = useState(false);
    useEffect(()=>{
        if(DMS_Data){
            setVehicleNumberList(DMS_Data?.map((data) => ({ value: data.REG_NUM, label: data.REG_NUM })))
            setCurrentVehicleModel([...new Set(DMS_Data?.map(data=>data?.MODEL_DESC))]);
            if(DMS_Data?.length<1){
                setCurrentVehicleModel([...vehicleModel])
            }
        }

    },[DMS_Data])
    useEffect(()=>{
        if(!(contactNumber.length===10)){
            setVehicleNumberList([]);
            setCurrentVehicleModel([]);
            setCurrentVehicleNumber("");
            setCurrentVehicleModelText("")
        }
    },[contactNumber])
    
    useEffect(()=>{
        const vehicleModel = document.getElementById('dave-customer-vehicle-model');
        console.log(currentVehicleModelText);
    },[currentVehicleModelText[0]])

    const closeAddCustomerForm = () => {
        dispatch(addCustomer(false));
        dispatch(addWalkInCustomer(false));
        dispatch(addAppointmentCustomer(false));
    };
    const addCustomerData = {
        HEADERS : HEADERS,
        customer_response : {
            agent_id : user_id,
            booking_date : "",
            booking_time : "",
            service_type : "",
            customer_name : "",
            booking_category : showAddAppointmentCustomer ? "appointment" : showAddWalkInCustomer ? "walk_in" : "",
            service_center_id : userInfoServiceCenterId,
            service_advisor_id : userInfoRole === "service advisor" ? user_id : "",
            smre_id : userInfoRole === "smre" ? user_id : "NA",
            wm_id : userInfoRole === "wm" ? user_id : "",
            ccm_id : userInfoRole === "ccm" ? user_id : "",
            vehicle_model : "",
            vehicle_reg_number : "",
            customer_contact_number : "",
        }
    };
    let errorField = [],
    dataValidated = false;
    
    const validateCustomerName =(e) => {
        e.target.style.border = inputSuccess;
    }
    const validateCustomerMobile = (e) => {
        if(mobile.test(e.target.value)) {
            e.target.style.border = inputSuccess;
            setContactNumber(e.target.value);
            dispatch(DMS_API({
                HEADERS : HEADERS,
                requestBody : e.target.value
            }));
        } else {
            e.target.style.border = inputError;
            if(e.target.value.charAt(0)!=='0')
                setContactNumber(e.target.value.replace(/\D/g, ''));
        }
    }
    const validateVehicleNumber = (e) => {
        e.target.style.border = inputSuccess;
    }
    const validateVehicleModel = (e) => {
        setCurrentVehicleModelText(e.target.value);
        e.target.parentElement.style.border = inputSuccess;
    }
    const validateServiceType = (e) => {
        e.target.parentElement.style.border = inputSuccess;
    }
    const validateAppointmentDate = (e) => {
        const pickedDate = new Date(e.target.value);
        const maxDate = new Date(e.target.getAttribute("max"));
        const minDate = new Date(e.target.getAttribute("min"))
        if(!(pickedDate <= maxDate && pickedDate >= minDate))
        {
            e.target.title="Hello";
        }
        console.log("picked:",pickedDate)
        console.log("maxDate:",maxDate);
        if(e.target.value.length === 10) {
            let dateFormat = e.target.value.split('-')
            setbookingDate(dateFormat[0] + "-" + dateFormat[1] + "-" + dateFormat[2]);
            e.target.parentElement.style.border = inputSuccess;
        }
        else{
            setbookingDate("")
        }
        setDatePickerShown(!datePickerShown);
    }
    const validateAppointmentTime = (e) => {
        e.target.parentElement.style.border = inputSuccess;
    }
    const validateSMRE = (e) => {
        e.target.parentElement.style.border = inputSuccess;
    }
    const validateServiceAdvisor = (e) => {
        e.target.parentElement.style.border = inputSuccess;
    }

    const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false }).slice(0,5).split(":")[0];
    const currentDate = new Date().toJSON().slice(0, 10);
    const handleSubmit = (e) => {
        e.preventDefault();
        dataValidated = true;
        
        const customerName = document.getElementById('dave-customer-name');
        const contactNumber = document.getElementById('dave-customer-contact-number');
        const vehicleModel = document.getElementById('dave-customer-vehicle-model');
        const serviceType = document.getElementById('dave-customer-service-type');
        const appointmentTime = document.getElementById('dave-customer-appointment-time');
        const appointmentDate = document.getElementById('dave-customer-appointment-date');
        const sMRE = document.getElementById('dave-customer-smre');
        const serviceAdvisor = document.getElementById('dave-customer-service-advisor');
        
        let formattedDate = appointmentDate && appointmentDate.value ? appointmentDate.value.split('-') : "";
        formattedDate = formattedDate ? formattedDate[2] + "-" + formattedDate[1] + "-" + formattedDate[0] : "";

        customerNameValidation.test(customerName.value) ? addCustomerData.customer_response.customer_name = customerName.value.trim().charAt(0).toUpperCase() + customerName.value.trim().slice(1) : validDataError("Customer Name",customerName);
        contactNumber.value && mobile.test(contactNumber.value) ? addCustomerData.customer_response.customer_contact_number = contactNumber.value : validDataError("Contact Number",contactNumber);
        currentVehicleNumber.value ? addCustomerData.customer_response.vehicle_reg_number = currentVehicleNumber.value.toUpperCase() : (()=>{setVehicleNumError(true);dataValidated=false})();
        vehicleModel.value ? addCustomerData.customer_response.vehicle_model = vehicleModel.value : validDataError("Vehicle Model",vehicleModel);

        serviceType.value ? addCustomerData.customer_response.service_type = serviceType.value : validDataError("Service Type",serviceType);

        appointmentTime && appointmentTime.value ? addCustomerData.customer_response.booking_time = appointmentTime.value : appointmentTime ? validDataError("Appointment TIME",appointmentTime) : console.log();
        appointmentDate && appointmentDate.value ? addCustomerData.customer_response.booking_date = formattedDate : appointmentDate ? validDataError("Appointment Date",appointmentDate) : console.log();
        sMRE && sMRE.value ? addCustomerData.customer_response.smre_id = sMRE.value : sMRE ? validDataError("SMRE",sMRE) : console.log();
        serviceAdvisor && serviceAdvisor.value ? addCustomerData.customer_response.service_advisor_id = serviceAdvisor.value : serviceAdvisor ? validDataError("Service Advisor",serviceAdvisor) : console.log();
        addCustomerData.customer_response.wm_id = wMData[0][0];
        addCustomerData.customer_response.ccm_id = cCMData[0][0];
        if(dataValidated) {
            dispatch(toggleTicketLoading(true));
            console.log(addCustomerAPIFire);
            console.log(addCustomerData);
            console.log("Add Customer Happened");
            dispatch(addCustomerAPI(addCustomerData)).then( () => {
                const userInfoParam = {
                HEADERS : HEADERS, 
                sortByUpdated : sortByUpdated,
                kws : kWS,
                serviceCenterId : userInfoServiceCenterId
            }
            setTimeout(() => {
                dispatch(activeChatStatusApi(userInfoParam)).then(()=>{
                    dispatch(toggleTicketLoading(false));
                });
            },2000);
            dispatch(getNotificationAPI(userInfoParam));
            });
            dispatch(addWalkInCustomer(false));
            dispatch(addAppointmentCustomer(false));
            setVehicleNumError(dataValidated);
            dataValidated = false;
        }
    }

    const validDataError = (errorFor,errorContainer) => {
        errorFor === "Vehicle Model" || errorFor === "Service Type" || errorFor === "Appointment TIME" || errorFor === "SMRE" || errorFor === "Service Advisor" || errorFor === "Appointment Date" ? errorContainer.parentElement.style.border = inputError : errorContainer.style.border = inputError;
        dataValidated = false;
    }

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    return (
        <div onClick={e=>{
            if(datePickerShown){
                setDatePickerShown(!datePickerShown);
            }
        }} className="dave-add-customer-popup-container col col-12">
            <div className="dave-shadow-box" onClick={closeAddCustomerForm}></div>
            <form style={{"--scroll-bg-color":addCustomerLoading || smreLoading || serviceTypeLoading?"rgba(27, 27, 27, 0.5)":"transparent","--sroll-border-radius":"",
            overflow : addCustomerLoading || smreLoading || serviceTypeLoading ? "hidden" : "auto"
        }} className='dave-add-walk-in-customer' onSubmit={handleSubmit}>
                <img src={require('../../images/Close.png')} alt="" className="dave-close-add-customer-form" onClick={closeAddCustomerForm}/>
                <div className="container-title">Add Customer</div>
                <div className="form-group">
                    <label htmlFor="dave-customer-name">Customer Name</label>
                    <input type="text" className="form-control" id="dave-customer-name" onChange={validateCustomerName} name='customerName'/>
                </div>
                <div className="form-group">
                    <label htmlFor="dave-customer-contact-number">Contact Number</label>
                    <input type="mobile" name='mobileNumber' className="form-control" id="dave-customer-contact-number" onChange={validateCustomerMobile} maxLength={10} value={contactNumber}/>
                </div>
                <div className="form-group">
                    <label htmlFor="dave-customer-vehicle-number">Vehicle Number</label>
                    <div style={{borderColor: currentVehicleNumber?.value ? "rgb(0, 128, 105)" :vehicleNumError?"red": "#000",}} className="dave-dropdown-container">
                        <CreatableSelect
                        classNamePrefix="react-select"
                        isDisabled={mobile.test(contactNumber)?false:true}
                        styles={{
                            indicatorSeparator:(baseStyle,state)=>({...baseStyle,visibility:"hidden"}),
                            
                            singleValue:(baseStyle,state)=>({...baseStyle,textTransform:"uppercase"}),
                            container:(baseStyle,state)=>{
                                return {
                                    ...baseStyle,
                                    width:"100%",
                                    "& .react-select__menu-list":{
                                        textTransform:"uppercase !important"
                                    },
                                    "& #react-select-3-listbox":{
                                        textTransform:"uppercase"
                                    },
                                    "& input":{
                                        textTransform:"uppercase",
                                        width:"100%",
                                        gridArea:"1 / 3 !important"
                                    },
                                    "& ::after":{
                                        display:"none"
                                    },
                                    "& #react-select-3-listbox #react-select-3-option-0":{
                                        textTransform:"uppercase"
                                    }
                                }
                            },
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border:"none",
                            width:"100%",
                            borderWidth: "2px",
                            outline: "none",
                            borderRadius: "10px",
                            boxShadow:"none",
                            "&:hover": {
                                outline: "none",
                            },
                            "&:focus": {
                              outline: "none",
                            },
                          }),
                        }}
                        placeholder={"Enter Vehicle Number"}
                        onChange={(option) => {
                            console.log(option);
                            if(!option){
                                setCurrentVehicleNumber("");
                                setVehicleNumError(true);
                            }
                            else
                                setCurrentVehicleNumber({value:option?.value.toUpperCase(),label:option?.value.toUpperCase()});
                            if(DMS_Data?.length>0){
                                const vehicleData = DMS_Data?.filter(data=>data.REG_NUM.includes(option?.value??""));
                                const selectedVehicleModel = vehicleData.map(vehicle=>vehicle.MODEL_DESC);
                                setCurrentVehicleModel(selectedVehicleModel);
                                if(!option){
                                    setCurrentVehicleModelText([]);
                                }
                                else{
                                    setCurrentVehicleModelText(selectedVehicleModel)
                                }
                                if(vehicleData.length<1){
                                    setCurrentVehicleModel([...vehicleModel]);
                                    setCurrentVehicleModelText("Select Vehicle Model")
                                }
                            }
                            else{
                                setCurrentVehicleModel([...vehicleModel]);
                                setCurrentVehicleModelText("Select Vehicle Model")
                            }
                            if(!mobile.test(contactNumber)){
                                setCurrentVehicleModel([]);
                                setCurrentVehicleNumber("");
                                setVehicleNumberList(null);
                            }
                        }}
                        value={currentVehicleNumber}
                        isClearable
                        options={[...vehicleNumberList].filter((value => !(value.value.length < 1)))}
                        />
                        <button className="dave-dropdown">
                            <img src={dropdownIconSVG} alt="" className="dave-dropdown-logo-img" />
                        </button>
                    </div>
            </div>
                <div className="form-group">
                    <label htmlFor="dave-customer-vehicle-model">Vehicle Model</label>
                    <div className="dave-dropdown-container">
                        <select value={currentVehicleModelText} disabled={currentVehicleNumber.value&&contactNumber.length===10?false:true} name='vehicleModel' className="form-control" id="dave-customer-vehicle-model" onChange={validateVehicleModel}>
                            <option value="">Select Vehicle Model</option>
                            {
                                currentVehicleNumber.value?currentVehicleModel?.map( model => <option value={model}>{model}</option>):""
                            }
                        </select>
                        <button className="dave-dropdown">
                            <img src={dropdownIconSVG} alt="" className="dave-dropdown-logo-img" />
                        </button>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="dave-customer-service-type">Service Type</label>
                    <div className="dave-dropdown-container">
                        <select className="form-control" id="dave-customer-service-type" onChange={validateServiceType} name='serviceType'>
                            <option value="">Select Service Type</option>
                            {
                                serviceTypeLoading != null && !serviceAdvisorLoading ? serviceTypeData.map( serviceType => <option value={serviceType}>{serviceType}</option>) : ""
                            }
                        </select>
                        <button className="dave-dropdown">
                            <img src={dropdownIconSVG} alt="" className="dave-dropdown-logo-img" />
                        </button>
                    </div>
                </div>
                {
                    showAddAppointmentCustomer ? <div className="form-group">
                        <div className="row">
                            <div style={{display:"flex",flexDirection:"column"}} className="col">
                                <label htmlFor="dave-customer-appointment-date">Appointment Date</label>
                                <label style={{width:"100%"}} htmlFor={"dave-customer-appointment-date"}>

                                <div onClick={(e)=>{
                                    if(!datePickerShown){
                                        document.getElementById("dave-customer-appointment-date").showPicker();
                                    }
                                    setDatePickerShown(!datePickerShown);
                                    // document.getElementById("dave-customer-appointment-date").focus();
                                }} 
                                className="dave-dropdown-container">
                                    <img className="calendarIcon" src={calendarIconSVG} />
                                    <span className="date-pretext">{bookingDate.split("-").reverse().join("-") || "DD-MM-YYYY"}</span>
                                    {/* <input placeholder="DD-MM-YYYY" style={{border:"none"}} type="date" className="form-control" id="dave-customer-appointment-date" min={new Date().toISOString().split('T')[0]} max={new Date(new Date().setDate(new Date().getDate()+90)).toISOString().split('T')[0]} onChange={validateAppointmentDate} value={bookingDate} name='appointmentDate'/> */}
                                    <input placeholder="DD-MM-YYYY" style={{border:"none"}} type="date" className="form-control" id="dave-customer-appointment-date" min={new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split('T')[0]} max={new Date(new Date().setDate(new Date().getDate()+90)).toISOString().split('T')[0]} onChange={validateAppointmentDate} value={bookingDate} name='appointmentDate'/>
                                </div>
                                </label>
                            </div>
                            <div className="col">
                                <label htmlFor="dave-customer-appointment-time">Appointment Time</label>
                                <div className="dave-dropdown-container">
                                    <select className="form-control" id="dave-customer-appointment-time" onChange={validateAppointmentTime}  name='appointmentTime'  disabled={bookingDate ? false : true}>
                                        <option value="">Select Time</option>
                                        { currentDate === bookingDate ? currentTime < 8 ? <option value="06:00-08:00">06:00-08:00</option> : "" : <option value="06:00-08:00">06:00-08:00</option> }
                                        { currentDate === bookingDate ? currentTime < 10 ? <option value="08:00-10:00">08:00-10:00</option> : "" : <option value="08:00-10:00">08:00-10:00</option> }
                                        { currentDate === bookingDate ? currentTime < 12 ? <option value="10:00-12:00">10:00-12:00</option> : "" : <option value="10:00-12:00">10:00-12:00</option> }
                                        { currentDate === bookingDate ? currentTime < 14 ? <option value="12:00-14:00">12:00-14:00</option> : "" : <option value="12:00-14:00">12:00-14:00</option> }
                                        { currentDate === bookingDate ? currentTime < 16 ? <option value="14:00-16:00">14:00-16:00</option> : "" : <option value="14:00-16:00">14:00-16:00</option> }
                                        { currentDate == bookingDate ? currentTime < 18 ? <option value="16:00-18:00">16:00-18:00</option> : "" : <option value="16:00-18:00">16:00-18:00</option> }
                                        { currentDate === bookingDate ? currentTime < 20 ? <option value="18:00-20:00">18:00-20:00</option> : "" : <option value="18:00-20:00">18:00-20:00</option> }
                                    </select>
                                    <button className="dave-dropdown">
                                        <img src={dropdownIconSVG} alt="" className="dave-dropdown-logo-img" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : ""
                }
                {
                        showAddAppointmentCustomer && ( userInfoRole === 'wm' || userInfoRole === 'ccm' ) ? <div className="form-group" id='smre-container'>
                        <label htmlFor="dave-customer-smre">SMRE</label>
                        <div className="dave-dropdown-container">
                            <select className="form-control" id="dave-customer-smre" onChange={validateSMRE}  name='SMRE'>
                                <option value="">Select SMRE</option>
                                {
                                    smreLoading != null && !smreLoading ? smreData.map( smre => <option value={smre[0]}>{smre[1]}</option>) : ""
                                }
                            </select>
                            <button className="dave-dropdown">
                                <img src={dropdownIconSVG} alt="" className="dave-dropdown-logo-img" />
                            </button>
                        </div>
                    </div> : ""
                }
                {
                        userInfoRole === 'wm' || userInfoRole === 'ccm' || userInfoRole === 'smre' ? <div className="form-group" id='service-advisor-container'>
                        <label htmlFor="dave-customer-service-advisor">Service Advisor</label>
                        <div className="dave-dropdown-container">
                            <select className="form-control" id="dave-customer-service-advisor" onChange={validateServiceAdvisor}  name='serviceAdvisor'>
                                <option value="">Select Service Advisor</option>
                                {/* {
                                    serviceAdvisorLoading != null && !serviceAdvisorLoading ? Object.keys(serviceAdvisorData).map( serviceAdvisor => <option value={serviceAdvisor}>{serviceAdvisor}</option>) : ""
                                } */}
                                {
                                    serviceAdvisorLoading != null && !serviceAdvisorLoading ? serviceAdvisorData.map( serviceAdvisor => {
                                        {console.log('serviceAdvisor======', serviceAdvisor)}
                                        return(<option value={serviceAdvisor[0]}>{serviceAdvisor[1]}</option>)}) : ""
                                }
                            </select>
                            <button className="dave-dropdown">
                                <img src={dropdownIconSVG} alt="" className="dave-dropdown-logo-img" />
                            </button>
                        </div>
                    </div> : ""
                }
                <button style={{border:"2px solid #000 !important"}} type="submit" className="dave-add-customer-submit btn btn-primary">
                    <span className="dave-add-customer-submit-btn-text">SEND REQUEST</span>
                    <img src={sendIconSVG} alt="" className="dave-add-customer-submit-btn-img" />
                </button>
                {addCustomerLoading || smreLoading || serviceTypeLoading?<Loader style={{position:"absolute",height:"100%",width:"100%",}}/>:""}
            </form>
        </div>
    )
}
