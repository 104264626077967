import io from 'socket.io-client';

export class DAVE_SPACE {

    space_manager_url = undefined;
    sendMessage = undefined;
    
    user_id = undefined;
    pingUrl = undefined;
    
    callbackEvents = ["connect-error", "backend-error", "connect-success", "data", "kickout", "disconnect"];
    // space_manager_url = "https://multiparty-orchestrator.iamdave.ai/";
    
    constructor(enterprise_id, customer_id, apikey, username, space_id, roomname, space_manager_url, callback, errorCallback, enableRemoteLogging = false, logServerUrl = "http://0.0.0.0:7000") {
        // if (enableRemoteLogging) {
        //     this.rel = new EventLogsManager.EventLogger(logServerUrl);
        // }
        let that = this;
        this.customCallbacks = (function () {
            let c = {};
            for (let event of that.callbackEvents) {
                c[event] = {};
            }
            return c;
        })()
        
        this.firstConnection = true;
        this.username = username;
        this.callback = callback;
        this.errorCallback = errorCallback;
        this.space_id = space_id;
        this.enterprise_id = enterprise_id;
        this.customer_id = customer_id;
        this.apikey = apikey;
        this.roomname = roomname;
        this.space_manager_url = space_manager_url;
        
        this.connect();
    }
    
    connect() {
        
        if (!this.enterprise_id) {
            console.error("P2P: Enterprise Id is invalid.")
            return;
        }
        
        if (!this.customer_id) {
            console.error("P2P: Customer Id is invalid.");
            return;
        }
        
        if (!this.apikey) {
            console.error("P2P: Apikey is invalid.");
            return;
        }
        
        this.doUserAuth(this.enterprise_id, this.customer_id, this.apikey);
        
        if (!this.username) {
            console.error("P2P: Username is invalid.");
            return;
        }
        
        if (!this.callback) {
            console.log("P2P: Success callback not given.");
        }
        
        if (!this.errorCallback) {
            console.log("P2P: Error callback not given.");
        }
        
        try {
            if (!this.space_manager_url) {
                console.log("P2P : Space Manager URL is not set.");
                return;
            }
        } catch (e) {
            console.error("P2P : Some issue in fetching space manager url.");
            console.error("P2P : "+e);
        }
        
        console.log("P2P : Connecting to Space.");
        this.space_login(this.space_manager_url, this.username, this.space_id, this.roomname).then(this.connectToMessagingServer.bind(this), this.spaceConnectionFailedHandler.bind(this));
    }
    
    // disconnect() {
    //     if (!this.disconnectWS) {
    //         console.log("P2P : Socketio not initialized.");
    //         return false
    //     } else {
    //         this.disconnectWS();
    //     }
    // }
    
    validate_connection() {
        console.log("P2P : Reconnecting....");
            
        if (this.disconnected) {
            console.log("P2P : Connection is not alive.");

            if(!this.firstConnection) {
                // this.disconnect();
            }
            this.websocket_connect_sequence(true);
            this.disconnected = false;
            this.firstConnection = false;
        } else {
            console.log("P2P : Connection already live.");
        }
        
    }
    
    doUserAuth(enterprise_id, customer_id, apikey) {
        console.log("P2P : Skipping auth for now.");
    }
    
    getActiveUserCount(successCallback, failureCallback) {
        this.getUsers(false, function(data) {
            successCallback(data.data);
        }, failureCallback);
    }
    
    isConnected() {
        if (!this.socketio) {
            return null;
        } 
        return this.socketio.connected;
    }
    
    registerCallback(callback_function, data_id, e) {
        e = e || 'data';
        if (!this.customCallbacks.hasOwnProperty(e)) {
            console.error("P2P : No such event exists : "+e);
            return false;
        }
        data_id = data_id || 'data-callback';
        if (!this.customCallbacks[e].hasOwnProperty(data_id)) {
            this.customCallbacks[e][data_id] = [];
        }
        this.customCallbacks[e][data_id].push(callback_function);
        console.log("P2P : Regestered callback "+e+" "+data_id)
    }
    
    unregisterCallback(data_id, callback_function, e) {
        e = e || 'data';
        if (!this.customCallbacks.hasOwnProperty(e)) {
            console.error("P2P : No such event exists : "+e);
            return false;
        }
        data_id = data_id || 'data-callback';
        console.log("P2P : Unregestering callback "+data_id+" for event" + e)
        if (!callback_function) {
            delete this.customCallbacks[e][data_id];
        } else {
            let i = this.customCallbacks[e][data_id].indexOf(callback_function);
            if (i >= 0 ) {
                this.customCallbacks[e][data_id].splice(i, 1);
            }
        }
    }
    
    executeCallbacks(event, data) {
        if (!data) {
            return false;
        }
        
        let data_id = data["data_id"];
        
        if (data_id === undefined) {
            return false;
        }
        
        if (!this.customCallbacks[event]) {
            return false;
        }
        
        if (!this.customCallbacks[event][data_id]) {
            return false;
        }
        
        let cb = this.customCallbacks[event][data_id];
        
        for (let key of cb) {
            if (typeof(key) == "function") {
                console.log("P2P : Executing callback "+event+" "+data_id);
                try {
                    // console.log("Callback function --> "+key);
                    let r = key(data);
                    if (r == false) {
                        return false;
                    } 
                }catch(e) {
                    console.error("P2P : Error Executing callback "+event+" : "+data_id+"\n"+e);
                }
            }
        }
    }
    
    getUsers(list, successCallback, failureCallback) {
        let that = this;
        let number = undefined;
        let path = "subscribers"
        if (!list) {
            path = path+"/count"
        }
        
        path = path + '/' + this.space_id;
        path = path + '/' + this.room_id;
        
        let headers = {
            "X-I2CE-ENTERPRISE-ID": this.enterprise_id,
            "X-I2CE-USER-ID" : this.user_id,
            "X-I2CE-API-KEY" : this.apikey
        }
        
        let resolve = function(data) {
            data = JSON.parse(data);
            if (successCallback) {
                // data = JSON.parse(data);
                successCallback(data);
            }
        }
        
        let reject =  function (data) {
            console.log("P2P : Continuing ping - HTTP call failed.");
            if (failureCallback) {
                data = JSON.parse(data);
                failureCallback(data["message"]);
            }
        }
        
        try {
            that.getData(path, headers, [], resolve, reject);
        }
        catch (e) {
            console.error("P2P : Error in getting user count.")
        }
        return number
    }
    
    static parseResponse(data) {
        
        data = JSON.parse(data);
        if (data.status == true) {
            return data.data;
        }
        
        console.log("P2P : API returned failure status.");
        console.log(data);
        return false;
    }
    
    stopPing() {
        console.log("P2P: Stopping PING.");
        clearInterval(this.pingInterval);
    }
    
    doPing(url) {
        let that = this;
        console.log("P2P: Trying to ping");
        
        let successCallback = function(data) {
            console.debug("P2P : Got PONG.");
        }
        
        let failureCallback =  function (data) {
            console.log("P2P : Continuing ping - HTTP call failed.");
            console.log(data);
        }
        
        this.pingInterval = setInterval(function () {
            let purl = new URL(url);
            that.getData(purl.pathname,{},[],successCallback,failureCallback);
            
            that.validate_connection();
        }, 5000)
    }
    
    doCallback(callbackURL) {
        let that = this;
        console.log("P2P: Callback URL "+callbackURL);
        console.log("P2P", "doing callback for subscribe", {"callback_url":callbackURL});
        let cbURL = callbackURL;        
        let successCallback = function (data) {
            data = DAVE_SPACE.parseResponse(data);
            
            console.log("P2P :"+JSON.stringify(data));
            console.debug(data);
            
            if (!data) {
                console.log("P2P : continuing callback - server gave negative response.");
                return    
            }
            
            if (data.hasOwnProperty("messaging_server_url")) {
                clearInterval(callbackInterval);
                this.connectToMessagingServer(data);
            } else {
                console.log("P2P : Continuing callback - success.");
                // console.log(data);
            }
        };
        
        let failureCallback = function (data) {
            console.log("P2P : Continuing callback - HTTP call failed.");
            console.log(data);
            let err = "Chat room missing or need more time."
            this.errorCallback({"error":err});
        };
        
        let inervalFunction = function () {
            that.getData(cbURL, {}, [], successCallback, failureCallback);
        };
        
        var callbackInterval = setInterval(inervalFunction, 2000);
    }
    
    
    login_room() {
        console.log("P2P: Entering Room");
        let room = this.currentRoom;
        let payload = {
            "username" : this.username,
            // "password" : password,
            "room" : room
        }
        console.log("P2P" + JSON.stringify(payload));
        
        this.socketio.emit("join", payload)
    }
    
    initWs() {
        var that = this;
        let sml = this.messaging_server_url.replace("https://","").split("/");
        let path = undefined;
        if (sml.length == 2) {
            this.messaging_server_url = "https://"+sml[0];
            path = "/"+sml[1];
        } else {
            path = "/";
        }
        
        this.sioquery = {uid : this.username, space_id : this.space_id};
        this.sioattributes = {query: this.sioquery, transports: ['websocket'],  path : path+"/sio"};
        this.socketio = io(this.messaging_server_url, this.sioattributes);
        
        console.log("P2P: Initing..")
        
        this.socketio.on('connect', function(e) {
            console.log("P2P: Connected...");
            console.log("P2P", "Websocket connected");
            that.validate_connection();
        });
        
        this.socketio.on('disconnect', function(e) {
            console.log("P2P: Disconnected...");
            that.disconnected = true;
            that.stopPing();
        });
        
        this.socketio.on('data', function(data) {
            
            console.log("P2P :"+JSON.stringify(data));
            // console.debug(data);
            that.executeCallbacks('data',data);
        });
        
        this.socketio.on('message', function(data) {
            console.log("P2P : Message :"+ data);
            
        });
        
        this.socketio.on('backend-error', function(data){
            console.log("P2P: backend error : "+JSON.stringify(data));
            console.debug(data);
            that.executeCallbacks('backend-error', data);
        });
        
        this.socketio.on('kickout', function (data) {
            console.log("P2P: Disconnected");
            that.executeCallbacks('kickout', data)
        });
        
        this.socketio.on("connect_error", (error) => {
            let err = `connect_error due to ${error.message}`;
            console.log(err);
            that.errorCallback({"error":err});
        });

        this.socketio.on("error", (err) => {
            let err_msg = `socket error ${err}`;
            console.log(err_msg);
            that.errorCallback({"error":err_msg});
        });


        this.sendReadReceipt = function(message_id, command, data_id) {

            if (!data_id) {
                console.debug("P2P: Data id is empty. Assuming data-callback.");
            }
            data_id = data_id || 'data-callback';

            var payload = {
                "data_id" : data_id,
                "space_id" : this.space_id,
                "room_id" : this.room_id,
                "sender_id" : this.username,
                "message_id" : message_id,
                "sent_timestamp":Math.floor(Date.now() / 1000),
                "emit_timestamp" : undefined,
                "sent_via" : "socket",
                "type" : "command",
                "command": command
            }

            that.socketio.emit('data', payload);
            return payload
        }

        this.mpsend = function (type, data, data_id) {
            let permittedDataTypes = ["data","text","media","voice","command","reaction"]
            
            if (!permittedDataTypes.includes(type)) {
                console.error("Unsupported data type.");
                return false;
            }
            
            var payload = {
                "data_id": data_id,
                "space_id": this.space_id,
                "room_id": this.room_id,
                "sender_id":  this.username,
                "sent_timestamp":Math.floor(Date.now() / 1000),
                "type": type
            }

            payload[type] = data;
            
            that.socketio.emit('data',payload, function(response) {
                that.executeCallbacks("message-received-by-server", {"status":response.status});
            });
            return payload;
        }
        
        this.sendMedia = function(media_data, data_id) {

            if (!media_data) {
                console.error("P2P: Media Object is empty.")
                return
            }
            if (!data_id) {
                console.debug("P2P: Data id is empty. Assuming data-callback.");
            }
            data_id = data_id || 'data-callback';
            return this.mpsend("media", media_data, data_id);

        }
        this.sendMessage = function (message, data_id) {
            
            if (!message) {
                console.error("P2P: Message is empty string.")
                return
            }
            if (!data_id) {
                console.debug("P2P: Data id is empty. Assuming data-callback.");
            }
            data_id = data_id || 'data-callback';
            
            return this.mpsend("text", message, data_id);
            
        }
        
        this.sendData = function (data, data_id) {
            data_id = data_id || 'data-callback';
            if (Object.keys(data).length === 0) {
                console.error("P2P: Data is empty.")
                return
            }
            
            if (!data_id) {
                console.log("P2P: Data id is empty. Assuming data-callback.")
            }
            
            return this.mpsend("data", data, data_id);
            
        }
        
        // this.sendMessage =  this.sendMessage;
        
        this.disconnect = function() {
            console.log("P2P: Disconnecting from WS");
            that.socketio.disconnect();
            
            that.executeCallbacks("disconnect", {});
            
            that.stopPing();
        }

        if (!this.socketio) {
            let err =  "socket io not initialized."
            console.error(err);
            this.errorCallback({"error":err});
        }
    }
    
    
    space_login(space_manager_url, username, space_id, room_id, gender = "", avatar_id = "") {
        let that = this;
        
        let p = new Promise(function (resolve, reject) {
            let payload = {
                "name" : username,
                "room_id" : room_id,
                "gender" : gender,
                "avatar_id" : avatar_id,
                "space_id" : space_id
            }
            
            let s = space_id;
            
            if (room_id) {
                s = s+"/"+room_id;
            }


            that.postData("subscribe/"+s, {"Content-Type":"application/json"}, [], payload, resolve, reject)
        })
        
        return p;
    }

    websocket_connect_sequence(reconnect = false) {
        if (!reconnect) {
            this.initWs(); 
        }
        this.login_room(); 
        this.executeCallbacks("connect-success", this.original_connection_data);
    }
    
    connectToMessagingServer(data) {

        if (!data.status) {
            this.errorCallback({"error":data.message});
        }

        console.log("P2P: Connecting to messaging servers.");
        let that = this;
        if (typeof(data) != "object") {
            data = DAVE_SPACE.parseResponse(data);
        }
        
        if (!data) {
            console.error("P2P : Connecting to message server failed.")
            return;
        }
        
        if (data.hasOwnProperty('messaging_server_url')) {
            console.log("P2P : Got room details. Connecting to room.");
            console.log("P2P", "subscribe success", data);
            // Space.loadStuff();
            console.log(data);
            
            Object.assign(this, data);
            this.original_connection_data = data;
            
            this.currentRoom = this.location;
            // DAVE_SPACE_MANAGER.initFunctions();
            this.websocket_connect_sequence();
            this.callback(data);
        }else {
            this.doCallback(data["callbackurl"]);
        }   
        this.doPing(data["pingUrl"]);        
    }
    
    spaceConnectionFailedHandler(data) {
        console.log("P2P : Connecting to space manager failed.");
        console.log("P2P :"+JSON.stringify(data));
        console.debug(data);
        // this.executeCallbacks("connect-error", data.message);    
    }
    
    getData(path, header, parameters = [], callbackFunction, failureCallback) {
        let http = new XMLHttpRequest();
        
        let url = this.space_manager_url+path;
        
        function setHeaders(headers){
            for(let key in headers){
                http.setRequestHeader(key, header[key]) 
            }
        }
        
        function setParameters(params) {
            for (var i = params.length - 1; i >= 0; i--) {
                url = url+"?"+params[i]
            }
            // console.log("url after adding parameters "+path);
        }
        
        setParameters(parameters);
        http.open("GET", url, true);
        setHeaders(header);
        http.onreadystatechange = function (e) {
            if (this.readyState == 4) {
                if (this.status == 200){
                    let response = this.responseText;
                    if (callbackFunction){
                        callbackFunction(response)
                    } else {
                        // console.log(response);
                    }
                } else {
                    // console.log("Non 200 response code: "+http.status)
                    failureCallback(this.responseText)
                    return "failure from server."
                }
            } else {
                // failureCallback()
                // console.log("Non success readyState: "+http.readyState)
                return "failure from http.open."
            }
        };
        http.send();
        
    }
    
    
    postData(path, header, parameters, payload, callbackFunction) {
        let http=new XMLHttpRequest();
        
        function setHeaders(headers){
            for(let key in headers){
                http.setRequestHeader(key, header[key]) 
            }
        }
        
        let url = this.space_manager_url+path;
        http.open("POST", url, true);
        setHeaders(header);
        http.onreadystatechange = function (e) {
            if (this.readyState == 4) {
                if (this.status == 200){
                    let response = this.responseText;
                    if (callbackFunction){
                        callbackFunction(response)
                    }
                } else {
                    // console.log("Non 200 response code: "+http.status)
                    return "failure from server."
                }
            } else {
                // console.log("Non success readyState: "+http.readyState)
                return "failure from http.open."
            }
        };
        // console.log(payload)
        let data = JSON.stringify(payload)
        // console.log(data)
        http.send(data);
        
    }
}
