import React from 'react';
import { useDispatch , useSelector } from "react-redux";
import { addCustomer ,addWalkInCustomer , addAppointmentCustomer } from '../../feature/AddCustomerOptionSlice';
import './AddNewCustomer.css';

export default function AddNewCustomer() {
    const dispatch = useDispatch();
    const { showAddCustomer , showAddWalkInCustomer , showAddAppointmentCustomer } = useSelector( state => state.addCustomer_reducer);
    const { userInfoRole } = useSelector( state => state.user_reducer);
    const addWalkinCustomer = (e) => {
        dispatch(addCustomer(!showAddCustomer));
        dispatch(addWalkInCustomer(!showAddWalkInCustomer));
        dispatch(addAppointmentCustomer(false));
    };
    const addAppointmentcustomer = (e) => {
        dispatch(addCustomer(!showAddCustomer));
        dispatch(addAppointmentCustomer(!showAddAppointmentCustomer));
        dispatch(addWalkInCustomer(false));
    };
    const closeAddCustomerPopUp = () => {
        dispatch(addCustomer(false));
        dispatch(addWalkInCustomer(false));
        dispatch(addAppointmentCustomer(false));
    }
    return (
        <div className="dave-add-customer-popup-container col col-12">
            <div className="dave-shadow-box" onClick={closeAddCustomerPopUp} ></div>
            {/* <div className="dave-addcustomer-popup col col-3" tabIndex={ 0 } onBlur={hideAddCustomer}> */}
            <div className="dave-addcustomer-popup col col-3">
                <img src={require('../../images/Close.png')} alt="" className="dave-close-add-customer-popup" onClick={closeAddCustomerPopUp} />
                {
                    userInfoRole === "service advisor" || userInfoRole === "ccm" || userInfoRole === "wm" ? <button className="btn btn-lg dave-walkin-customer" onClick={addWalkinCustomer}>Walk-in</button> : ""
                }
                {
                    userInfoRole === "smre" || userInfoRole === "ccm" || userInfoRole === "wm" ? <button className="btn btn-lg dave-appointment-customer" onClick={addAppointmentcustomer}>New Appointment</button> : ""
                }
            </div>
        </div>
    )
}
