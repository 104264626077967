import React from 'react';
import { useDispatch , useSelector } from 'react-redux';
import './Logout.css';
import { getWM , getCCM } from '../../feature/ReassignSlice';
import { toggleLogout } from '../../feature/LogoutSlice';
import hamburgerIconSVG from "../../images/hamburger-icon.svg"
import H_hamburgerIconSVG from "../../images/hamburger-icon-h.svg"

export default function Logout() {
  const dispatch = useDispatch();
  const { HEADERS } = useSelector( state => state.login_reducer);
  const { userInfoServiceCenterId } = useSelector( state => state.user_reducer);
  const { logout } = useSelector( state => state.logout_reducer);
  const logoutPopup = (e) => {
    const userInfoParam = {
      HEADERS : HEADERS,
      service_center_id : userInfoServiceCenterId
  }
    dispatch(getWM(userInfoParam));
    dispatch(getCCM(userInfoParam));
    dispatch(toggleLogout(!logout));
  }
  return (
    <>
    <button className="dave-logout-btn btn btn-lg" onClick={logoutPopup} style={logout ? {"background": "#fff","z-index":"4"} : {"background": "#008069"}}>
      <img src={logout ? H_hamburgerIconSVG : hamburgerIconSVG} alt="" className="dave-logout-img" />
    </button>
    <button className="dave-logout-btn i-pad btn btn-lg" onClick={logoutPopup} style={logout ? {"background": "#008069","z-index":"4"} : {"background": "#fff"}}>
      <img src={logout ? hamburgerIconSVG :  H_hamburgerIconSVG} alt="" className="dave-logout-img" />
    </button>
    </>
  )
}
