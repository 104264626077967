import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API_Calls from './API_Call';
import * as API from "./API_Path";

export const nudgeApi = createAsyncThunk("nudgeApi", async (para,{rejectWithValue}) => {
    const { booking_id , customer_mobile_number , service_whatsapp_number , room_id , sender_id } = para;
    const body = {
        "booking_id" : booking_id,
        "customer_mobile_number" : customer_mobile_number,
        "service_whatsapp_number" : service_whatsapp_number,
        "room_id" : room_id,
        "sender_id" : sender_id
    }
    try {
        const response = await API_Calls.postMethod(API.NUDGE_API,body);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const nudgeUser = createSlice({
    name : "nudgeUser",
    initialState : {
        nudgeApiData : "",
        nudgeApiLoading : "",
        nudgeApiError : "",
        nudgePopUp : false
    },
    reducers : {
        hideNudgePopUp: (state,action) => {
            state.nudgePopUp = action.payload;
        }
    },
    extraReducers : {
        [nudgeApi.pending] : (state) => {
            state.nudgeApiLoading = true;
        },
        [nudgeApi.fulfilled] : (state, action) => {
            state.nudgeApiLoading = false;
            state.nudgeApiData = action.payload.info;
        },
        [nudgeApi.rejected] : (state, action) => {
            state.nudgeApiLoading = false;
            state.nudgeApiError = action.payload;
        }
    }
});

export const { hideNudgePopUp } = nudgeUser.actions;
export default nudgeUser.reducer;