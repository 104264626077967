import React from 'react';
import { useSelector } from 'react-redux';
import './LoginError.css';

export default function LoginError() {
  const { error, attemptCount } = useSelector( state => state.login_reducer);
  return (
    <div className="dave-login-error-container">
        <span className="dave-login-error-message">{error}</span>
    </div>
  )
}
