import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Navbar from '../navbar/Navbar';
import CustomerDetails from '../customerDetails/CustomerDetails';
import AddNewCustomer from '../addNewCustomer/AddNewCustomer';
import AddCustomerForm from '../addNewCustomer/AddCustomerForm';
import Logout from '../logoutPage/Logout';
import { emailAPI , wmEmailAPI } from '../../feature/SendEmailSlice';
import { searchTicket } from '../../feature/TicketDataSlice';
import { deleteFromAdvisorEmailCounterList , deleteFromManagerEmailCounterList } from '../../feature/SendEmailSlice';
import Loader from '../loader/Loader';

export default function ChatPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showAddCustomer , showAddWalkInCustomer , showAddAppointmentCustomer } = useSelector( state => state.addCustomer_reducer);
  const { logout } = useSelector( state => state.logout_reducer);
  const { api_key , enterprise_id , user_id , loading , HEADERS } = useSelector( state => state.login_reducer);
  const { advisorEmailCounterList , managerEmailCounterList } = useSelector( state => state.email_reducer);
  const { sTicketData } = useSelector( state => state.searchJobTicket_reducer);
  const headerData = {
    HEADERS : HEADERS,
  }
  const sendEmailNotification = () => {
    console.log(sTicketData);
    console.log(advisorEmailCounterList);
    console.log(managerEmailCounterList);
    for( let roomId in advisorEmailCounterList ) {
      if(sTicketData[roomId]?.service_advisor_id === user_id && advisorEmailCounterList[roomId]){
        console.log(advisorEmailCounterList[roomId]);
        if(advisorEmailCounterList[roomId] && new Date(new Date(advisorEmailCounterList[roomId]*1000).getTime() + 600000) < new Date(new Date().getTime())) {
          console.log(new Date(new Date(advisorEmailCounterList[roomId]*1000).getTime() + 600000) + "<<<--->>>" + new Date(new Date().getTime()))
          headerData.sTicketData = sTicketData[roomId];
          headerData.emailSentTo = "advisor"
          // dispatch(emailAPI(headerData));
          dispatch(deleteFromAdvisorEmailCounterList(roomId));
        } 
      }
    }for( let roomId in managerEmailCounterList ) {
      if(sTicketData[roomId]?.service_advisor_id === user_id && managerEmailCounterList[roomId]){
        if(new Date(new Date(managerEmailCounterList[roomId]*1000).getTime() + 1200000) < new Date(new Date().getTime())) {
          console.log(new Date(new Date(managerEmailCounterList[roomId]*1000).getTime() + 1200000) + "<<<--->>>" + new Date(new Date().getTime()))
          headerData.sTicketData = sTicketData[roomId];
          headerData.emailSentTo = "manager"
          // dispatch(emailAPI(headerData)).then(()=>{
          // dispatch(wmEmailAPI(headerData));
          // });
          dispatch(deleteFromManagerEmailCounterList(roomId));
        }
      }
    }
  }
  useEffect(() => {
    if (loading === null && (!api_key || !enterprise_id || !user_id)) {
      localStorage.clear();
      navigate("/");
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    for( let roomId in advisorEmailCounterList ) {
      headerData.tRoom_id = roomId
      console.log(roomId);
      dispatch(searchTicket(headerData)).then(() => {
      });
    }
    for( let roomId in managerEmailCounterList ) {
      headerData.tRoom_id = roomId
      console.log(roomId);
      dispatch(searchTicket(headerData)).then(() => {
      });
    }
  }, [advisorEmailCounterList, managerEmailCounterList])
  useEffect(() => {
    const interval = setInterval(() => {
      sendEmailNotification();
    }, 10000);
    return ()=>{clearInterval(interval);}
  },[advisorEmailCounterList,managerEmailCounterList,sTicketData]);
  
  
  
  return (
    <>
        <Navbar/>
        <CustomerDetails/>
        {
          showAddCustomer ? <AddNewCustomer /> : ""
        }
        {
          showAddWalkInCustomer || showAddAppointmentCustomer ? <AddCustomerForm /> : ""
        }
        {
          logout ? <Logout/> : ""
        }
    </>
  )
}
