import { createSlice } from "@reduxjs/toolkit";

export const chatAction = createSlice({
    name : 'chatAction',
    initialState : {
        uploadFilePopUp : false,
        templateMessagePopUp : false,
        chatContaierActive : false,
    },
    reducers : {
        toggleUploadFilePopUp : (state, action) => {
            state.uploadFilePopUp = action.payload;
        },
        toggleTemplateMessagePopUp: (state,action) => {
            state.templateMessagePopUp = action.payload;
        },
        toggleChatContaierActive: (state, action) => { 
            state.chatContaierActive = action.payload;
        }
    },
})

export const { toggleUploadFilePopUp , toggleTemplateMessagePopUp , toggleChatContaierActive } = chatAction.actions;
export default chatAction.reducer;