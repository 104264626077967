import React from "react";
import Homepage from "./homePage/HomePage";
import Loginpage from "./loginPage/LoginPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChatPage from "./chatPage/ChatPage";
import './Dave.css'

export default function Dave() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />}></Route>
          <Route exact path="/login" element={<Loginpage />}></Route>
          <Route path="/chat" element={<ChatPage/>}></Route>
        </Routes>
      </Router>
    </>
  );
}
