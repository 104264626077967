import React, { useEffect, useRef } from 'react';
import { useDispatch , useSelector } from "react-redux";
import { clearMessageCount , getPreviousMessage, getPreviousMessageByRoomId, updateMessageText } from '../../feature/MessageSlice';
import { updateReassignPopUp , updateReassignAction } from '../../feature/ReassignSlice';
import SentIcon from '../../images/Sent.svg';
import DeliveredIcon from '../../images/Delivered.svg';
import ReadIcon from '../../images/Read.svg';
import FailedIcon from '../../images/Failed.svg';
import './Message.css';
import moment from 'moment/moment';
import { dateFrom } from '../common/Common';

export default function Message() {
    const dispatch = useDispatch();
    const { chatMessage , automatedResponse , chatMessagesStatus , readReceits , tempMessage } = useSelector( state => state.message_reducer);
    const { activeTicketData } = useSelector( state => state.ticketStatus_reducer);
    const { user_id , HEADERS } = useSelector( state => state.login_reducer);
  useEffect(() => {
        scrollBottom();
        dispatch(updateReassignPopUp(false));
        dispatch(updateReassignAction(false));
    },[chatMessage[activeTicketData.room_id],activeTicketData]);

    useEffect(() => {
        scrollBottom();
    },[activeTicketData])
    useEffect(() => {
        console.log("automatedResponseMessage is changed");
    }, [automatedResponse])
    useEffect(() => {
    }, [readReceits])
    
    
    const scrollBottom = () => {
        const messageContainer = document.querySelector(".dave-active-chat-message-container");
        messageContainer.scrollTop = messageContainer.scrollHeight //- messageContainer.offsetHeight;
    }
    const handelInfiniteMessageScroll =(e) => {
        // console.log("scroll:",e.target.scrollHeight,e.target.scrollTop);
        const lastChildElement = document.querySelector(".dave-active-chat-message-container").firstElementChild;
        window.lastChildElement = lastChildElement;
        if(e.target.scrollTop === 0) {
            console.log("Reached Top");
            const messageContainer = document.querySelector(".dave-active-chat-message-container");
            const previousScrollHeight = messageContainer.scrollHeight;
            if(chatMessagesStatus.hasOwnProperty(activeTicketData.room_id) && !chatMessagesStatus[activeTicketData.room_id].is_last)
                dispatch(getPreviousMessageByRoomId({room_id : activeTicketData.room_id, page_number : chatMessagesStatus[activeTicketData.room_id]?.page_number + 1})).then(() => {
                    console.log("consoleLog: microtask queue");
                    messageContainer.scrollTop = messageContainer.scrollHeight - (previousScrollHeight + 50) //- messageContainer.offsetHeight;
                    // messageContainer.scrollIntoView()
                    // messageContainer.scrollTo(0,scrollHeight - messageContainer.scrollTop);
                    console.log("scroll---");
                })
        }
    }
    const formatDate = (dateAndTimeString) => {
        const splitString = dateAndTimeString.split(" ");
        const dateObj = moment(dateAndTimeString);
        return {date:dateObj.format(" DD MMM YYYY"),time:dateObj.format("hh:mm:ss A")}
    }

    function responseTemplate(chatData) {
        console.log(chatData);
        console.log(automatedResponse);
        if(chatData.message_id in automatedResponse) {
            // document.querySelector('.dave-user-message-typing').value = automatedResponse[chatData.message_id][0];
            const payload = {
                room_id : chatData.room_id,
                message : automatedResponse[chatData.message_id]
            }
            dispatch(updateMessageText(payload));
        }
    }

    const unsetMessageCount = () => {
        // dispatch(clearMessageCount(activeTicketData));
        console.log(chatMessage);
    };

    // const timePrint = (timestamp) => {
    //     const tStamp = new Date(timestamp).toLocaleString().split(' ')
    //     console.log("Timestamp: " + timestamp);
    //     console.log("Date string",new Date(timestamp));
    //     console.log("to local string",new Date(timestamp).toLocaleString());
    //     console.log("full time",tStamp);
    //     console.log("time",tStamp[1]);
    //     console.log("Display Time",tStamp[1] + " " + tStamp[2]);
    //     // console.log("time",)
    //     if(tStamp[1] && tStamp[2]) {
    //         console.log("if t1 & t2",tStamp[1],tStamp[2],);
    //         return tStamp[1] + " " + tStamp[2] + " " +tStamp[0];
    //     } else if(tStamp[1]) {
    //         console.log("if t1",tStamp[1] + " " +tStamp[0]);
    //         return tStamp[1];
    //     }

    // }

    // console.log('chatMessage======', chatMessage[activeTicketData.room_id])
    // console.log(chatMessage);
    // console.log("Automated Responses:",automatedResponse)
    return (
        <div className="dave-active-chat-message-container" onScroll={handelInfiniteMessageScroll} onClick={unsetMessageCount}>
            {
                activeTicketData.room_id in chatMessage ? chatMessage[activeTicketData.room_id].map(chat => chat.room_id === activeTicketData.room_id && chat.type === "data" && chat.sender_id !== user_id ? <div className="dave-received-message-container">
                    {
                        chat.sender_id === "initial_template" && activeTicketData.agent_id !== user_id && <span className="dave-sender-details">{activeTicketData.agent_id}</span>
                    }
                    {
                        chat.sender_id !== "initial_template" && <span className="dave-sender-details">{chat.sender_id}</span>
                    }
                    {/* {console.log("automated response id:",chat.message_id in automatedResponse)} */}
                    {chat.data.message && <span className="dave-received-message-text" onClick={()=>{responseTemplate(chat)}} style={chat.message_id in automatedResponse ? {"background":"#008069","color":"#fff"}:{"background":"#F3F5FC"}}>{chat.data.message}</span>}
                    {chat.data.type === 'image' && <a href={chat.data.media_url} target='_blank' className="dave-received-image-contaier" download><img src={chat.data.media_url} alt="" className="dave-received-image" /></a>}
                    {chat.data.type === 'audio' && <audio src={chat.data.media_url} className="dave-received-audio" controls></audio>}
                    {chat.data.type === 'video' &&  <video src={chat.data.media_url} className="dave-received-video" controls></video>} 
                    {chat.data.type === 'document' && <a href={chat.data.media_url} target='_blank' title={chat.data.filename} className="dave-doc-message-container" download><span className="doc-file-extension">{chat.data.filename.split(".")[chat.data.filename.split(".").length - 1].slice(0,3)}</span><span className="doc-filename">{chat.data.filename}</span></a>}
                    <span className="dave-received-time">{chat.sent_timestamp && typeof chat.sent_timestamp === "number" ? moment(chat.sent_timestamp * 1000).format("DD MMM YYYY") + ", " + (typeof chat.sent_timestamp === "number" && moment(chat.sent_timestamp * 1000).format("hh:mm:ss A")) : chat.formated_timestamp}</span>
                </div> : chat.type === "data" && chat.room_id === activeTicketData.room_id ? <div className="dave-sent-message-container">
                    {chat.data.message ? <span className="dave-sent-message-text">{chat.data.message}</span> : chat.data.type === 'image' ? <a href={chat.data.media_url} target='_blank' className="dave-sent-image-contaier" download><img src={chat.data.media_url} alt="" className="dave-sent-image" /></a> : chat.data.type === 'audio' ? <audio src={chat.data.media_url} className="dave-sent-audio" controls></audio> : chat.data.type === 'video' ?  <video src={chat.data.media_url} className="dave-sent-video" controls></video> : chat.data.type === 'document' ? <a href={chat.data.media_url} target='_blank' title={chat.data.filename} className="dave-doc-message-container sent" download><span className="doc-file-extension">{chat.data.filename.split(".")[chat.data.filename.split(".").length - 1].slice(0,3)}</span><span className="doc-filename">{chat.data.filename}</span></a> : ""}
                    {chat.message_id in readReceits && <div className="dave-read-receit-container"><img className='dave-read-receit-img' src={readReceits[chat.message_id] === "read" ? ReadIcon : readReceits[chat.message_id] === "delivered" ? DeliveredIcon : readReceits[chat.message_id] === "sent" ? SentIcon : readReceits[chat.message_id] === "failed" && FailedIcon} alt='' /></div>}
                    <span className="dave-sent-time">{chat.sent_timestamp && typeof chat.sent_timestamp === "number" ? moment(chat.sent_timestamp * 1000).format("DD MMM YYYY") + ", " + (typeof chat.sent_timestamp === "number" && moment(chat.sent_timestamp * 1000).format("hh:mm:ss A")) : chat.formated_timestamp}</span>
                </div> : "") : ""
            }
            {/* {
                console.log("activeTicketData.room_id",activeTicketData.room_id)
            }{
                console.log("activeTicketData.room_id in tempMessage",activeTicketData.room_id in tempMessage)
            }{
                console.log("tempMessage",tempMessage)
            } */}
            {
                activeTicketData.room_id in tempMessage && tempMessage[activeTicketData.room_id].map(chat => <div className="dave-sent-message-container">
                    {
                        chat.data.message && <span className="dave-sent-message-text">{chat.data.message}</span>
                    }
                    {
                        chat.data.type === 'image' && <a href={chat.data.media_url} target='_blank' className="dave-sent-image-contaier" download><img src={chat.data.media_url} alt="" className="dave-sent-image" /></a>
                    }
                    {
                        chat.data.type === 'audio' && <audio src={chat.data.media_url} className="dave-sent-audio" controls></audio>
                    }
                    {
                        chat.data.type === 'video' &&  <video src={chat.data.media_url} className="dave-sent-video" controls></video>
                    }
                    {
                        chat.data.type === 'document' && <a href={chat.data.media_url} target='_blank' title={chat.data.filename} className="dave-doc-message-container sent" download><span className="doc-file-extension">{chat.data.filename.split(".")[chat.data.filename.split(".").length - 1].slice(0,3)}</span><span className="doc-filename">{chat.data.filename}</span></a>
                    }
                </div>)
            }
        </div>
    )
}
