import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as API_Calls from './API_Call';
import * as API from "./API_Path";

export const emailAPI = createAsyncThunk("emailAPI", async (para,{rejectWithValue}) => {
    const { HEADERS , sTicketData , emailSentTo } = para;
    const { service_advisor_name , service_advisor_id , ccm_name , customer_name , vehicle_reg_number , ccm_email , service_advisor_email } = sTicketData;
    const body = emailSentTo === "advisor" ?{
        // "recipient": `${service_advisor_email ? service_advisor_email : ["sumit@iamdave.ai" , "rahul.pandey@iamdave.ai"]}`,
        "recipient": `${service_advisor_email ? service_advisor_email : ["aviral.rawat@maruti.co.in"]}`,
        "subject": "Greetings from Multiparty Service Chat",
        "body": 
        `<html>
            <body>
                <p>Dear ${service_advisor_name},</p></br>
                <p>${customer_name} with vehicle number ${vehicle_reg_number} is waiting  for a response for the past 10 minutes. Please provide timely assistance to ensure a positive experience. Thank you! " </p>
                <p>no reply</p>
            </body>
        </html>`,
        "sender": {
            "email": "donotreply@iamdave.ai",
            "name": "Service Center Admin"
            // "email": "multipartyservicechat@testmail.com",
            // "name": "Multiparty Service App"
        },
    } : {
        // "recipient": `${ccm_email ? ccm_email :["sumit@iamdave.ai" , "rahul.pandey@iamdave.ai"]}`,
        "recipient": `${ccm_email ? ccm_email :["aviral.rawat@maruti.co.in"]}`,
        "subject": "Greetings from Multiparty Service Chat",
        "body": 
        `<html>
            <body>
                <p>Dear ${ccm_name},</p></br>
                <p>${customer_name} with vehicle number ${vehicle_reg_number} is waiting  for a response from ${service_advisor_name}-${service_advisor_id} for the past 20 minutes. Please provide timely assistance to ensure a positive experience. Thank you! " </p>
                <p>no reply</p>
            </body>
        </html>`,
        "sender": {
            "email": "donotreply@iamdave.ai",
            "name": "Service Center Admin"
            // "email": "multipartyservicechat@testmail.com",
            // "name": "Multiparty Service App"
        },
    };
    // try {
    //     const response = await axios.post(API.EMAIL_PATH,body,{headers : HEADERS});
    //     const result = response.data;
    //     console.log(response);
    //     console.log(result);
        
    // } catch (error) {
        
    // }
    try {
        const response = await API_Calls.postMethod(API.EMAIL_PATH,body);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const wmEmailAPI = createAsyncThunk("wmEmailAPI", async (para,{rejectWithValue}) => {
    const { sTicketData } = para;
    const { service_advisor_name , service_advisor_id , wm_name , customer_name , vehicle_reg_number , wm_email } = sTicketData;
    const body = {
        // "recipient": `${wm_email ? wm_email :["sumit@iamdave.ai" , "rahul.pandey@iamdave.ai"]}`,
        "recipient": `${wm_email ? wm_email :["aviral.rawat@maruti.co.in"]}`,
        "subject": "Greetings from Multiparty Service Chat",
        "body": 
        `<html>
            <body>
                <p>Dear ${wm_name},</p></br>
                <p>${customer_name} with vehicle number ${vehicle_reg_number} is waiting  for a response from ${service_advisor_name}-${service_advisor_id} for the past 20 minutes. Please provide timely assistance to ensure a positive experience. Thank you! " </p>
                <p>no reply</p>
            </body>
        </html>`,
        "sender": {
            "email": "donotreply@iamdave.ai",
            "name": "Service Center Admin"
        },
    };
    try {
        const response = await API_Calls.postMethod(API.EMAIL_PATH,body);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const email = createSlice({
    name : "email",
    initialState : {
        emailData : [],
        emailloading : null,
        emailError : "",
        wmEmailData : [],
        wmEmailloading : null,
        wmEmailError : "",
        advisorEmailCounterList : {},
        managerEmailCounterList : {},
    },
    reducers :{
        addToAdvisorEmailCounterList : (state, action) => {
            if(action.payload.room_id in state.advisorEmailCounterList) {
                
            }
            else {
                Object.assign(state.advisorEmailCounterList, {
                    [action.payload.data.room_id]: action.payload.sent_timestamp,
                });
            }
        },
        deleteFromAdvisorEmailCounterList : (state, action) => {
            if(action.payload in state.advisorEmailCounterList) {
                console.log(state.advisorEmailCounterList[action.payload]);
                delete state.advisorEmailCounterList[action.payload];
            }
        },
        addToManagerEmailCounterList : (state, action) => {
            if(action.payload.room_id in state.managerEmailCounterList) {
                
            }
            else {
                Object.assign(state.managerEmailCounterList, {
                    [action.payload.data.room_id]: action.payload.sent_timestamp,
                });
            }
        },
        deleteFromManagerEmailCounterList : (state, action) => {
            if(action.payload in state.managerEmailCounterList) {
                console.log(state.managerEmailCounterList[action.payload]);
                delete state.managerEmailCounterList[action.payload];
            }
        },
    },
    extraReducers : {
        [emailAPI.pending] : (state) => {
            state.emailloading = true;
        },
        [emailAPI.fulfilled] : (state, action) => {
            state.emailloading = false;
            state.emailData = action.payload;
        },
        [emailAPI.rejected] : (state, action) => {
            state.emailloading = false;
            state.emailError = action.payload.error;
        },
        [wmEmailAPI.pending] : (state) => {
            state.wmEmailloading = true;
        },
        [wmEmailAPI.fulfilled] : (state, action) => {
            state.wmEmailloading = false;
            state.wmEmailData = action.payload;
        },
        [wmEmailAPI.rejected] : (state, action) => {
            state.wmEmailloading = false;
            state.wmEmailError = action.payload.error;
        }
    }
});

export const { addToAdvisorEmailCounterList, deleteFromAdvisorEmailCounterList, addToManagerEmailCounterList, deleteFromManagerEmailCounterList } = email.actions;
export default email.reducer;