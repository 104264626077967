import React from 'react';
import { useSelector } from 'react-redux';
import Status from './Status'
import './ChatStatus.css';
export default function ChatStatus() {
  const { ticketCountData , ticketCountLoading } = useSelector(state => state.ticketStatus_reducer);
  const { activeState } = useSelector( state => state.ticketStatus_reducer);
  return (
    <div className="dave-chat-status-container col col-xs-12">
      {/* <Status title="Active" style={activeState === "active" ? {"background":"#fff","color":"#2D3494"} : {"background":"rgba(100, 110, 229, 1)","color":"#fff"}} ticketNo={ticketCountLoading ? <img className='dave-loader-gif' src={require("../../images/Maintenance.gif")} alt="Add User" /> : ticketCountData.active}/>
      <Status title="Closed" style={activeState === "closed" ? {"background":"#fff","color":"#2D3494"} : {"background":"rgba(100, 110, 229, 1)","color":"#fff"}}  ticketNo={ticketCountLoading ? <img className='dave-loader-gif' src={require("../../images/Maintenance.gif")} alt="Add User" /> : ticketCountData.closed}/> */}
      <Status title="Active" style={activeState === "active" ? {"background":"#fff","color":"#000","box-shadow": "0px 10px 20px 0px #00000040"} : {"background" : "#008069", "color" : "#fff", "border" : "2px solid #FFF"}} ticketNo={ticketCountData.active===null?0:ticketCountData.active}/>
      <Status title="Closed" style={activeState === "closed" ? {"background":"#fff","color":"#000","box-shadow": "0px 10px 20px 0px #00000040"} : {"background":"#008069","color":"#fff","border": "2px solid #FFF"}}  ticketNo={ticketCountData.closed===null?0:ticketCountData.closed}/>
      {/* <Status title="Pending" style={activeState === "pending" ? {"background":"#fff","color":"#2D3494"} : {"background":"rgba(100, 110, 229, 1)","color":"#fff"}}  ticketNo={ticketCountLoading ? <img className='dave-loader-gif' src={require("../../images/Maintenance.gif")} alt="Add User" /> : ticketCountData.pending}/> */}
    </div>
  )
}
