import { createSlice } from "@reduxjs/toolkit";

export const sortJob = createSlice({
    name : 'sortJob',
    initialState : {
        sortByUpdated : true,
        sortPopUp : false
    },
    reducers : {
        toggleSort: (state,action) => {
            state.sortByUpdated = action.payload;
        },
        toggleSortPopUp: (state,action) => {
            state.sortPopUp = action.payload;
        }
    }
})

export const { toggleSort , toggleSortPopUp } = sortJob.actions;
export default sortJob.reducer;