import React,{useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Tickets.css';
import TicketSearch from './TicketSearch';
import Ticket from './Ticket'
import ReassignedTicket from './ReassignedTicket';
import Loader from '../loader/Loader';
import { toggleTicketLoading } from '../../feature/AddCustomerOptionSlice';
import { activeChatStatusApi, closedChatStatusApi, updateTicketsListApi } from '../../feature/ChatStatusSlice';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Tickets() {
  const dispatch = useDispatch();
  const { HEADERS } = useSelector( state => state.login_reducer);
    const { sortByUpdated } = useSelector( state => state.sorting_reducer);
    const { kWS } = useSelector( state => state.searchJob_reducer);
    const { userInfoServiceCenterId , userInfoRole } = useSelector( state => state.user_reducer);
  const { activeState , activeData , pendingData , closedData, activeLoading, closedLoading, ticketCountData, activePageNumber, closedPageNumber, activeIsLast, closedIsLast} = useSelector(state => state.ticketStatus_reducer);
  // const { newStatusData } = useSelector( state => state.message_reducer);
  const { addCustomerLoading, addCustomerData, ticketDataLoading } = useSelector( state => state.addCustomer_reducer );
  useEffect(()=>{
    dispatch(toggleTicketLoading(false))
  },[activeData])
  // useEffect(() => {
  //   console.log("New status changes");
  //   console.log(newStatusData);
  // }, [newStatusData]);
  const observerRef = useRef();

  const updateTicketList = (ticketType) => {
    const userInfoParam = {
      HEADERS : HEADERS, 
      sortByUpdated : sortByUpdated,
      kws : kWS,
      serviceCenterId : userInfoServiceCenterId,
      pageNumber : (ticketType === "active" ? activePageNumber : closedPageNumber) + 1,
      status : ticketType
    }
    const isLastPage = ticketType === "active" ? activeIsLast : closedIsLast ;
    console.log(userInfoParam)
    if(!isLastPage)
      dispatch(updateTicketsListApi(userInfoParam));
  }

  useEffect(()=>{
    observerRef.current.scrollTo(0,0);
  },[activeState])
  
  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries,observer) => {
  //     console.log("observed")
  //     if(entries[0].intersectionRatio === 1 && !isLastPage){
  //       console.log(entries[0])
  //       console.log(observerRef.current.children[observerRef.current.children.length - 1])
  //       // updateTicketList(activeState);    
  //       observer.unobserve(observerRef.current.children[observerRef.current.children.length - 1])
  //       observer.disconnect();
  //     }
  //   },{
  //     rootMargin : "0px",
  //     root : document.querySelector(".dave-tickets-container.col.col-5"),
  //     threshold : 1.0
  // }) 
  //   observer.observe(observerRef.current.children[observerRef.current.children.length - 1])
  //   return () => {
  //     observer.unobserve(observerRef.current.children[observerRef.current.children.length - 1])
  //     observer.disconnect()
  //   }
  // },[activeData,closedData])

  return (
    <div className="dave-tickets-container col col-5">
      <TicketSearch/>

      <div ref={observerRef} id="ticketScroll" className="dave-tickets-wrapper col">
        <InfiniteScroll scrollableTarget={"ticketScroll"} hasMore={true} dataLength={activeState === "active" ? activeData?.length : closedData?.length} next={()=>{updateTicketList(activeState)}}>
        {
          activeState === "active" && activeData?.length > 0 ? activeData.map(ticket => 
            <Ticket activeTicketData={ticket}/>) : activeState === "closed" && closedData?.length > 0 ? closedData.map(ticket => 
              <Ticket activeTicketData={ticket}/>
              ) : <div className="dave-no-ticket-container">
            <span className="dave-no-tickets">No Job Ticket Available </span>
          </div>
        }
        {
          activeState === "active" && activeData?.length > 0 ? activeData.map(ticket => 
            <ReassignedTicket activeTicketData={ticket}/>).reverse() : activeState === "closed" && closedData?.length > 0 ? closedData.map(ticket => 
              <ReassignedTicket activeTicketData={ticket}/>
              ).reverse() : ""
        }
        </InfiniteScroll>
      </div>
      {(ticketCountData.active === null || ticketCountData.closed === null || ticketDataLoading) ? <Loader style={{ position : "fixed", height : "100dvh", width : "100dvw" }}/> : ""}
    </div>
  )
}
