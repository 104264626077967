import React, { useState } from 'react';
import { useSelector , useDispatch } from "react-redux";
import { fileUpload , setFileType , setSelectedFile, deletefileData, setFileName } from '../../feature/FileUploadSlice';
import { toggleUploadFilePopUp } from '../../feature/ChatActionSlice';
import closeIconSVG from "../../images/close-icon.svg"
import './ChatFileUpload.css';

export default function ChatFileUpload() {
  const dispatch = useDispatch();
  const { api_key, enterprise_id ,user_id } = useSelector( state => state.login_reducer);
  const [isFileSeleted,setIsFileSelected] = useState(false);
  const [fileName,setLocalFileName] = useState("");
  const [fileSizeError,setFileSizeError] = useState(false);

  const uploadFile = (e) => {
    // dispatch(toggleUploadLoading(true));
    if(!e.target.files[0]) return;
    console.log(e);
    console.log("file info:",e.target.files[0]);
    console.log(e.target.files[0].type.split('/')[0]);
    if(e.target.files[0].size/(1024*1024) > 16){
      setFileSizeError(true);
      return;
    }
    if (e.target.files[0].type.split('/')[0] === 'application') {
      console.log("type of file is application");
      dispatch(setFileType("document"));
      dispatch(setFileName(e.target.files[0].name));
    } else {
      console.log("type of file is " +e.target.files[0].type.split('/')[0])
      dispatch(setFileType(e.target.files[0].type.split('/')[0]));
    }
    setLocalFileName(e.target.files[0].name);
    setIsFileSelected(true);
    // dispatch(toggleUploadFilePopUp(false));
    // dispatch(setFileType(e.target.files[0].type.split('/')[0]));
    console.log('e====', e)
    dispatch(setSelectedFile(e.target.files[0]))
    // dispatch(fileUpload({api_key, enterprise_id ,user_id, file : e.target.files[0]})).then(() => { 
    //   // dispatch(deletefileData());
    // });

  }
  const closeFileHandler = () => {
    dispatch(deletefileData());
    setIsFileSelected(false);
  }
  // useEffect(() => {
  // }, [fileType,fileUploadData])
  
  return (
    <>
      <div className="dave-upload-container col">
          { isFileSeleted ? <div className="select-file-preview-container"> <span className="select-file-filename" title={fileName}>{fileName}</span> <span onClick={closeFileHandler} className="close-file-btn"> <img src={closeIconSVG} /> </span> </div> : <input className="dave-upload-file form-control" type="file" id="formFile" accept='.png,.pdf,.mp3,.mp4,.wav,.ppt,.doc,.jpg,.jpeg' onChange={uploadFile}></input>}
      </div>
      {fileSizeError ? <div className="file_error_modal_container">
        <div className="file_error_modal">
          <span className="file_error_msg">File size should not be larger than 16MB!</span>
          <div className="file_error_btn-container">
            <button onClick={() => {
              setFileSizeError(false);
              document.getElementById("formFile").value = "";
              }}>OK</button>
          </div>
        </div>
      </div> : ""}
    </>
  )
}
