import React, { useEffect } from 'react';
import * as API from '../../feature/API_Path';
import { DAVE_SPACE } from '../../feature/daveSocket';
import { useSelector , useDispatch } from "react-redux";
import { activeChatStatusApi , closedChatStatusApi , activeTicketInfo , updateLastServiceSpaceData } from '../../feature/ChatStatusSlice';
import { getNotificationAPI } from '../../feature/NotificationSlice';
import { userInfo } from '../../feature/AgentSlice';
import { addToAdvisorEmailCounterList , addToManagerEmailCounterList } from "../../feature/SendEmailSlice";
import { updateReadReceits , updateChatMessage , incrementMessageCount , getPreviousMessage , newStatus , updateMessageCount , updateAutomatedResponse, getPreviousMessageByRoomId , clearMessageCount , deleteFromTemMessages } from '../../feature/MessageSlice';
import './CustomerDetails.css';
import Notifications from '../notification/Notifications';
import Chatstatus from '../chatStatus/ChatStatus';
import Tickets from '../tickets/Tickets';
import Activechat from '../activeChat/ActiveChat';
import Loader from '../loader/Loader';
import { toggleTicketLoading } from '../../feature/AddCustomerOptionSlice';

let centerRoomConnection;
export default function CustomerDetails() {
    const dispatch = useDispatch();
    const { api_key , enterprise_id , user_id , loading , HEADERS } = useSelector( state => state.login_reducer);
    const { sortByUpdated } = useSelector( state => state.sorting_reducer);
    const { notificationActive } = useSelector( state => state.notification_reducer);
    const { kWS } = useSelector( state => state.searchJob_reducer);
    const { userInfoData , userInfoServiceCenterId , userInfoRole } = useSelector( state => state.user_reducer);
    const { activeState , activeData , activeTicketData , lastServiceSpaceData } = useSelector( state => state.ticketStatus_reducer);
    const { chatMessagesStatus , updateMessageCountData } = useSelector((state) => state.message_reducer);
    const successCallback = (res) => {
        console.log(centerRoomConnection);
        console.log(res);
        centerRoomConnection.registerCallback(dataHandler, "data-callback", "data");
    }
    const errorCallback = (err) => {
        console.log(err);
    }
    let unreadCount = 0;
    // const dataHandler = (data) => {
    //     console.log(centerRoomConnection);
    //     console.log("P2P - Data Handler - data --> "+JSON.stringify(data));
    //     console.log(data);
    //     if(data.data.send_via === "whatsapp") {
    //         data.data.message_id = data.message_id;
    //     }
    //     console.log("send via",data.data.sent_via);
    //     dispatch(updateLastServiceSpaceData(data));
    //     if(data.data.room_id && !data.data.search_term) {
    //         if(data.data.sent_via === "socket" || data.data.send_via === "whatsapp"){
    //             dispatch(updateChatMessage(data.data));
    //         }
    //         const userInfoParam = {
    //             HEADERS : HEADERS,
    //             sortByUpdated : sortByUpdated,
    //             kws : kWS,
    //             serviceCenterId : userInfoServiceCenterId
    //         }
    //         console.log("User Id--->>>"+user_id);
    //         console.log("Sender Id->>>"+data.data.sender_id);
    //         console.log(data.data);
    //         if(data.data.sender_id !== user_id && data.data.sender_id) {
    //             console.log(data.data.sender_id);
    //             if(!data?.data?.data?.suggestion && data.data.send_via === "whatsapp")
    //                 dispatch(incrementMessageCount(data.data))
    //         }
    //         if(data.data.send_via === "whatsapp" && userInfoRole === "service advisor") {
    //             const curTime = new Date();
    //             if(curTime.getHours() < 18 && curTime.getHours() >= 8) {
    //                 dispatch(addToAdvisorEmailCounterList(data.data));
    //                 dispatch(addToManagerEmailCounterList(data.data));
    //             }
    //             console.log("Current Time in hrs",curTime.getHours())
    //         }
    //         const updateUnreadMessageCount = {
    //             HEADERS : HEADERS,
    //             booking_id : data.data.room_id.replaceAll('_job_space',''),
    //             count : unreadCount
    //         }
    //         data.data.send_via === "whatsapp" && dispatch(updateMessageCount(updateUnreadMessageCount));
    //         data.data.sent_via === "socket" && dispatch(clearMessageCount(data.data.room_id));
    //         if(data.data.sent_via === "socket") {
                
    //             dispatch(clearMessageCount(data.data.room_id));
    //         }
    //         console.log("automatedData:",data.data.data);
    //         if(data?.data?.data?.suggestion){
    //             dispatch(updateAutomatedResponse(data.data));
    //         }
    //         setTimeout(() => {
    //             dispatch(newStatus(userInfoParam));
    //         }, 2000);
    //     } else if(data.data.data.suggestion) {
    //         console.log(data);
    //         dispatch(updateAutomatedResponse(data.data.data));
    //     } else if(data.data.search_term) {
    //         const userInfoParam = {
    //             HEADERS : HEADERS,
    //             sortByUpdated : sortByUpdated,
    //             kws : kWS,
    //             serviceCenterId : userInfoServiceCenterId
    //         }
    //         if(activeState === 'active' && data.data.room_id) {
    //             dispatch(activeChatStatusApi(userInfoParam));
    //         } else if(activeState === 'closed') {
    //             dispatch(closedChatStatusApi(userInfoParam));
    //         }
    //         console.log(activeTicketData);
    //         console.log(activeTicketData.booking_id);
    //         console.log(data.data.booking_id);
    //         console.log("Ticket Reassigned")
    //     }
    // }

    const dataHandler = (data) => {
        // console.log(centerRoomConnection);
        // console.log("P2P - Data Handler - data --> "+JSON.stringify(data));
        console.log(data);
        const userInfoParam = {
            HEADERS : HEADERS,
            sortByUpdated : sortByUpdated,
            kws : kWS,
            serviceCenterId : userInfoServiceCenterId
        }
        data.type === "data" && dispatch(updateLastServiceSpaceData(data));
        if(data.type === "data" && !data?.data.headers && !data.data.suggestion) {
            const mesData = JSON.parse(JSON.stringify(data))
            mesData.booking_id = mesData.data.booking_id;
            mesData.message_id = mesData.data.message_id;
            mesData.room_id = mesData.data.room_id;
            // setTimeout(() => {
            //     dispatch(newStatus(userInfoParam));
            // }, 3000);
            dispatch(updateChatMessage(mesData));
            dispatch(deleteFromTemMessages(mesData));
            if(mesData.send_via === "whatsapp") {
                const updateUnreadMessageCount = {
                    HEADERS : HEADERS,
                    booking_id : mesData.data.room_id.replaceAll('_job_space',''),
                    count : unreadCount
                }
                dispatch(updateMessageCount(updateUnreadMessageCount));
                dispatch(incrementMessageCount(mesData.data))
                if(userInfoRole === "service advisor") {
                    const curTime = new Date();
                    if(curTime.getHours() < 18 && curTime.getHours() >= 8) {
                        dispatch(addToAdvisorEmailCounterList(mesData));
                        // dispatch(addToAdvisorEmailCounterList(mesData.data));
                        // dispatch(addToManagerEmailCounterList(mesData.data));
                        dispatch(addToManagerEmailCounterList(mesData));
                    }
                    console.log("Current Time in hrs",curTime.getHours())
                }
            } else if(!mesData.send_via) {
                setTimeout(() => {
                    dispatch(newStatus(userInfoParam));
                }, 3000);
                dispatch(clearMessageCount(mesData.data.room_id))
            }
        } else if(data.type === "data" && data.data.suggestion) {
            dispatch(updateAutomatedResponse(data))
        // } else if(data?.data?.headers) {
        //     // const userInfoParam = {
        //     //     HEADERS : HEADERS,
        //     //     sortByUpdated : sortByUpdated,
        //     //     kws : kWS,
        //     //     serviceCenterId : userInfoServiceCenterId
        //     // }
        //     // if(activeState === 'active' && data.data.room_id) {
        //     //     dispatch(activeChatStatusApi(userInfoParam));
        //     // } else if(activeState === 'closed') {
        //     //     dispatch(closedChatStatusApi(userInfoParam));
        //     // }
        //     console.log(activeTicketData);
        //     console.log(activeTicketData.booking_id);
        //     console.log(data.data.booking_id);
        //     console.log("Ticket Reassigned")
        } else if(data.type === "command") {
            dispatch(updateReadReceits({"command" : data.command,"message_id": data.message_id.split("_")[0]}))
        }
    }

    let centerRoomConnected = false;
    useEffect(() => {
        console.log("lastServiceSpaceData updated");
        console.log(lastServiceSpaceData);
        // console.log(activeTicketData.booking_id);
        // console.log(lastServiceSpaceData.data.booking_id);
        const userInfoParam = {
            HEADERS : HEADERS,
            sortByUpdated : sortByUpdated,
            kws : kWS,
            serviceCenterId : userInfoServiceCenterId
        }
        if(activeState === 'active' && lastServiceSpaceData?.data && lastServiceSpaceData.data.room_id) {
            dispatch(activeChatStatusApi(userInfoParam));
        }
        if(lastServiceSpaceData?.data?.headers && activeTicketData && activeTicketData.booking_id === lastServiceSpaceData.data.booking_id) {
            console.log(activeTicketData.booking_id);
            console.log(lastServiceSpaceData.data.booking_id);
            dispatch(activeTicketInfo(lastServiceSpaceData.data));
        }
    }, [lastServiceSpaceData])

    useEffect(() => {
        const userInfoParam = {
            HEADERS : HEADERS,
            sortByUpdated : sortByUpdated,
            kws : kWS,
            serviceCenterId : userInfoServiceCenterId
        }
        activeState === 'active' && dispatch(activeChatStatusApi(userInfoParam));
    }, [updateMessageCountData])
    
    
    useEffect(() => {
        console.log(enterprise_id);
        console.log(user_id);
        console.log(api_key);
        console.log(userInfoData.room_id);
        console.log(API.SOCKET_URL);
        if(userInfoData.room_id) {
            centerRoomConnection  = new DAVE_SPACE(enterprise_id, user_id, api_key, user_id, "service_center_space", userInfoData.room_id, API.SOCKET_URL , successCallback, errorCallback);
            centerRoomConnection.response_url ? console.log("Connected") : centerRoomConnected = !centerRoomConnected;
            console.log(centerRoomConnection.response_url);
        }
        console.log("centerRoomConnection",centerRoomConnection);
    }, [userInfoData.room_id,centerRoomConnected]);
    useEffect(() => {
        if(!loading && loading != null && enterprise_id && api_key && user_id) {
            window.HEADERS = HEADERS;
            dispatch(userInfo({HEADERS : HEADERS,}));
            dispatch(newStatus({HEADERS : HEADERS,}));
            // dispatch(getPreviousMessage({HEADERS : HEADERS,}));
        }
    }, [loading]);

    useEffect(() => {
        if(activeTicketData.room_id && !chatMessagesStatus.hasOwnProperty(`${activeTicketData.room_id}`))
            dispatch(getPreviousMessageByRoomId({room_id : activeTicketData.room_id, page_number : chatMessagesStatus[activeTicketData.room_id]?.page_number})).then(() => {
                const messageContainer = document.querySelector(".dave-active-chat-message-container");
                messageContainer.scrollTop = messageContainer?.scrollHeight;
        });
    },[activeTicketData]);
    useEffect(() => {
        if(!loading && loading != null && enterprise_id && api_key && user_id && userInfoServiceCenterId) {
            const userInfoParam = {
                HEADERS : HEADERS,
                sortByUpdated : sortByUpdated,
                kws : kWS,
                serviceCenterId : userInfoServiceCenterId
            }
            console.log(userInfoServiceCenterId);
            dispatch(activeChatStatusApi(userInfoParam));
            dispatch(closedChatStatusApi(userInfoParam));
            dispatch(getNotificationAPI(userInfoParam)); 
            console.log(enterprise_id);
            console.log(api_key);
            console.log(user_id);
        }
    }, [userInfoServiceCenterId]);
    useEffect(()=>{
        if(!loading && loading != null && enterprise_id && api_key && user_id && userInfoServiceCenterId) {
            const userInfoParam = {
                HEADERS : HEADERS,
                sortByUpdated : sortByUpdated,
                kws : kWS,
                serviceCenterId : userInfoServiceCenterId
            }
            let debounce;
            if(activeState === 'active') {
                debounce = setTimeout(() => {
                console.log("search API call");
                dispatch(toggleTicketLoading(true))
                dispatch(activeChatStatusApi(userInfoParam)).then( () => dispatch(toggleTicketLoading(false)) );
              }, 1000);
              
            } else if(activeState === 'closed') {
                debounce = setTimeout(() => {
                console.log("search API call");
                dispatch(toggleTicketLoading(true))
                dispatch(closedChatStatusApi(userInfoParam)).then( () => dispatch(toggleTicketLoading(false)) );
              }, 1000);
            }
            return () => {
                console.log(`cleared ${debounce}`);
                clearTimeout(debounce);
              }
        }
    },[kWS])
    useEffect(()=>{
        if(!loading && loading != null && enterprise_id && api_key && user_id && userInfoServiceCenterId) {
            const userInfoParam = {
                HEADERS : HEADERS,
                sortByUpdated : sortByUpdated,
                kws : kWS,
                serviceCenterId : userInfoServiceCenterId
            }
            dispatch(toggleTicketLoading(true));
            if(activeState === 'active') 
                dispatch(activeChatStatusApi(userInfoParam)).then( () => dispatch(toggleTicketLoading(false)) );
            else if(activeState === 'closed') {
                dispatch(closedChatStatusApi(userInfoParam)).then( () => dispatch(toggleTicketLoading(false)) );
            }
        }
    },[sortByUpdated]);
    
    return (
        <div className="dave-customer-details-container col col-12">
            <Chatstatus/>
            <Tickets/>
            {
                notificationActive ? <Notifications /> : <Activechat/>
            }
        </div>
    )
}
