import React , { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { initAPI , getServiceType , addCustomer ,addWalkInCustomer , addAppointmentCustomer } from '../../feature/AddCustomerOptionSlice';
import { getSMREData , getServiceAdvisiorData , getWM , getCCM } from '../../feature/ReassignSlice';
import './AddCustomer.css';
import addCustomerIconSVG from "../../images/addCustomer-icon-h.svg"
import h_addCustomerIconSVG from "../../images/addCustomer-icon-h.svg"

export default function AddCustomer() {
    const dispatch = useDispatch();
    const { HEADERS} = useSelector( state => state.login_reducer);
    const { userInfoServiceCenterId } = useSelector( state => state.user_reducer);
    const { showAddCustomer , userEngagementId } = useSelector( state => state.addCustomer_reducer);
    const addCustomerOption = async (e) => {
        dispatch(addCustomer(!showAddCustomer));
        if(!showAddCustomer) {
            dispatch(initAPI({ HEADERS : HEADERS }));
            dispatch(addAppointmentCustomer(false));
            dispatch(addWalkInCustomer(false));
            const userInfoParam = {
                HEADERS : HEADERS,
                service_center_id : userInfoServiceCenterId
            }
            dispatch(getSMREData(userInfoParam));
            dispatch(getServiceAdvisiorData(userInfoParam));
            dispatch(getWM(userInfoParam));
            dispatch(getCCM(userInfoParam));
            const serviceTypePara = {
                HEADERS : HEADERS,
                engagement_id : userEngagementId
            }
            dispatch(getServiceType(serviceTypePara));
        }
    }

    useEffect(() => {
    }, [showAddCustomer])

    return (
        <>
        <button className="dave-add-customer-btn btn btn-lg" onClick={addCustomerOption} style={{"background":"#008069","color":"#fff"}}>
            <span className='dave-add-customer-txt'>Add Customer</span>
            <img className='dave-add-customer-img' src={addCustomerIconSVG} alt="Add User" />
        </button>
        <button className="dave-add-customer-btn i-pad btn btn-lg" onClick={addCustomerOption}>
            <span className='dave-add-customer-txt'>Add Customer</span>
            <img className='dave-add-customer-img' src={h_addCustomerIconSVG} alt="Add User" />
        </button>
        </>
    )
}
