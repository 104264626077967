import { createSlice } from "@reduxjs/toolkit";

export const setPagination = createSlice({
    name : 'setPagination',
    initialState : {
        jobPageNo : 1,
        jobLastPage : "",
        messagePageNo : 1,
        messagLastPage : ""
    },
    reducers : {
        updateJobPageNo: (state,action) => {
            state.jobPageNo = action.payload;
        },
        updateMessagePageNo: (state,action) => {
            state.messagePageNo = action.payload;
        }
    }
})

export const { toggleSort , toggleSortPopUp } = setPagination.actions;
export default setPagination.reducer;