import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSort , toggleSortPopUp } from '../../feature/SortSlice';
import { activeChatStatusApi , closedChatStatusApi } from '../../feature/ChatStatusSlice';
import { updateKWS } from '../../feature/SearchSlice';
import './TicketSearch.css';
import filterIconSVG from "../../images/filter-icon.svg"
import searchIconSVG from "../../images/search-icon.svg"
import Loader from '../loader/Loader';

export default function TicketSearch() {
  const dispatch = useDispatch();
  const { api_key , enterprise_id , user_id , HEADERS } = useSelector( state => state.login_reducer);
  const { sortByUpdated , sortPopUp } = useSelector( state => state.sorting_reducer);
  const { activeState, activeLoading, closedLoading } = useSelector( state => state.ticketStatus_reducer);
  const { userInfoServiceCenterId } = useSelector( state => state.user_reducer);
  const { kWS } = useSelector( state => state.searchJob_reducer);
  const triggerSort = (e) => {
    dispatch(toggleSortPopUp(!sortPopUp));
  }
  const sortTicketAPI = (userInfoParam) => {
    if(activeState === "active") {
      dispatch(activeChatStatusApi(userInfoParam));
    } else if(activeState === "closed") {
      dispatch(closedChatStatusApi(userInfoParam));
    }
  }
  const sortByNewToOld = (e) => {
    const userInfoParam = {
      HEADERS : HEADERS,
      sortByUpdated : true,
      kws : kWS,
      serviceCenterId : userInfoServiceCenterId
    }
    sortTicketAPI(userInfoParam);
    dispatch(toggleSort(true));
    dispatch(toggleSortPopUp(false));
  };
  const sortByOldToNew = (e) => {
    const userInfoParam = {
      api_key : api_key,
      enterprise_id : enterprise_id,
      user_id : user_id,
      sortByUpdated : false,
      kws : kWS,
      serviceCenterId : userInfoServiceCenterId
    }
    sortTicketAPI(userInfoParam);
    dispatch(toggleSort(false));
    dispatch(toggleSortPopUp(false));
  };
  const searchJobs = (e) => {
    dispatch(updateKWS(e.target.value));
  };
  // useEffect(() => {
  //   if(userInfoServiceCenterId) {
  //     const userInfoParam = {
  //       api_key : api_key,
  //       enterprise_id : enterprise_id,
  //       user_id : user_id,
  //       sortByUpdated : sortByUpdated,
  //       kws : kWS,
  //       serviceCenterId : userInfoServiceCenterId
  //     }
  //     console.log(kWS);
  //     let debounce = setTimeout(() => {
  //       console.log("search API call");
  //       sortTicketAPI(userInfoParam);
  //     }, 5000);
  //     return () => {
  //       console.log(`cleared ${debounce}`);
  //       clearTimeout(debounce);
  //     }
  //   }
  // }, [kWS]);

  return (
    <div className="dave-ticket-search-container">
        <input type="text" className="dave-search-box" value={kWS} style={sortPopUp ? {"width":"72%"} : {"width":"87%"}} onChange={searchJobs} placeholder={"Search (Car Number, Customer Name,..)"} />
        <img src={searchIconSVG} alt="" className="dave-search-icon" />
        <button className="dave-sort-filter btn btn-lg" style={sortPopUp ? {"position":"absolute" , "z-index":"1" , "right":"0" , "width":"25%"} : {"position":"relative" , "z-index":"1" , "right":"0" , "width":"80px"}}>
          <div className="dave-sort-text-icon-container" onClick={triggerSort}>
            {
              sortPopUp ? <span className="dave-sort-text">Sort By</span> : ""
            }
            <img className='dave-sort-filter-img' src={filterIconSVG} alt='' />
          </div>
          {
            sortPopUp ? <div className="dave-sort-options-container">
              {
                sortByUpdated?[<span className="dave-sort-new-to-old" onClick={sortByNewToOld} style={!sortByUpdated ? {"background":"#fff" , "color":"#008069"} : {"background":"#008069" , "color":"#fff"}} >New To Old</span>,
                <span className="dave-sort-old-to-new" onClick={sortByOldToNew} style={sortByUpdated ? {"background":"#fff" , "color":"#008069"} : {"background":"#008069" , "color":"#fff"}}>Old To New</span>]
                : [<span className="dave-sort-new-to-old" onClick={sortByNewToOld} style={!sortByUpdated ? {"background":"#fff" , "color":"#008069"} : {"background":"#008069" , "color":"#fff"}} >New To Old</span>,
                <span className="dave-sort-old-to-new" onClick={sortByOldToNew} style={sortByUpdated ? {"background":"#fff" , "color":"#008069"} : {"background":"#008069" , "color":"#fff"}}>Old To New</span>].reverse()
              }
            </div> : ""
          }
        </button>
        
      {/* {( activeLoading || closedLoading ) ? <Loader style={{ position : "fixed", height : "100dvh", width : "100dvw" }}/> : ""} */}
    </div>
  )
}
