import React from 'react';
import { useSelector } from "react-redux";
import './ReassignedChatAction.css';

export default function ReassignedChatAction() {
  const { userInfoRole } = useSelector( state => state.user_reducer);
  const { activeTicketData } = useSelector( state => state.ticketStatus_reducer);
  //   const { user_id } = useSelector( state => state.login_reducer);
  return (
    <div className="dave-reassigned-chat-action-container input-group">
        <span className="dave-reassigned-chat-action-text">Chat has been reassigned to { userInfoRole === "smre" ? activeTicketData.smre_name : userInfoRole === "service advisor" ? activeTicketData.service_advisor_name : "" }. You can no longer send messages to this job.</span>
      </div>
  )
}
