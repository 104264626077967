import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as API_Calls from './API_Call';
import * as API from "./API_Path";

// export const DMS_API = createAsyncThunk("DMS_API", async (para,{rejectWithValue}) => {
//     const { HEADERS , requestBody } = para;
//     let inner_API = {"P_MOBILE":requestBody};
//     console.log(inner_API);
//     inner_API['_url'] = API.dMSAPI
//     inner_API['_headers'] = {
//         'Content-Type': 'application/json',
//     };
//     inner_API['_method'] = 'post'
//     try {
//         const response = await axios.post(API.PASS_THROUGH_API,inner_API,{headers : HEADERS});
//         const result = response.data.result;
//         console.log(result);
//         return result;
        
//     } catch (error) {
//         console.log(error);
//         return rejectWithValue(error.response);
//     }
// });

export const DMS_API = createAsyncThunk("DMS_API", async (para,{rejectWithValue}) => {
    const { requestBody } = para;
    let inner_API = {"P_MOBILE":requestBody};
    console.log(inner_API);
    inner_API['_url'] = API.dMSAPI
    inner_API['_headers'] = {
        'Content-Type': 'application/json',
        // 'Token': sToken
    };
    inner_API['_application'] = "dms_vehicle_details_api";
    inner_API['_method'] = 'post'
    try {
        const response = await API_Calls.postMethod(API.PASS_THROUGH_API,inner_API);
        console.log(response);
        return response.data.result;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const DMSAPI = createSlice({
    name : "DMSAPI",
    initialState : {
        DMS_Data : [],
        DMS_loading : null,
        DMS_Error : "",
    },
    extraReducers : {
        [DMS_API.pending] : (state) => {
            state.DMS_loading = true;
        },
        [DMS_API.fulfilled] : (state, action) => {
            state.DMS_loading = false;
            state.DMS_Data = action.payload;
        },
        [DMS_API.rejected] : (state, action) => {
            state.DMS_loading = false;
            state.DMS_Error = action.payload.error;
        }
    }
});

// export const { apiKey , enterpriseId , userRole , userId } = login.actions;
export default DMSAPI.reducer;