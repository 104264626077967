import React from 'react';
import './NudgeNotification.css'

export default function NudgeNotification(props) {
    const { customer_name , customer_contact_number } = props.propsData
    console.log("props",props);
  return (
    <div className="dave-nugde-container">  
        <div id="dave-nudge-action-container">
            <span id="dave-nudge-message">A Nudge sent to {customer_name} on whatsapp {customer_contact_number}.</span>
            <button id="dave-hide-nudge-popup" onClick={props.sCallBack}>OK</button>
        </div>
    </div>
  )
}
