import { configureStore , combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import login from '../feature/LoginSlice';
import userLogged from "../feature/AgentSlice";
import ticketStatus from "../feature/ChatStatusSlice";
import addCustomerOption from '../feature/AddCustomerOptionSlice';
import reassignTicket from "../feature/ReassignSlice";
import notification from "../feature/NotificationSlice";
import chatAction from "../feature/ChatActionSlice";
import messaging from "../feature/MessageSlice";
import sortJob from "../feature/SortSlice";
import logOut from "../feature/LogoutSlice";
import searchJob from "../feature/SearchSlice";
import setPagination from "../feature/PaginationSlice";
import email from "../feature/SendEmailSlice";
import DMSAPI from '../feature/DMS_APISlice';
import searchJobTicket from '../feature/TicketDataSlice';
import mediaUpload from '../feature/FileUploadSlice';
import nudgeUser from '../feature/NudgeSlice';

const persistConfig = {
  key : "root",
  version : 1,
  storage,
  whitelist: ['login_reducer','user_reducer','email_reducer'],
  blacklist: ['nudgeUser_reducer','addCustomer_reducer','ticketStatus_reducer','reassignTicket_reducer','notification_reducer','chatAction_reducer','sorting_reducer','logout_reducer','searchJob_reducer','pagination_reducer']
};

const reducers = combineReducers({
  login_reducer: login,
  user_reducer : userLogged,
  addCustomer_reducer : addCustomerOption,
  ticketStatus_reducer: ticketStatus,
  reassignTicket_reducer: reassignTicket,
  notification_reducer : notification,
  chatAction_reducer : chatAction,
  message_reducer : messaging,
  sorting_reducer : sortJob,
  logout_reducer : logOut,
  searchJob_reducer : searchJob,
  pagination_reducer : setPagination,
  email_reducer : email,
  DMSAPI_reducer : DMSAPI,
  searchJobTicket_reducer : searchJobTicket,
  mediaUpload_reducer : mediaUpload,
  nudgeUser_reducer : nudgeUser
});

const persistedReducer = persistReducer(persistConfig,reducers);
export const store = configureStore({
  reducer: persistedReducer
});