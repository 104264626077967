import React, { useState , useEffect } from 'react';
import { useDispatch , useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginApi , setCustomError } from '../../feature/LoginSlice';
import LoginError from '../error/LoginError';
import Loader from '../loader/Loader';
import './LoginPage.css';
import loginIconSVG from "../../images/login-icon.svg"
import { userIdValidation } from '../common/Common';

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading , statusCode , error , api_key , enterprise_id , user_id , HEADERS , attemptCount , } = useSelector( state => state.login_reducer);
  // const [username, setUsername] = useState("sonu-368102-smre");
  const [username, setUsername] = useState("deepakkumar-885765-wm");
  // const [username, setUsername] = useState("ajeetsoni-602059-serviceadvisor");
  // const [username, setUsername] = useState("shivkumar-774131-serviceadvisor");
  // const [username, setUsername] = useState("");
  const [password, setPassword] = useState("Multip@rty@09");
  // const [password, setPassword] = useState("");
  const updateUserName = (e) => {
    console.log(e.target.value);
    setUsername(e.target.value);
    // console.log(userIdValidation.test(username));
  };
  const updatePassword = (e) => {
    setPassword(e.target.value);
    // console.log(userPasswordValidation.test(password));
  };
  const loginUser = (e) => {
    if(!userIdValidation.test(username)){
      dispatch(setCustomError("Invalid username! , must contain alphanumeric characters and hyphen."))
      return;
    }

    let parameters = {
        enterprise_id: "maruti_core",
        password: password,
        user_id: username
    }
    dispatch(loginApi(parameters));
  };
  // useEffect(() => {
  //   if(attemptCount<3){
  //     localStorage.setItem("lastAttempt",Date.now());
  //   }
  //   if(attemptCount>2){
  //     setTimeout(()=>{
  //       dispatch(resetAttemptCount());
  //       localStorage.setItem("lastAttempt",0);
  //     },(1000 * 60 * 10));
  //   }
  //   const previousTime = new Date(+localStorage.getItem("lastAttempt"));
  //   const currentTime = new Date();
  //   const minutesDifference = (currentTime - previousTime) / (1000 * 60);
  //   console.log(previousTime)
  //   if(minutesDifference >= 10){
  //     dispatch(resetAttemptCount());
  //     localStorage.setItem("lastAttempt",0);
  //     console.log("hello from login______________________________",previousTime)
  //   }
  // },[attemptCount])

  useEffect(() => {
    if(!loading && statusCode === 200) {
      navigate("/chat");
      window.HEADERS = HEADERS;
    }
  },[loading,statusCode])
  
  return (
    <>
      <div className="dave-homepage-container col col-12">
        <div className="dave-logo-container col col-6">
            <img src={require('../../images/marutiLogo.png')} alt="" className="dave-main-logo-img" />
        </div>
        <div className="dave-signin-container col col-6">
            <div className="dave-user-details-container col">
                <input type="text" className="dave-username" placeholder='USERNAME' onChange={updateUserName} value={username}/>
                <input type="password" className="dave-password" placeholder='PASSWORD' onChange={updatePassword} value={password}/>
            </div>
            {
              error ? <LoginError/> : "" 
            }
            <div className="dave-signin-btn-container col col-4">
            <button className="dave-signin-button btn btn-sm"  onClick={loginUser}>
                <img src={loginIconSVG} alt="" className="dave-signin-button" />
            </button>
            </div>
        </div>
        <div className="dave-footer-container col col-12">
        <span className="dave-login-message">Get Login Details From Your Manager</span>
          <img src={require('../../images/artwork.png')} alt="" className="dave-footer-logo-img" />
        </div>
      </div>
      {
        loading ? <Loader/> : ""
      }
    </>
  )
}
