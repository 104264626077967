import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeChatStatusApi , closedChatStatusApi, activeTicketInfo } from '../../feature/ChatStatusSlice';
import { toggleNotificationState } from '../../feature/NotificationSlice';
import { toggleUploadFilePopUp , toggleTemplateMessagePopUp } from '../../feature/ChatActionSlice';
import { updateReassignPopUp , updateReassignAction } from '../../feature/ReassignSlice';
// import { updateMessageCount , clearMessageCount , updateActiveTicketData } from '../../feature/MessageSlice';
import { hideNudgePopUp } from '../../feature/NudgeSlice';
import { updateMessageCount , clearMessageCount } from '../../feature/MessageSlice';
import './Ticket.css';

export default function ReassignedTicket(props) {
  const dispatch = useDispatch();
  const { user_id , HEADERS } = useSelector( state => state.login_reducer);
  const { sortByUpdated } = useSelector( state => state.sorting_reducer);
  const { kWS } = useSelector( state => state.searchJob_reducer);
  const { activeState } = useSelector( state => state.ticketStatus_reducer);
  const { userInfoRole , userInfoServiceCenterId } = useSelector( state => state.user_reducer);
  const { messageCount } = useSelector( state => state.message_reducer);
  const activeTicketData = {...props.activeTicketData};
  const openChat = (e) => {
    console.log(activeTicketData);
    dispatch(hideNudgePopUp(false));
    // dispatch(updateActiveTicketData(activeTicketData));
    // dispatch(clearMessageCount(activeTicketData));
    dispatch(activeTicketInfo(activeTicketData));
    dispatch(toggleNotificationState(false));
    dispatch(toggleUploadFilePopUp(false));
    dispatch(toggleTemplateMessagePopUp(false));
    dispatch(updateReassignPopUp(false));
    dispatch(updateReassignAction(false));
    const updateUnreadMessageCount = {
      HEADERS : HEADERS,
      booking_id : activeTicketData.booking_id,
      count : 0
    }
    if(activeTicketData.message_count > 0) {
      dispatch(updateMessageCount(updateUnreadMessageCount)).then(() => {
        const userInfoParam = {
          HEADERS :HEADERS,
          sortByUpdated : sortByUpdated,
          kws : kWS,
          serviceCenterId : userInfoServiceCenterId
        }
        if(activeState === 'active') {
          dispatch(activeChatStatusApi(userInfoParam));
        } else if(activeState === 'closed') {
          dispatch(closedChatStatusApi(userInfoParam));
        }
      });
    }
  }
  
  return (
    <>
      {
        ( userInfoRole === "smre" && activeTicketData.smre_id != user_id ) || ( userInfoRole === "service advisor" && activeTicketData.service_advisor_id != user_id) ? <div className="dave-ticket-container col" onClick={openChat}>
          <div className="dave-ticket-detail-col">
          <span className="dave-vechile-registration">{activeTicketData.vehicle_reg_number}</span>
          <span className="dave-ticket-assigned">{activeTicketData.service_advisor_name || "Service Advisor"}</span>
          </div>
          <div className="dave-ticket-detail-notification">
              <div className="dave-ticket-vechile-detail-col">
                  <span className="dave-customer-name">{activeTicketData.customer_name.toLowerCase()}</span>
                  <span className="dave-customer-mobile">{activeTicketData.customer_contact_number}</span>
                  <span className="dave-vechile-model">{activeTicketData.vehicle_model.toLowerCase()}</span>
                  <span className="dave-service-type">{activeTicketData.service_type.toLowerCase()}</span>
              </div>
              <div className="dave-ticket-notification-container">
              <div className="dave-reassigned-notification-container"><button className="dave-reassigned-btn btn">REASSIGNED</button></div>
              <div className="dave-ticket-notification">
                <img src={require('../../images/TicketNotification.png')} alt="" className="dave-ticket-notification-img" />
                {
                  (activeTicketData.room_id in messageCount) && messageCount[activeTicketData.room_id] > 0 ? <span className="dave-new-message-count">{messageCount[activeTicketData.room_id]}</span> : ""
                }
              </div>
            </div>
          </div>
        </div> : ""
      }
    </>
  )
}

