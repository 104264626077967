import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as API_Calls from './API_Call';
import * as API from "./API_Path";

// export const updateMessageCount = createAsyncThunk("updateMessageCount", async (para,{rejectWithValue}) => {
//     const { HEADERS , booking_id ,count } = para;
//     const updatedBody = {
//         "message_count": count
//     }
//     try {
//         const response = await axios.patch(API.UPDATE_TICKET+booking_id,updatedBody,{headers : HEADERS});
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const updateMessageCount = createAsyncThunk("updateMessageCount", async (para,{rejectWithValue}) => {
    const { booking_id ,count } = para;
    const updatedBody = {
        "message_count": count
    }
    try {
        const response = await API_Calls.patchMethod(API.UPDATE_TICKET+booking_id,updatedBody);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const getPreviousMessage = createAsyncThunk("getPreviousMessage", async (para,{rejectWithValue}) => {
//     const { HEADERS } = para;
//     try {
//         const response = await axios.get(API.PREVIOUS_MESSAGE,{
//             params : {
//                 _sort_by : "sent_timestamp",
//                 _sort_reverse : true,
//                 page_size : 1000
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         console.log(result);
//         console.log(result.data);
//         return result.data.reverse();
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const getPreviousMessage = createAsyncThunk("getPreviousMessage", async (para,{rejectWithValue}) => {
    try {
        const response = await API_Calls.getMethod(API.PREVIOUS_MESSAGE,{
            _sort_by : "sent_timestamp",
            _sort_reverse : true,
            page_size : 1000,
        });
        console.log(response);
        return response.data.data.reverse();
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const lastCustomerMessage = createAsyncThunk("lastCustomerMessage", async (para,{rejectWithValue}) => {
    const { room_id ,sender_id } = para;

    try {
        const response = await API_Calls.getMethod(API.PREVIOUS_MESSAGE,{
            _sort_by : "sent_timestamp",
            _sort_reverse : true,
            page_size : 1,
            room_id : room_id,
            sender_id : sender_id,
        });
        console.log("lastCustomer room Message response",response.data.data);
        console.log("lastCustomerMessage response",response.data.data[0]);
        return response.data.data ? response.data.data : false;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const getPreviousMessageByRoomId = createAsyncThunk("getPreviousMessageByRoomId",async (para,{rejectWithValue}) => {
    if (para.room_id) {
        try{
            const response = await API_Calls.getMethod(API.PREVIOUS_MESSAGE,{
                _sort_by : "sent_timestamp",
                _sort_reverse : true,
                page_size : 50,
                room_id : para.room_id,
                page_number : para.page_number || 1,
            });
            return {data : response.data, room_id : para.room_id};
        }
        catch(error){
            rejectWithValue(error.response);
        }
    }
});

// export const newStatus = createAsyncThunk("newStatus", async (para,{rejectWithValue}) => {
//     const { HEADERS } = para;
//     try {
//         const response = await axios.get(API.NEW_STATUS,{
//             params : {
//                 sender_id : HEADERS['X-I2CE-USER-ID'],
//                 page_size : 10000,
//                 _fresh : true
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         console.log(result);
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const newStatus = createAsyncThunk("newStatus", async (para,{rejectWithValue}) => {
    const { HEADERS } = para;
    try {
        const response = await API_Calls.getMethod(API.NEW_STATUS,{
            sender_id : HEADERS['X-I2CE-USER-ID'],
            page_size : 1000,
            _fresh : true
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const messaging = createSlice({
    name : 'messaging',
    initialState : {
        chatMessage : {},
        previousMessage : [],
        previousMessageLoading : null,
        previousMessageError : "",
        jobPreviousMessageLoading : null,
        jobPreviousMessageError : "",
        messageCount : {},
        newStatusData : {},
        newStatusLoading : null,
        newStatusError : "",
        automatedResponse : {},
        activeTktData : {},
        messageText : {},
        chatMessagesStatus : {},
        lastCustomerMessageData : [],
        lastCustomerMessageLoading : "",
        lastCustomerMessageError : "",
        customerActive : "",
        messageAction : "",
        readReceits : {},
        updateMessageCountData : "",
        updateMessageCountLoading : "",
        updateMessageCountError : "",
        tempMessage : {},
    },
    reducers : {
        updateChatMessageLoading : (state, action) => {
            if(action.payload.room_id in state.chatMessage) {
                console.log(state.chatMessage[action.payload.room_id]);
                // state.chatMessage[action.payload.room_id].push(action.payload);
            }
            else {
                Object.assign(state.chatMessage, {
                    [action.payload.room_id]: [],
                });
            }
        },
        updateChatMessage : (state, action) => {
            if(action.payload.room_id in state.chatMessage) {
                console.log(state.chatMessage[action.payload.room_id]);
                state.chatMessage[action.payload.room_id].push(action.payload);
            }
            else {
                Object.assign(state.chatMessage, {
                    [action.payload.room_id]: [action.payload],
                });
            }
            if(action.payload.room_id in state.tempMessage) {
                state.tempMessage[action.payload.room_id].map( ( chat , index ) => {
                    console.log("chat",chat,"index",index);
                    console.log("chat.data.temMessageID",chat.data.temMessageID)
                    console.log("action.payload.data.temMessageID",action.payload.data.temMessageID);
                    console.log("Compare",action.payload.data.temMessageID === chat.data.temMessageID);
                    action.payload.data.temMessageID === chat.data.temMessageID && state.tempMessage[action.payload.data.room_id].splice(index,1)
                    // console.log("first",action.payload.data.temMessageID in chat && state.tempMessage[action.payload.data.room_id].splice(index,1))
                })
                console.log("state.tempMessage",state.tempMessage);
                console.log("Temp message is there");
                console.log("action.payload.data.temMessageID",action.payload.data.temMessageID)
            }
        },
        updateReadReceits : (state, action) => {
            if(action.payload.room_id in state.chatMessage) {
                if(action.payload.command === "read") {
                    state.readReceits[action.payload.message_id] = action.payload.command;
                } else if(action.payload.command === "delivered" && state.readReceits[action.payload.message_id] !== "read") {
                    state.readReceits[action.payload.message_id] = action.payload.command;
                } else if (action.payload.command === "sent" && state.readReceits[action.payload.message_id] === "failed") {
                    state.readReceits[action.payload.message_id] = action.payload.command;
                }
            }
            else {
                Object.assign(state.readReceits, {[action.payload.message_id]: action.payload.command});
            }
        },
        incrementMessageCount: (state,action) => {
            if(action.payload.room_id in state.messageCount) {
                console.log(state.messageCount[action.payload.room_id]);
                state.messageCount[action.payload.room_id] += 1;
            }
            else {
                Object.assign(state.messageCount, {
                    [action.payload.room_id]: 1,
                });
            }
        },
        clearMessageCount: (state,action) => {
            if(action.payload in state.messageCount) {
                console.log(state.messageCount[action.payload]);
                state.messageCount[action.payload] = 0;
            }
        },
        updatePreviousMessage: (state,action) => {
            state.previousMessage = action.payload;
        },
        updateAutomatedResponse: (state,action) => {
            Object.assign(state.automatedResponse, {[action.payload.data.reply_id]: [action.payload.data.suggestion[0]]});
        },
        updateMessageText: (state,action) => {
            if(action.payload.room_id in state.messageText) {
                console.log(state.messageText[action.payload.room_id]);
                state.messageText[action.payload.room_id] = action.payload.message;
            }
            else {
                Object.assign(state.messageText, {
                    [action.payload.room_id]: [action.payload.message],
                });
            }
        },
        updateCustomerActive : (state,action) => {
            state.customerActive = action.payload;
        },
        updateMessageAction : (state, action) => {
            state.messageAction = action.payload;
        },
        updateLastCustomerMessage : (state, action) => {
            action.payload !== false ? state.lastCustomerMessageData = [action.payload] : state.lastCustomerMessageData = false;
        },
        addToTempMessage : (state, action) => {
            if(action.payload.room_id in state.tempMessage) {
                // state.tempMessage[action.payload.room_id][action.payload.data.temMessageID] = action.payload;
                state.tempMessage[action.payload.room_id].push(action.payload)
            } else {
                Object.assign(state.tempMessage,{
                    [action.payload.room_id] : [action.payload]
                    // [action.payload.room_id]:  { [action.payload.data.temMessageID] : action.payload}
                })
            }
        },
        deleteFromTemMessages : (state, action) => {
            // if(action.payload.data.room_id in state.tempMessage && action.payload.data.temMessageID in state.tempMessage[action.payload.data.room_id] ) {
            //     console.log("Temp message is there");
            //     console.log("action.payload.data.temMessageID",action.payload.data.temMessageID)
            // }
            if(action.payload.data.room_id in state.tempMessage) {
                state.tempMessage[action.payload.data.room_id].map( ( chat , index ) => {
                    action.payload.data.temMessageID in chat && state.tempMessage[action.payload.data.room_id].splice(index,1);
                })
                console.log("state.tempMessage",state.tempMessage);
                console.log("Temp message is there");
                console.log("action.payload.data.temMessageID",action.payload.data.temMessageID)
            }
        }
    },
    extraReducers : {
        [updateMessageCount.pending] : (state) => {
            state.updateMessageCountLoading = true;
        },
        [updateMessageCount.fulfilled] : (state, action) => {
            state.updateMessageCountLoading = false;
            state.updateMessageCountData = action.payload;
        },
        [updateMessageCount.rejected] : (state, action) => {
            state.updateMessageCountLoading = false;
            state.updateMessageCountError = action.payload;
        },
        [getPreviousMessage.pending] : (state) => {
            state.previousMessageLoading = true;
        },
        [getPreviousMessage.fulfilled] : (state, action) => {
            state.previousMessageLoading = false;
            action.payload.map((message) => {
                if(message.data.message || message.data.media_url) {
                    if(message.room_id in state.chatMessage) {
                        state.chatMessage[message.room_id].push(message);
                    }
                    else {
                        Object.assign(state.chatMessage, {
                            [message.room_id]: [message]
                        });
                    }
                } else if(message.data.suggestion) {
                    Object.assign(state.automatedResponse, {[message.message_id]: [message.data.suggestion[0]]});
                } 
                return 0;
            });
        },
        [getPreviousMessage.rejected] : (state, action) => {
            state.previousMessageLoading = false;
            state.previousMessageError = action.payload;
        },
        [getPreviousMessageByRoomId.pending] : (state, action) => {
            // state.previousMessageLoading = true;
        },
        [getPreviousMessageByRoomId.fulfilled] : (state, action) => {
            state.previousMessageLoading = false;
            // console.log("previousMessage:",action.payload.data)
            // console.log("Action Payload:",action.payload)
            // action.payload.data.data.map((mes) => {
            //     console.log("Message:",mes);
            //     mes.data.reply_id ? console.log("Auto Prompt",mes.data.reply_id + "----" +  mes.data.suggestion[0]) : console.log("Messages",mes.room_id + "----" + mes.data);
            //     if(mes.data.reply_id) {
            //         state.automatedResponse[mes.data.reply_id] = mes.data.suggestion[0];
            //     } else if(mes.data.message || mes.data.filename) {
            //         if(state.chatMessagesStatus[action.payload.room_id]){
            //             state.chatMessage[action.payload.room_id].unshift(mes);
            //             if(!state.chatMessagesStatus[action.payload.room_id]?.is_last || !state.chatMessagesStatus[action.payload.room_id]?.is_last){
            //                 state.chatMessagesStatus[action.payload.room_id] = {
            //                     is_last : action.payload.data.is_last,
            //                     page_number : action.payload.data.page_number
            //                 };
            //                 return;
            //             }
            //             state.chatMessagesStatus[action.payload.room_id].is_last = action.payload.data.is_last;
            //             state.chatMessagesStatus[action.payload.room_id].page_number = action.payload.data.page_number;
            //         }
            //         else{
            //             // state.chatMessage[action.payload.room_id].push(mes);
            //             Object.assign(state.chatMessage, {
            //                 [action.payload.room_id]: [mes]
            //             });
            //             state.chatMessagesStatus[action.payload.room_id] = {
            //                 is_last : action.payload.data.is_last,
            //                 page_number : action.payload.data.page_number
            //             };
            //         }
            //     }
            // })
            // if(action.payload.data.re) {}
            // console.log(action.payload);
            action.payload.data.data?.forEach((val) => {
                if(val.sent_via === "socket") {
                    if(val.read.length) {
                        state.readReceits[val.message_id] = "read";
                    } else if(val.delivered_to.length) {
                        state.readReceits[val.message_id] = "delivered";
                    }
                }
                if(val.data.reply_id){
                    state.automatedResponse[val.data.reply_id] = val.data.suggestion;
                }
            });
            const messages = action.payload.data.data?.filter((val) => !val.data.reply_id)
            if(action.payload.data.total_number) {
                let count = 0;
                for(let i = 0; i < action.payload.data.data.length ; i++ ) {
                    if(action.payload.data.data[i].sent_via === "socket") {
                        break;
                    } else if(!action.payload.data.data[i].data.suggestion) {
                        console.log("action.payload.data.data.reverse()[i]",action.payload.data.data[i])
                        count++;
                    }
                }
                // console.log(action.payload.room_id+" count",i);
                console.log(action.payload.room_id+" count",count)
                if(action.payload.room_id in state.messageCount) {
                    console.log(state.messageCount[action.payload.room_id]);
                    state.messageCount[action.payload.room_id] = count;
                }
                else {
                    Object.assign(state.messageCount, {
                        [action.payload.room_id]: count,
                    });
                }
                // action.payload.data.reverse().
            }
            console.log("action.payload.room_id",action.payload.room_id);
            if(state.chatMessagesStatus[action.payload.room_id]){
                state.chatMessage[action.payload.room_id].unshift(...messages.reverse());
                console.log("previousMessageArray:",messages);
                if(!state.chatMessagesStatus[action.payload.room_id]?.is_last){
                    state.chatMessagesStatus[action.payload.room_id] = {
                        is_last : action.payload.data.is_last,
                        page_number : action.payload.data.page_number
                    };
                    return;
                }
                state.chatMessagesStatus[action.payload.room_id].is_last = action.payload.data.is_last;
                state.chatMessagesStatus[action.payload.room_id].page_number = action.payload.data.page_number;
            }
            else{
                if(!action.payload.room_id) return;
                state.chatMessage[action.payload.room_id] = [...messages].reverse();
                state.chatMessagesStatus[action.payload.room_id] = {
                    is_last : action.payload.data.is_last,
                    page_number : action.payload.data.page_number
                };
            }            
        },
        // [getPreviousMessageByRoomId.fulfilled] : (state, action) => {
        //     state.previousMessageLoading = false;
        //     console.log("previousMessage:",action.payload.data)
        //     console.log("Action Payload:",action.payload)
        //     const automatedResponsesChat = action.payload.data.data?.forEach((val) => {
        //         if(val.data.reply_id){
        //             state.automatedResponse[val.data.reply_id] = val.data.suggestion;
        //         }
        //     });
        //     const messages = action.payload.data.data?.filter((val) => !val.data.reply_id)
        //     if(action.payload.data.total_number) {
        //         let count = 0;
        //         for(let i = 0; i < action.payload.data.data.length ; i++ ) {
        //             if(action.payload.data.data[i].sent_via === "socket") {
        //                 break;
        //             } else if(!action.payload.data.data[i].data.suggestion) {
        //                 console.log("action.payload.data.data.reverse()[i]",action.payload.data.data[i])
        //                 count++;
        //             }
        //         }
        //         // console.log(action.payload.room_id+" count",i);
        //         console.log(action.payload.room_id+" count",count)
        //         if(action.payload.room_id in state.messageCount) {
        //             console.log(state.messageCount[action.payload.room_id]);
        //             state.messageCount[action.payload.room_id] = count;
        //         }
        //         else {
        //             Object.assign(state.messageCount, {
        //                 [action.payload.room_id]: count,
        //             });
        //         }
        //         // action.payload.data.reverse().
        //     }
        //     console.log("action.payload.room_id",action.payload.room_id);
        //     if(state.chatMessagesStatus[action.payload.room_id]){
        //         state.chatMessage[action.payload.room_id].unshift(...messages.reverse());
        //         console.log("previousMessageArray:",messages);
        //         if(!state.chatMessagesStatus[action.payload.room_id]?.is_last || !state.chatMessagesStatus[action.payload.room_id]?.is_last){
        //             state.chatMessagesStatus[action.payload.room_id] = {
        //                 is_last : action.payload.data.is_last,
        //                 page_number : action.payload.data.page_number
        //             };
        //             return;
        //         }
        //         state.chatMessagesStatus[action.payload.room_id].is_last = action.payload.data.is_last;
        //         state.chatMessagesStatus[action.payload.room_id].page_number = action.payload.data.page_number;
        //     }
        //     else{
        //         if(!action.payload.room_id) return;
        //         state.chatMessage[action.payload.room_id] = [...messages].reverse();
        //         state.chatMessagesStatus[action.payload.room_id] = {
        //             is_last : action.payload.data.is_last,
        //             page_number : action.payload.data.page_number
        //         };
        //     }            
        // },
        [newStatus.pending] : (state) => {
            state.newStatusLoading = true;
        },
        [newStatus.fulfilled] : (state, action) => {
            state.newStatusLoading = false;
            state.newStatusData = action.payload.data;
        },
        [newStatus.rejected] : (state, action) => {
            state.newStatusLoading = false;
            state.newStatusError = action.payload;
        },
        [lastCustomerMessage.pending] : (state) => {
            state.lastCustomerMessageLoading = true;
        },
        [lastCustomerMessage.fulfilled] : (state, action) => {
            state.lastCustomerMessageLoading = false;
            state.lastCustomerMessageData = action.payload;
        },
        [lastCustomerMessage.rejected] : (state, action) => {
            state.lastCustomerMessageLoading = false;
            state.lastCustomerMessageError = action.payload;
        }
    }
})

// export const { updateMessageText , addToManagerEmailCounterList , deleteFromManagerEmailCounterList , addToAdvisorEmailCounterList , deleteFromAdvisorEmailCounterList , updateChatMessage , updatePreviousMessage , updateAutomatedResponse , clearMessageCount , incrementMessageCount , updateActiveTicketData } = messaging.actions;
export const { addToTempMessage , deleteFromTemMessages , updateChatMessageLoading , updateReadReceits , updateLastCustomerMessage , updateMessageAction , updateCustomerActive , updateMessageText , addToManagerEmailCounterList , deleteFromManagerEmailCounterList , addToAdvisorEmailCounterList , deleteFromAdvisorEmailCounterList , updateChatMessage , updatePreviousMessage , updateAutomatedResponse , clearMessageCount , incrementMessageCount } = messaging.actions;
export default messaging.reducer;