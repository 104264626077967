import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as API_Calls from './API_Call';
import * as API from "./API_Path";

// export const fileUpload = createAsyncThunk("fileUpload", async (para,{rejectWithValue}) => {
//     const { api_key, enterprise_id ,user_id , file } = para;
//     console.log(file);
//     // console.log(HEADERS);
//     var formData = new FormData();
//     formData.append('file', file);
//     try {
//         const response = await axios.post(API.FILE_UPLOAD,formData,{
//             params : {
//                 large_file : true,
//                 full_path : true,
//             },
//             headers : {
//                 "Content-Type":"multipart/form-data",
//                 "X-I2CE-ENTERPRISE-ID":enterprise_id,
//                 "X-I2CE-USER-ID":user_id,
//                 "X-I2CE-API-KEY":api_key
//             }
//         });
//         const result = response.data;
//         console.log(result)
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const fileUpload = createAsyncThunk("fileUpload", async (para,{rejectWithValue}) => {
    const { api_key, enterprise_id ,user_id , file } = para;
    var formData = new FormData();
    formData.append('file', file);
    try {
        const response = await API_Calls.postMethod(API.FILE_UPLOAD,formData,{
                large_file : true,
                full_path : true,
        },
        {
            "Content-Type":"multipart/form-data",
            "X-I2CE-ENTERPRISE-ID":enterprise_id,
            "X-I2CE-USER-ID":user_id,
            "X-I2CE-API-KEY":api_key
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const mediaUpload = createSlice({
    name : "mediaUpload",
    initialState : {
        fileUploadData : "",
        fileUploadloading : null,
        fileUploaderror : "",
        fileType : "",
        fileName : "",
        selectedFile:null
    },
    reducers : {
        deletefileData: (state,action) => {
            state.fileUploadData = {};
            state.fileType = "";
            state.fileName = "";
            state.selectedFile = null;
        },
        setFileType: (state,action) => {
            state.fileType = action.payload;
        },
        setFileName : (state,action) => {
            state.fileName = action.payload;
        },
        toggleUploadLoading : (state,action) => {
            state.fileUploadloading = action.payload;
        },
        setSelectedFile:(state,action) => {
            state.selectedFile = action.payload;
        }
    },
    extraReducers : {
        [fileUpload.pending] : (state) => {
            state.fileUploadloading = true;
            state.fileUploaderror = "";
        },
        [fileUpload.fulfilled] : (state, action) => {
            state.fileUploadloading = false;
            console.log(action.payload);
            state.fileUploadData = action.payload;
        },
        [fileUpload.rejected] : (state, action) => {
            state.fileUploadloading = false;
            state.fileUploaderror = action.payload;
        }
    }
});

export const { deletefileData , setFileType , setFileName , toggleUploadLoading , setSelectedFile } = mediaUpload.actions;
export default mediaUpload.reducer;