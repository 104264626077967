import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as  API from './API_Path';
import * as API_Calls from './API_Call';

// export const activeChatStatusApi = createAsyncThunk("activeChatStatusApi", async (para,{rejectWithValue}) => {
//     const { HEADERS , sortByUpdated , kws } = para;
//     try {
//         const response = await axios.get(API.GET_SERVICE_JOBS,{
//             params : {
//                 status : "active",
//                 assigned_agents : HEADERS['X-I2CE-USER-ID'],
//                 _sort_by : "updated",
//                 _sort_reverse : sortByUpdated,
//                 search_term : "~"+kws
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         console.log(response.data)
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });


export const activeChatStatusApi = createAsyncThunk("activeChatStatusApi", async (para,{rejectWithValue}) => {
    const { HEADERS , sortByUpdated , kws , pageNumber } = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_JOBS,{
            status : "active" ,
            assigned_agents : HEADERS['X-I2CE-USER-ID'] ,
            _sort_by : "updated" ,
            _sort_reverse : sortByUpdated ,
            search_term : "~"+kws,
            page_number : pageNumber || 1
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const activeChatStatusApi = createAsyncThunk("activeChatStatusApi", async (para) => {
//     const { HEADERS , sortByUpdated , kws } = para;
//         const res = await API_Calls.getMethod(API.GET_SERVICE_JOBS,
//             { status : "active" ,
//              assigned_agents : HEADERS['X-I2CE-USER-ID'] ,
//               _sort_by : "updated" ,
//                _sort_reverse : sortByUpdated ,
//                 search_term : "~"+kws
//             },HEADERS)
//         .then((response => {
//             console.log(response.data);
//             return response;
//         }),(err) => {
//             console.error(err);
//         });
//         return res;
// });






// export const closedChatStatusApi = createAsyncThunk("closedChatStatusApi", async (para,{rejectWithValue}) => {
//     const { HEADERS , sortByUpdated , kws } = para;
//     try {
//         const response = await axios.get(API.GET_SERVICE_JOBS,{
//             params : {
//                 status : "closed",
//                 assigned_agents : HEADERS['X-I2CE-USER-ID'],
//                 _sort_by : "updated",
//                 _sort_reverse : sortByUpdated,
//                 search_term : "~"+kws
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const updateTicketsListApi = createAsyncThunk("updateTicketsListApi",async (para,{rejectWithValue}) => {
    const {HEADERS , sortByUpdated , kws , pageNumber , status} = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_JOBS,{
            status : status,
            assigned_agents : HEADERS['X-I2CE-USER-ID'],
            _sort_by : "updated",
            _sort_reverse : sortByUpdated,
            search_term : "~"+kws,
            page_number : pageNumber
        });
        return response.data;
    }
    catch(err) {
        return rejectWithValue(err.response);
    }
})

export const closedChatStatusApi = createAsyncThunk("closedChatStatusApi", async (para,{rejectWithValue}) => {
    const { HEADERS , sortByUpdated , kws , pageNumber } = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_JOBS,{
            status : "closed",
            assigned_agents : HEADERS['X-I2CE-USER-ID'],
            _sort_by : "updated",
            _sort_reverse : sortByUpdated,
            search_term : "~"+kws,
            page_number : pageNumber || 1
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const getTicketData = createAsyncThunk("getTicketData", async (para,{rejectWithValue}) => {
    const { booking_id } = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_JOBS,{
            booking_id : booking_id,
        });
        console.log("TicketData",response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const ticketStatus = createSlice({
    name : 'ticketStatus',
    initialState : {
        activeData : [],
        activeNumber : "",
        activeIsFirst : "",
        activeIsLast : "",
        activePageNumber : "",
        activeLoading : null,
        activeError : "",
        pendingData : [],
        pendingNumber : "",
        pendingIsFirst : "",
        pendingIsLast : "",
        pendingPageNumber : "",
        pendingLoading : null,
        pendingError : "",
        closedData : [],
        closedNumber : "",
        closedIsFirst : "",
        closedIsLast : "",
        closedPageNumber : "",
        closedLoading : null,
        closedError : "",
        activeTicketData : "",
        activeState : "active",
        ticketCountData : {
            active : null,
            closed : null,
            pending : 0
        },
        ticketCountLoading : null,
        ticketCountError : "",
        lastServiceSpaceData : "",
        getTicketDataLoading : null,
        getTicketDataError : null,
        reOpenPopup : false,
    },
    reducers : {
        updateState : (state, action) => {
            state.activeState = action.payload;
        },
        activeTicketInfo: (state,action) => {
            state.reOpenPopup = false;
            state.activeTicketData = action.payload;
        },
        clearActiveTicketData : (state, action) => {
            state.activeTicketData = action.payload;
        },
        updateLastServiceSpaceData : (state, action) => {
            state.lastServiceSpaceData = action.payload;
        },
        updateReOpenPopup: (state,action) => {
            state.reOpenPopup = action.payload;
        }
    },
    extraReducers : {
        [activeChatStatusApi.pending] : (state) => {
            state.activeLoading = true;
        },
        [activeChatStatusApi.fulfilled] : (state, action) => {
            state.activeLoading = false;
            state.activeData = action.payload.data;
            state.activeIsFirst = action.payload.is_first;
            state.activeIsLast = action.payload.is_last;
            state.activePageNumber = action.payload.page_number;
            state.activeNumber = action.payload.total_number;
            state.ticketCountData.active = action.payload.total_number;
        },
        [activeChatStatusApi.rejected] : (state, action) => {
            state.activeLoading = false;
            state.activeError = action.payload;
        },
        [closedChatStatusApi.pending] : (state) => {
            state.closedLoading = true;
        },
        [closedChatStatusApi.fulfilled] : (state, action) => {
            state.closedLoading = false;
            state.closedData = action.payload.data;
            state.closedIsFirst = action.payload.is_first;
            state.closedIsLast = action.payload.is_last;
            state.closedPageNumber = action.payload.page_number;
            state.closedNumber = action.payload.total_number;
            state.ticketCountData.closed = action.payload.total_number;
        },
        [closedChatStatusApi.rejected] : (state, action) => {
            state.closedLoading = false;
            state.closedError = action.payload;
        },
        [updateTicketsListApi.pending] : (state) => {
            state.ticketCountData.active = state.activeState === "active" ? null : state.ticketCountData.active;
            state.ticketCountData.closed = state.activeState === "closed" ? null : state.ticketCountData.closed;
        },
        [updateTicketsListApi.fulfilled] : (state, action) => {
            state.activeLoading = false;
            state.closedLoading = false;
            if(state.activeState === "active"){
                state.activeData = [...state.activeData,...action.payload.data];
                state.activeIsFirst = action.payload.is_first;
                state.activeIsLast = action.payload.is_last;
                state.activePageNumber = action.payload.page_number;
                state.activeNumber = action.payload.total_number;
                state.ticketCountData.active = action.payload.total_number;
            }
            else{
                state.closedData = [...state.closedData,...action.payload.data];
                state.closedIsFirst = action.payload.is_first;
                state.closedIsLast = action.payload.is_last;
                state.closedPageNumber = action.payload.page_number;
                state.closedNumber = action.payload.total_number;
                state.ticketCountData.closed = action.payload.total_number;
            }
        },
        [getTicketData.pending] : (state) => {
            state.getTicketDataLoading = true;
        },
        [getTicketData.fulfilled] : (state, action) => {
            state.getTicketDataLoading = false;
            state.activeTicketData = action.payload.data[0];
        },
        [getTicketData.rejected] : (state, action) => {
            state.getTicketDataLoading = false;
            state.getTicketDataError = action.payload;
        },
    }
});

export const { activeTicketInfo , updateState , clearActiveTicketData , updateLastServiceSpaceData , updateReOpenPopup } = ticketStatus.actions;
export default ticketStatus.reducer;