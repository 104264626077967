export const userId = /^[a-zA-Z0-9._]{3,16}$/g;
export const userPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{7,}$/;
export const mobile = /^[6789]\d{9}$/;
export const inputSuccess = '2px solid #008069';
export const inputError = '2px solid red';
export const customerNameValidation = /^[a-zA-Z0-9-_ ]+$/
export const userIdValidation = /^[a-zA-Z0-9-]+$/

const characters_ ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function generateTempMesID(length = 32) {
    let result = ' ';
    const charactersLength = characters_.length;
    for ( let i = 1; i <= length; i++ ) {
        result += characters_.charAt(Math.floor(Math.random() * charactersLength));
        if(i % 5 == 0 && i < length) {
            result += "-"
        }
    }
    return result;
}

export function dateFrom(timestamp) {
    let td = new Date(); 
    if (timestamp.search(/\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d [A|P]M [\+|-]\d\d\d\d/) >= 0) {                                                        
        let [y, m, d, H, M, S, O] = timestamp.split(/\D+/);
        if (!S) {
            S = '00'
        }
        if (!M) {
            M = td.getMinutes()
        }
        if (!H) {
            H = td.getHours()
        }
        timestamp = new Date(y + '-' + m + '-' + d + 'T' + H + ':' + M + ':' + S + '.000' + timestamp.slice(-5));
    }
    return timestamp;
}