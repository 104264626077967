import React from 'react';
import { useSelector , useDispatch} from 'react-redux';
import { updateReassignPopUp , updateReassignAction } from '../../feature/ReassignSlice';
import './ActiveTicketDetail.css';
import ActiveTicketInfo from './ActiveTicketInfo'
import DropdownIconSVG from "../../images/dropdown-icon.svg"

export default function ActiveTicketDetail() {
  const dispatch = useDispatch();
  const { user_id } = useSelector( state => state.login_reducer);
  const { userInfoRole } = useSelector( state => state.user_reducer);
  const { vehicle_reg_number , smre_id , service_advisor_id, status } = useSelector( state => state.ticketStatus_reducer.activeTicketData);
  const { reassignPopUp } = useSelector( state => state.reassignTicket_reducer);
  const toggleReassignPopUp = (e) => {
    dispatch(updateReassignPopUp(!reassignPopUp));
    dispatch(updateReassignAction(false));
  }
  return (
    <div className="dave-active-ticket-detail-container">
        <div className="dave-active-ticket-no col">
          <span className="dave-active-ticket-no">{vehicle_reg_number}</span>
        </div>
        <div className="dave-active-ticket-veichle-info-container">
          <ActiveTicketInfo/>
          {
            status === "active" && (( userInfoRole === "smre" && smre_id === user_id ) || ( userInfoRole === "service advisor" && service_advisor_id === user_id ) || userInfoRole === "wm" || userInfoRole === "ccm") ? <button className="dave-ticket-reassign btn btn-lg" onClick={toggleReassignPopUp}>
              <img src={DropdownIconSVG} alt="" style={reassignPopUp ? {"transform":"rotate(180deg)",height:"1rem"} : {"transform":"rotate(0deg)",height:"1rem"}} />
            </button> : ""
          }
        </div>
    </div>
  )
}
