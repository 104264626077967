import { createSlice } from "@reduxjs/toolkit";

export const logOut = createSlice({
    name : 'logOut',
    initialState : {
        logout : false
    },
    reducers : {
        toggleLogout: (state,action) => {
            state.logout = action.payload;
        }
    }
})

export const { toggleLogout } = logOut.actions;
export default logOut.reducer;