import React, { useEffect } from 'react';
import { useSelector , useDispatch } from "react-redux";
import './ActiveChat.css';
import ActiveTicketDetail from './ActiveTicketDetail';
import ChangeStatus from './ChangeStatus';
import ChatAction from './ChatAction';
import ReassignPopup from './ReassignPopup';
import Message from './Message';
import ReassignedChatAction from './ReassignedChatAction';
import Loader from '../loader/Loader';
import { updateState , getTicketData , activeChatStatusApi , closedChatStatusApi , updateReOpenPopup } from '../../feature/ChatStatusSlice';
import { reOpenJob } from '../../feature/TicketDataSlice';

export default function ActiveChat() {
  const dispatch = useDispatch();
  const { user_id , HEADERS } = useSelector( state => state.login_reducer);
  const { kWS } = useSelector( state => state.searchJob_reducer);
  const { sortByUpdated } = useSelector( state => state.sorting_reducer);
  const { userInfoRole , userInfoServiceCenterId } = useSelector( state => state.user_reducer);
  const { reassignPopUp , reassignActionPopUp } = useSelector( state => state.reassignTicket_reducer);
  const { activeTicketData , reOpenPopup } = useSelector( state => state.ticketStatus_reducer);
  const { fileUploadloading } = useSelector( state => state.mediaUpload_reducer);
  const { previousMessageLoading } = useSelector( state => state.message_reducer);
  // const agentReassagined = activeTicketData.assigned_agents.filter(agents => agents === user_id);
  // console.log(agentReassagined.length);
  const reOpenTicketPopup = () => {
    dispatch(updateReOpenPopup(true));
  }
  const reOpenTicket = () => {
    console.log("activeTicketData",activeTicketData);
    const bookingIdData  = {
      booking_id : activeTicketData.booking_id
    }
    dispatch(reOpenJob(bookingIdData)).then(() => {
      dispatch(updateState("active"));
      dispatch(getTicketData(bookingIdData));
      const userInfoParam = {
        HEADERS : HEADERS,
        sortByUpdated : sortByUpdated,
        kws : kWS,
        serviceCenterId : userInfoServiceCenterId
      }
      dispatch(updateReOpenPopup(false));
      dispatch(activeChatStatusApi(userInfoParam));
      dispatch(closedChatStatusApi(userInfoParam));
    });
  }
  const closeReOpenTicketPopup = () => {
    dispatch(updateReOpenPopup(false));
    // console.log("activeTicketData",activeTicketData);
    // const bookingIdData  = {
    //   booking_id : activeTicketData.booking_id
    // }
    // dispatch(reOpenJob(bookingIdData)).then(() => {
    //   dispatch(updateState("active"));
    //   dispatch(getTicketData(bookingIdData));
    //   const userInfoParam = {
    //     HEADERS : HEADERS,
    //     sortByUpdated : sortByUpdated,
    //     kws : kWS,
    //     serviceCenterId : userInfoServiceCenterId
    //   }
    //   dispatch(activeChatStatusApi(userInfoParam));
    //   dispatch(closedChatStatusApi(userInfoParam));
    // });
  }
  useEffect(() => {
  }, [activeTicketData,reOpenPopup])
  
  return (
    <div className="dave-active-chat-container col col-5">
      {
        reOpenPopup && <div id="dave-popup-container">
          <span id="dave-popup-message">Are you sure want to reopen job {activeTicketData.vehicle_reg_number}?</span>
          <div id="dave-job-reopen-action-container">
            <button className="dave-reopen-action" id="dave-reopen-confirm"  onClick={reOpenTicket}>Yes</button>
            <button className="dave-reopen-action" id="dave-reopen-reject"  onClick={closeReOpenTicketPopup}>No</button>
          </div>
        </div>
      }
      {
        activeTicketData ? <ActiveTicketDetail/> : ""
      }
      {
        reassignPopUp && !reassignActionPopUp ? <ChangeStatus/> : ""
      }
      {
        reassignPopUp && reassignActionPopUp ? <ReassignPopup/> : ""
      }
      {
        activeTicketData ? <Message/> : ""
      }
      {
        activeTicketData && (( userInfoRole === "smre" && activeTicketData.smre_id === user_id ) || ( userInfoRole === "service advisor" && activeTicketData.service_advisor_id === user_id ) || userInfoRole === "wm" || userInfoRole === "ccm" ) ?
         activeTicketData?.status ==="active"?
          <ChatAction/> : activeTicketData?.status === "closed" ?
            <span className="closed-ticket-info">This ticket has been closed!{<button id="dave-reopen-ticket" onClick={reOpenTicketPopup}>Reopen Ticket</button>}</span> :
             "" : activeTicketData && activeTicketData?.status === "closed" ?
              <div className="flex-column" style={{display:"flex",flexDirection:"column"}}> <ReassignedChatAction/> 
              <span className="closed-ticket-info">This ticket has been closed!</span>
                </div> : activeTicketData && <ReassignedChatAction />
      }
      {/* {
        activeTicketData && (( userInfoRole === "smre" && activeTicketData.smre_id === user_id ) || ( userInfoRole === "service advisor" && activeTicketData.service_advisor_id === user_id ) || userInfoRole === "wm" || userInfoRole === "ccm" ) ? activeTicketData?.status ==="active"? <ChatAction/> : activeTicketData?.status === "closed" ?  <span className="closed-ticket-info">This ticket has been closed!</span> : "" : activeTicketData && activeTicketData?.status === "closed" ? <div className="flex-column" style={{display:"flex",flexDirection:"column"}}> <ReassignedChatAction/> <span className="closed-ticket-info">This ticket has been closed!</span>  </div> : activeTicketData && <ReassignedChatAction />
      } */}
      {
        !activeTicketData ? <div className="dave-no-active-message-container">
          <span className="dave-no-active-message">Select Job For Message</span>
        </div> : ""
      }
      
      {fileUploadloading || (previousMessageLoading && activeTicketData) ? <Loader style={{position:"absolute",height:"100%",width:"100%",borderRadius:"10px"}}/>:""}
    </div>
  )
}
