import axios from 'axios';
export const getMethod = (url,parameter,headers) => {
    // console.log(HEADERS);
    // console.log(window.HEADERS);
    // return new Promise((resolve, reject) => {
    //     axios.get(url,{
    //         params : parameter,
    //         // headers : HEADERS
    //         headers : window.HEADERS
    //     }).then(response => {
    //         if (response.status < 400) {
    //             return resolve(response.data);
    //         } else {
    //             return reject(response.status, response);
    //         }
    //     });    
    // });
    return axios.get(url,{
        params : parameter,
        headers : headers || window.HEADERS
    });
};

export const postMethod = (url,body,parameter,headers) => {
    return axios.post(url,body,{
        params : parameter,
        headers : headers || window.HEADERS
    });
};

export const patchMethod = (url,body,parameter,headers) => {
    return axios.patch(url,body,{
        params : parameter,
        headers : headers || window.HEADERS
    });
};