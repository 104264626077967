import React from 'react';
import { useSelector , useDispatch} from 'react-redux';
import { getSMREData , getServiceAdvisiorData ,updateReassignAction } from '../../feature/ReassignSlice';
import './ChangeStatus.css';

export default function ChangeStatus(props) {
  const dispatch = useDispatch();
  const { HEADERS } = useSelector( state => state.login_reducer);
  const { userInfoRole , userInfoLoading , userInfoServiceCenterId } = useSelector( state => state.user_reducer);
  const toggleReassignActionPopUp = (e) => {
    dispatch(updateReassignAction(true));
    const userInfoParam = {
      HEADERS : HEADERS,
      service_center_id : userInfoServiceCenterId
    }
    if(!userInfoLoading && (userInfoRole === 'ccm' || userInfoRole === 'wm')) {
      dispatch(getSMREData(userInfoParam))
      dispatch(getServiceAdvisiorData(userInfoParam))
    } else if(!userInfoLoading && ( userInfoRole === 'smre' || userInfoRole === 'service advisor' )) {
      dispatch(getServiceAdvisiorData(userInfoParam))
    }
  }
  return (
    <div className="dave-ticket-status-change-container col">
        <button className="dave-ticket-reassign-btn btn btn-lg" onClick={toggleReassignActionPopUp}>REASSIGN</button>
    </div>
  )
}
