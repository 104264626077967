import React from 'react';
import './Loader.css';

export default function Loader({style}) {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  return (
    <div style={{...style}} className="dave-loader">
        <img style={{width:"10%"}} className='dave-add-customer-img dave-loader-img' src={require("../../images/Maintenance.gif")} alt="" />
    </div>
  )
}
