import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import * as API_Calls from './API_Call';
import * as  API from './API_Path';

// export const getSMREData = createAsyncThunk("getSMREData", async (para,{rejectWithValue}) => {
//     const { HEADERS , service_center_id } = para;
//     try {
//         const response = await axios.get(API.GET_SERVICE_CENTER,{
//             params : {
//                 service_center_id : service_center_id,
//                 agent_role : "smre"
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const getSMREData = createAsyncThunk("getSMREData", async (para,{rejectWithValue}) => {
    const { service_center_id } = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_CENTER,{
            service_center_id : service_center_id,
            agent_role : "smre"
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const getServiceAdvisiorData = createAsyncThunk("getServiceAdvisiorData", async (para,{rejectWithValue}) => {
//     const { HEADERS , service_center_id } = para;
//     try {
//         const response = await axios.get(API.GET_SERVICE_CENTER,{
//             params : {
//                 service_center_id : service_center_id,
//                 agent_role : "service advisor"
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const getServiceAdvisiorData = createAsyncThunk("getServiceAdvisiorData", async (para,{rejectWithValue}) => {
    const { service_center_id } = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_CENTER,{
            service_center_id : service_center_id,
            agent_role : "service advisor"
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const getWM = createAsyncThunk("getWM", async (para,{rejectWithValue}) => {
//     const { HEADERS , service_center_id } = para;
//     try {
//         const response = await axios.get(API.GET_SERVICE_CENTER,{
//             params : {
//                 service_center_id : service_center_id,
//                 agent_role : "wm"
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const getWM = createAsyncThunk("getWM", async (para,{rejectWithValue}) => {
    const { service_center_id } = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_CENTER,{
            service_center_id : service_center_id,
            agent_role : "wm"
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const getCCM = createAsyncThunk("getCCM", async (para,{rejectWithValue}) => {
//     const { HEADERS , service_center_id } = para;
//     try {
//         const response = await axios.get(API.GET_SERVICE_CENTER,{
//             params : {
//                 service_center_id : service_center_id,
//                 agent_role : "ccm"
//             },
//             headers : HEADERS
//         });
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const getCCM = createAsyncThunk("getCCM", async (para,{rejectWithValue}) => {
    const { service_center_id } = para;
    try {
        const response = await API_Calls.getMethod(API.GET_SERVICE_CENTER,{
            service_center_id : service_center_id,
            agent_role : "ccm"
        });
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const updateReasinee = createAsyncThunk("updateReasinee", async (para,{rejectWithValue}) => {
//     const { HEADERS , booking_id , advisors } = para;
//     console.log(advisors);
//     try {
//         const response = await axios.patch(API.UPDATE_TICKET+booking_id,advisors,{headers : HEADERS});
//         const result = response.data;
//         return result;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const updateReasinee = createAsyncThunk("updateReasinee", async (para,{rejectWithValue}) => {
    const { booking_id , advisors } = para;
    console.log(advisors);
    try {
        const response = await API_Calls.patchMethod(API.UPDATE_TICKET+booking_id,advisors);
        console.log(response);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const reassignTicket = createSlice({
    name : 'reassignTicket',
    initialState : {
        reassignPopUp : false,
        reassignActionPopUp : false,
        smreLoading : null,
        smreData : [],
        smreError : "",
        serviceAdvisorLoading : null,
        serviceAdvisorData : [],
        serviceAdvisorError : "",
        wMLoading : null,
        wMData : [],
        wMError : "",
        cCMLoading : null,
        cCMData : [],
        cCMError : ""
    },
    reducers : {
        updateReassignPopUp : (state, action) => {
            state.reassignPopUp = action.payload;
        },
        updateReassignAction: (state,action) => {
            state.reassignActionPopUp = action.payload;
        },
    },
    extraReducers : {
        [getSMREData.pending] : (state) => {
            state.smreLoading = true;
        },
        [getSMREData.fulfilled] : (state, action) => {
            state.smreLoading = false;
            state.smreData = action.payload.data;
        },
        [getSMREData.rejected] : (state, action) => {
            state.smreLoading = false;
            state.smreError = action.payload;
        },
        [getServiceAdvisiorData.pending] : (state) => {
            state.serviceAdvisorLoading = true;
        },
        [getServiceAdvisiorData.fulfilled] : (state, action) => {
            state.serviceAdvisorLoading = false;
            state.serviceAdvisorData = action.payload.data;
        },
        [getServiceAdvisiorData.rejected] : (state, action) => {
            state.serviceAdvisorLoading = false;
            state.serviceAdvisorError = action.payload;
        },
        [getWM.pending] : (state) => {
            state.wMLoading = true;
        },
        [getWM.fulfilled] : (state, action) => {
            state.wMLoading = false;
            state.wMData = action.payload.data;
        },
        [getWM.rejected] : (state, action) => {
            state.wMLoading = false;
            state.wMError = action.payload;
        },
        [getCCM.pending] : (state) => {
            state.cCMLoading = true;
        },
        [getCCM.fulfilled] : (state, action) => {
            state.cCMLoading = false;
            state.cCMData = action.payload.data;
        },
        [getCCM.rejected] : (state, action) => {
            state.cCMLoading = false;
            state.cCMError = action.payload;
        }
    }
})

export const { updateReassignPopUp , updateReassignAction  } = reassignTicket.actions;
export default reassignTicket.reducer;